import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const LogoutIcon = (props: Props) => {
  const { width = 43, height = 19 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M26.3333 11.3333L23.9833 13.6833L26.6167 16.3333H13V19.6667H26.6167L23.9833 22.3L26.3333 24.6667L33 18L26.3333 11.3333ZM6.33333 6.33333H18V3H6.33333C4.5 3 3 4.5 3 6.33333V29.6667C3 31.5 4.5 33 6.33333 33H18V29.6667H6.33333V6.33333Z" />
    </svg>
  );
};
export default LogoutIcon;
