import {
  Box,
  Dialog,
  DialogContent,
  Stack,
  Typography,
  IconButton,
  Divider,
  TextField,
  InputAdornment,
  DialogTitle,
} from "@mui/material";
import TransactionComponent from "./TransactionComponent";
import { useMemo, useState } from "react";
import { AEGCoinIcon, CloseIcon } from "../assets";
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers/RootReducer";
import PrimaryButton from "./PrimaryButton/PrimaryButton";
import { requestBuyToken } from "../api/api";
import { BuyTokenRequest, BuyTokenResponse } from "../types/BuyTokenModel";
import Loader from "./Loader";
import DialogLoadingView from "./DialogLoadingView";
import { isEmpty, isValidAmount } from "../utils/Validation";
import { AxiosResponse } from "axios";
import { multiplyBigNumber, zeroNumber } from "../utils/Utils";
import { COLOR } from "../theme";

type Props = {
  open: boolean;
  handleClose: () => void;
  onError: (error: string) => void;
};

const enum ViewState {
  BUY_FORM,
  PAYMENT_PROGRESS,
  LOADING,
  CONFIRMATION,
}

const BuyAegDialog = (props: Props) => {
  const { open, handleClose, onError } = props;
  const { aegDetailState } = useSelector((state: RootState) => state);
  const [viewState, setViewState] = useState<ViewState>(ViewState.BUY_FORM);
  const [buyQty, setBuyQty] = useState("");
  const [buyError, setBuyError] = useState("");

  const handleBuyClick = () => {
    if (isEmpty(buyQty)) {
      setBuyError("Buy AEG Qty is required");
    } else if (
      (aegDetailState?.result?.buyMaxQty
        ? aegDetailState?.result?.buyMaxQty
        : 1000) >= parseFloat(buyQty)
    ) {
      requestBuy();
    } else {
      setBuyError(
        `Buy amount should now be more than ${aegDetailState?.result?.buyMaxQty}`
      );
    }
  };

  const requestBuy = async () => {
    try {
      const body: BuyTokenRequest = {
        payment_gateway: "stripe",
        amount: buyQty,
        source: "web",
      };
      const response: AxiosResponse<BuyTokenResponse> = await requestBuyToken(
        body
      );
      if (response) {
        if (response.status === 200) {
          window.location.replace(response.data.data.stripeSession.url);
          setViewState(ViewState.PAYMENT_PROGRESS);
        } else {
          onError(response.data.message);
        }
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const totalBuyAed = useMemo(() => {
    if (aegDetailState?.result?.aegPriceAed && buyQty) {
      const total = multiplyBigNumber(
        aegDetailState?.result?.aegPriceAed,
        buyQty
      );
      return total;
    }
    return zeroNumber();
  }, [aegDetailState?.result?.aegPriceAed, buyQty]);

  const onHandleClose = () => {
    setViewState(ViewState.BUY_FORM);
    setBuyQty("");
    handleClose();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={TransactionComponent}
      keepMounted
      onClose={onHandleClose}
      aria-describedby="dialog-profile-description"
      PaperProps={{
        style: {
          backgroundColor: "rgba(12, 12, 12, 1)",
          border: "0.5px solid rgba(255, 255, 255, 0.4)",
          minWidth: 360,
        },
      }}
    >
      <DialogTitle>
        <Stack direction={"row"} sx={styles.inlineSpaceBetween}>
          <Typography sx={styles.aedPrice}>Buy AEG</Typography>
          <IconButton sx={styles.iconBackground} onClick={onHandleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {viewState === ViewState.BUY_FORM ? (
          <Box>
            <Stack direction={"row"} sx={styles.inlineCenter} mb={3}>
              <AEGCoinIcon />
              <Typography
                sx={styles.aedPrice}
                ml={1}
              >{` AED ${aegDetailState?.result?.aegPriceAed}`}</Typography>
            </Stack>
            <TextField
              variant="outlined"
              type="text"
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{
                      backgroundColor: COLOR.background,
                      minHeight: "2.5em",
                      borderRadius: "8px 0px 0px 8px",
                    }}
                  >
                    <Typography sx={styles.inputPrefix}>AEG Qty</Typography>
                  </InputAdornment>
                ),
                inputProps: {
                  style: {
                    textAlign: "right",
                    fontWeight: 700,
                    letterSpacing: "0.05em",
                    boxSizing: "border-box",
                    paddingRight: "10px",
                  },
                },
              }}
              value={buyQty}
              onChange={(event) => {
                if (isValidAmount(event.target.value)) {
                  const numericValue = parseFloat(event.target.value);
                  if (
                    numericValue <=
                      (aegDetailState?.result?.buyMaxQty
                        ? aegDetailState?.result?.buyMaxQty
                        : 1000) ||
                    event.target.value === ""
                  ) {
                    setBuyError("");
                    setBuyQty(event.target.value);
                  }
                }
              }}
              fullWidth
              error={buyError !== "" ? true : false}
              helperText={buyError === "" ? "" : buyError}
              sx={styles.inputStyle}
            />
            <Typography sx={styles.availableQtyTxt} mt={0.5}>
              {`Maximum Qty: ${
                aegDetailState?.result?.buyMaxQty
                  ? aegDetailState?.result?.buyMaxQty
                  : 1000
              }`}
            </Typography>
            <Stack
              direction={"row"}
              sx={styles.inlineSpaceBetween}
              mt={1}
              mb={3}
            >
              <Stack direction={"row"} sx={styles.inlineCenter}>
                <AEGCoinIcon />
                <Typography sx={styles.totalGold} ml={1}>{`Total`}</Typography>
              </Stack>
              <Typography
                sx={styles.totalGold}
              >{`AED ${totalBuyAed}`}</Typography>
            </Stack>
            <PrimaryButton title="Buy Now" onClick={handleBuyClick} />
          </Box>
        ) : (
          <></>
        )}

        {viewState === ViewState.PAYMENT_PROGRESS ? (
          <Stack direction={"column"} sx={styles.inlineCenter} py={2}>
            <Typography sx={styles.paymentTitle} mt={2}>
              Payment in progress
            </Typography>
            <Typography sx={styles.paymentDesc} mt={4} mb={2}>
              Do not close window while payment is completed
            </Typography>
            <Loader />
          </Stack>
        ) : (
          <></>
        )}
        {viewState === ViewState.LOADING ? <DialogLoadingView /> : <></>}
      </DialogContent>
    </Dialog>
  );
};
export default BuyAegDialog;
const styles = {
  iconBackground: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "rgba(0, 0, 0, 1)",
    width: "1em",
    height: "1em",
    borderRadius: "1em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    marginLeft: "1em",
  },
  inlineSpaceBetween: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  inlineCenter: {
    alignItems: "center",
  },
  aedPrice: {
    fontSize: "1rem",
    fontWeight: 600,
  },
  coldIcon: {
    width: 28,
    height: 28,
  },
  inputPrefix: {
    fontSize: "1rem",
    fontWeight: 300,
    padding: "5px 1em",
  },
  inputStyle: {
    fontSize: "1rem",
    "& .MuiInputBase-root": {
      padding: 0,
    },
    "& .MuiInputBase-input": {
      padding: "0 0 0 0",
    },
  },
  availableQtyTxt: {
    textAlign: "right",
    fontSize: "0.8rem",
    fontWeight: 300,
  },
  totalGold: {
    fontSize: "0.8rem",
    fontWeight: 300,
  },
  centerView: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "3em",
  },
  loadingTitle: {
    fontSize: "1.2rem",
    fontWeight: 600,
  },
  loadingDesc: {
    fontSize: "0.9rem",
    fontWeight: 300,
  },
  paymentTitle: {
    fontSize: "1.2rem",
    fontWeight: 700,
    color: "#FFC800",
  },
  paymentDesc: {
    fontSize: "0.6rem",
    fontWeight: 200,
  },
};
