import StarIcon from "@mui/icons-material/Star";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import SecurityIcon from "@mui/icons-material/Security";
import GppGoodIcon from "@mui/icons-material/GppGood";
import { Icon } from "@iconify/react";
export const items = [
  {
    icon: <Icon icon="mdi:gold" color="#F1B20F" width={30} height={30} />,
    title: "Pure 24 Karat Gold",
    description:
      "Our gold bars are 99.99% pure, ensuring you receive only the finest quality product in the market.",
  },
  {
    icon: (
      <Icon
        icon="icomoon-free:price-tags"
        color="#F1B20F"
        width={30}
        height={30}
      />
    ),
    title: "Global Price Index",
    description:
      "We base all transactions on the Global Price Index, guaranteeing that your gold is always traded at a fair and transparent market price.",
  },
  {
    icon: <Icon icon="mdi:storage" color="#F1B20F" width={30} height={30} />,
    title: "No Storage Fees",
    description:
      "Enjoy the benefit of secure storage without any additional fees, giving you exceptional value over time.",
  },
  {
    icon: (
      <Icon
        icon="hugeicons:bitcoin-withdraw"
        color="#F1B20F"
        width={30}
        height={30}
      />
    ),
    title: "Redeem Anytime",
    description:
      "You can access your gold at any time with ease. Physical delivery is available within the UAE, and we follow strict regulations to ensure a smooth process. Our KYC verification is done conveniently via UAE PASS, ensuring a secure and seamless experience.",
  },
  {
    icon: (
      <Icon icon="stash:vault-light" color="#F1B20F" width={30} height={30} />
    ),
    title: "Secure Vaults",
    description:
      "Your investment is safeguarded in our state-of-the-art vaults, providing the highest level of security and peace of mind.",
  },
  {
    icon: (
      <Icon
        icon="heroicons:cube-transparent-solid"
        color="#F1B20F"
        width={30}
        height={30}
      />
    ),
    title: "Transparency",
    description:
      "With a focus on transparency, security, and flexibility, we offer a premium solution for those looking to invest in gold with the ability to access their holdings anytime, all while enjoying competitive pricing based on global market rates",
  },
];
