import { Box, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { VerifyTwoFARequest } from "../types/TwoStepVerificationModel";
import { requestVerifyTwoFa } from "../api/api";
import PrimaryButton from "./PrimaryButton/PrimaryButton";
import AlertComponent from "./AlertComponent";
import { AlertDataProps } from "../types/Common";

type Props = {
  onSuccess: (code: string) => void;
};

const VerifyTwoFAContent = (props: Props) => {
  const { onSuccess } = props;
  const [code1, setCode1] = useState("");
  const [code2, setCode2] = useState("");
  const [code3, setCode3] = useState("");
  const [code4, setCode4] = useState("");
  const [code5, setCode5] = useState("");
  const [code6, setCode6] = useState("");
  const input1Refs = useRef<HTMLInputElement | null>(null);
  const input2Refs = useRef<HTMLInputElement | null>(null);
  const input3Refs = useRef<HTMLInputElement | null>(null);
  const input4Refs = useRef<HTMLInputElement | null>(null);
  const input5Refs = useRef<HTMLInputElement | null>(null);
  const input6Refs = useRef<HTMLInputElement | null>(null);
  const [alertData, setAlertData] = useState<AlertDataProps>({
    message: "",
    type: "error",
  });

  const resetCode = () => {
    setCode1("");
    setCode2("");
    setCode3("");
    setCode4("");
    setCode5("");
    setCode6("");
    input1Refs?.current?.focus();
  };

  useEffect(() => {
    if (code1 && code2 && code3 && code4 && code5 && code6) {
      verifyTwoFA();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code6]);

  const verifyTwoFA = async () => {
    if (code1 && code2 && code3 && code4 && code5 && code6) {
      const otp = code1 + code2 + code3 + code4 + code5 + code6;
      const body: VerifyTwoFARequest = {
        token: otp,
      };
      const response = await requestVerifyTwoFa(body);
      if (response) {
        if (response.status === 200) {
          onSuccess(otp);
        } else if (response.status === 401) {
          console.log("Response: ", response.data.message);

          resetCode();
          setAlertData({
            title: "Error",
            message: response?.data?.message,
            type: "error",
          });
        } else {
          if (response?.data?.message) {
            setAlertData({
              title: "Error",
              message: response?.data?.message,
              type: "error",
            });
          }
        }
      }
    }
  };

  return (
    <Box sx={styles.contentArea}>
      <Typography sx={styles.titleText}>
        Verify Two Step Verification
      </Typography>
      <Typography sx={styles.subTitleText} mb={3}>
        Enter your two step verification code
      </Typography>
      <Stack sx={{ mb: 10 }} direction={"row"} gap={2}>
        <TextField
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*", maxLength: 1 }}
          inputRef={input1Refs}
          onChange={(e) => {
            if (e.target.value && e.target.value.length > 0) {
              input2Refs?.current?.focus();
              setCode1(e.target.value);
            }
          }}
          onKeyDown={(event) => {
            if (event.key === "Backspace") {
              if (code1 && code1.length > 0) {
                setCode1("");
              }
            }
          }}
          sx={styles.inputStyle}
          value={code1}
        />
        <TextField
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*", maxLength: 1 }}
          inputRef={input2Refs}
          onChange={(e) => {
            if (e.target.value && e.target.value.length > 0) {
              input3Refs?.current?.focus();
              setCode2(e.target.value);
            }
          }}
          onKeyDown={(event) => {
            if (event.key === "Backspace") {
              if (code2 && code2.length > 0) {
                setCode2("");
              } else {
                input1Refs?.current?.focus();
              }
            }
          }}
          sx={styles.inputStyle}
          value={code2}
        />
        <TextField
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*", maxLength: 1 }}
          inputRef={input3Refs}
          onChange={(e) => {
            if (e.target.value && e.target.value.length > 0) {
              input4Refs?.current?.focus();
              setCode3(e.target.value);
            }
          }}
          onKeyDown={(event) => {
            console.log(" handleChange event: ", event.key);
            if (event.key === "Backspace") {
              if (code3 && code3.length > 0) {
                setCode3("");
              } else {
                input2Refs?.current?.focus();
              }
            }
          }}
          sx={styles.inputStyle}
          value={code3}
        />
        <TextField
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*", maxLength: 1 }}
          inputRef={input4Refs}
          onChange={(e) => {
            if (e.target.value && e.target.value.length > 0) {
              input5Refs?.current?.focus();
              setCode4(e.target.value);
            }
          }}
          onKeyDown={(event) => {
            if (event.key === "Backspace") {
              if (code4 && code4.length > 0) {
                setCode4("");
              } else {
                input3Refs?.current?.focus();
              }
            }
          }}
          sx={styles.inputStyle}
          value={code4}
        />
        <TextField
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*", maxLength: 1 }}
          inputRef={input5Refs}
          onChange={(e) => {
            if (e.target.value && e.target.value.length > 0) {
              input6Refs?.current?.focus();
              setCode5(e.target.value);
            }
          }}
          onKeyDown={(event) => {
            if (event.key === "Backspace") {
              if (code5 && code5.length > 0) {
                setCode5("");
              } else {
                input4Refs?.current?.focus();
              }
            }
          }}
          sx={styles.inputStyle}
          value={code5}
        />
        <TextField
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*", maxLength: 1 }}
          inputRef={input6Refs}
          onChange={(e) => {
            if (e.target.value && e.target.value.length > 0) {
              setCode6(e.target.value);
            }
          }}
          onKeyDown={(event) => {
            if (event.key === "Backspace") {
              if (code6 && code6.length > 0) {
                setCode6("");
              } else {
                input5Refs?.current?.focus();
              }
            }
          }}
          sx={styles.inputStyle}
          value={code6}
        />
      </Stack>

      <PrimaryButton
        title={"Verify"}
        onClick={() => {
          verifyTwoFA();
        }}
      />
      <AlertComponent
        open={alertData.message !== "" ? true : false}
        onClose={() => setAlertData({ message: "", type: "error" })}
        message={alertData.message}
        type={alertData.type ? alertData.type : "error"}
      />
    </Box>
  );
};
export default VerifyTwoFAContent;

const styles = {
  titleText: {
    fontSize: "1rem",
    fontWeight: 500,
  },
  loadingTitle: {
    fontSize: "1.2rem",
    fontWeight: 700,
  },
  loadingDesc: {
    fontSize: "1.2rem",
    fontWeight: 300,
  },
  closeIconSize: {
    height: 15,
    width: 15,
  },
  loadingStyleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "40px 10px",
  },
  subTitleText: {
    fontSize: "0.8rem",
    fontWeight: 300,
  },
  headerStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  iconBackground: {
    backgroundColor: "#000000",
  },
  contentArea: {
    padding: "20px 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  inputStyle: {
    "& .MuiOutlinedInput-root": {
      width: "40px", // Set width and height to ensure square aspect ratio
      minHeight: "40px",
      padding: 0, // Remove default padding
      display: "flex",
      alignItems: "center",
      "& .MuiInputBase-input": {
        textAlign: "center", // Center the input text
        height: "40px",
        padding: 0,
      },
    },
  },
};
