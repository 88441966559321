import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import TransactionComponent from "./TransactionComponent";
import { AEGCoinIcon, CloseIcon, GoldBarIcon, VerifyIcon } from "../assets";
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers/RootReducer";
import PrimaryButton from "./PrimaryButton/PrimaryButton";
import VerifyTwoFAContent from "./VerifyTwoFAContent";
import { requestRedeemToken } from "../api/api";
import { RedeemRequestModel, RedeemResponseModel } from "../types/RedeemModel";
import DialogLoadingView from "./DialogLoadingView";
import { AxiosResponse } from "axios";
import { multiplyBigNumber, toFixedDecimal, zeroNumber } from "../utils/Utils";
import { COLOR } from "../theme";
import {
  isLessThenOrEqual,
  isNumber,
  isValidAmount,
} from "../utils/Validation";

type Props = {
  open: boolean;
  handleClose: () => void;
  onError: (error: string) => void;
  onSuccess: (tnxId: number) => void;
};

const enum ViewState {
  REDEEM_FORM,
  TWOFA,
  LOADING,
  CONFIRMATION,
}

const RedeemDialog = (props: Props) => {
  const { open, handleClose, onError, onSuccess } = props;
  const { aegDetailState, userProfileState } = useSelector(
    (state: RootState) => state
  );
  const [redeemQty, setRedeemQty] = useState("");
  const [redeemQtyError, setRedeemQtyError] = useState("");
  const [viewState, setViewState] = useState<ViewState>(ViewState.REDEEM_FORM);
  const [transactionId, setTransactionId] = useState<number>(-1);

  const handleRedeemClick = () => {
    if (redeemQty) {
      if (!isNumber(redeemQty)) {
        setRedeemQtyError("Please enter valid Redeem Amount");
        return;
      } else if (
        !isLessThenOrEqual(totalGoldValue, aegDetailState.result?.balance)
      ) {
        setRedeemQtyError(
          "Redeem amount should not be more then available qty"
        );
        return;
      }
      if (userProfileState.result?.twoFactorEnabled === 1) {
        setViewState(ViewState.TWOFA);
      } else {
        sendRedeemRequest("000000");
      }
    } else {
      setRedeemQtyError("Redeem amount is required");
    }
  };

  useEffect(() => {
    if (viewState === ViewState.CONFIRMATION) {
      setTimeout(() => {
        setViewState(ViewState.REDEEM_FORM);
        setRedeemQty("");
        onSuccess(transactionId);
      }, 1500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewState]);

  const sendRedeemRequest = async (token: string) => {
    if (token) {
      setViewState(ViewState.LOADING);
      const body: RedeemRequestModel = {
        token: token,
        amount: totalGoldValue,
      };
      const response: AxiosResponse<RedeemResponseModel> =
        await requestRedeemToken(body);
      if (response) {
        if (response.status === 200) {
          setViewState(ViewState.CONFIRMATION);
          setTransactionId(response.data.data.responseData.transactionId);
        } else {
          setViewState(ViewState.REDEEM_FORM);
          setRedeemQty("");
          onError(response.data.message);
        }
      }
    }
  };

  const totalGoldValue = useMemo(() => {
    if (redeemQty) {
      return toFixedDecimal(multiplyBigNumber(redeemQty, 250), 0);
    }
    return toFixedDecimal(zeroNumber(), 0);
  }, [redeemQty]);

  const handleCloseClicked = () => {
    setRedeemQty("");
    setViewState(ViewState.REDEEM_FORM);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={TransactionComponent}
      keepMounted
      PaperProps={{
        style: {
          backgroundColor: "rgba(12, 12, 12, 1)",
          border: "0.5px solid rgba(255, 255, 255, 0.4)",
          minWidth: 360,
        },
      }}
      onClose={handleCloseClicked}
      aria-describedby="dialog-profile-description"
    >
      <DialogTitle>
        <Stack direction={"row"} sx={styles.inlineSpaceBetween}>
          <Typography sx={styles.titleText}>Redeem</Typography>
          <IconButton sx={styles.iconBackground} onClick={handleCloseClicked}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent sx={styles.container}>
        {viewState === ViewState.REDEEM_FORM ? (
          <Box>
            <Stack direction={"row"} sx={styles.inlineCenter} gap={1} mb={2}>
              <AEGCoinIcon />
              <Typography sx={styles.goldPriceText}>1 AEG = </Typography>
              <img src={GoldBarIcon} style={styles.coldIcon} alt="GoldBar" />
              <Typography sx={styles.goldPriceText}>
                {`${toFixedDecimal(
                  aegDetailState.result?.unitGoldGramValue
                )} Gram Gold`}
              </Typography>
            </Stack>
            <TextField
              variant="outlined"
              type="text"
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{
                      backgroundColor: COLOR.background,
                      minHeight: "2.5em",
                      borderRadius: "8px 0px 0px 8px",
                    }}
                  >
                    <Typography sx={styles.inputPrefix}>AEG Qty</Typography>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      backgroundColor: COLOR.background,
                      minHeight: "2.5em",
                      borderRadius: "0px 8px 8px 0px",
                    }}
                  >
                    <Typography sx={styles.inputSuffix}>x250</Typography>
                  </InputAdornment>
                ),
                inputProps: {
                  style: {
                    textAlign: "right",
                    fontWeight: 700,
                    letterSpacing: "0.2em",
                    boxSizing: "border-box",
                    paddingRight: "5px",
                  },
                },
              }}
              inputProps={{ maxLength: 5 }}
              value={redeemQty}
              onChange={(event) => {
                if (event.target.value && event.target.value.length > 0) {
                  setRedeemQtyError("");
                } else {
                  setRedeemQty("");
                }
                if (
                  isValidAmount(event.target.value) &&
                  !event.target.value.includes(".") &&
                  /^\d*$/.test(event.target.value) &&
                  parseInt(event.target.value, 10) <
                    (aegDetailState?.result?.balance
                      ? parseFloat(aegDetailState?.result?.balance)
                      : 1000)
                ) {
                  setRedeemQty(event.target.value);
                }
              }}
              error={redeemQtyError !== "" ? true : false}
              helperText={redeemQtyError !== "" ? redeemQtyError : ""}
              fullWidth
              sx={styles.inputStyle}
            />
            <Typography sx={styles.availableQtyTxt} mt={0.5}>
              {`Available Qty: ${aegDetailState.result?.balance} AEG`}
            </Typography>
            <Stack direction={"row"} sx={styles.inlineSpaceBetween} mt={1}>
              <Stack direction={"row"} sx={styles.inlineCenter}>
                <img src={GoldBarIcon} alt="GoldIcon" style={styles.coldIcon} />
                <Typography
                  sx={styles.totalGold}
                  ml={1}
                >{`Total Gold Value`}</Typography>
              </Stack>
              <Typography sx={styles.totalGold}>{`${toFixedDecimal(
                totalGoldValue
              )} GM`}</Typography>
            </Stack>
            <Typography sx={styles.descText} mt={2} mb={4}>
              Note: After the approval of redemptions, you need to visit the
              nearest store to collect your physical gold
            </Typography>
            <PrimaryButton title="Redeem Now" onClick={handleRedeemClick} />
          </Box>
        ) : (
          <></>
        )}
        {viewState === ViewState.TWOFA ? (
          <VerifyTwoFAContent onSuccess={(code) => sendRedeemRequest(code)} />
        ) : (
          <></>
        )}
        {viewState === ViewState.LOADING ? <DialogLoadingView /> : <></>}
        {viewState === ViewState.CONFIRMATION ? (
          <Stack direction={"column"} sx={styles.inlineCenter} p={3}>
            <img
              src={VerifyIcon}
              alt="RedeemVerified"
              style={styles.confirmationIcon}
            />
            <Typography style={styles.doneText}>Done</Typography>
            <Typography style={styles.transactionSuccess} mt={2}>
              Transaction Successful
            </Typography>
            <Typography style={styles.transactionId} mt={3}>
              Order Id
            </Typography>
            <Typography style={styles.transactionId}>
              {transactionId}
            </Typography>
          </Stack>
        ) : (
          <></>
        )}
      </DialogContent>
    </Dialog>
  );
};
export default RedeemDialog;
const styles = {
  container: {
    backgroundColor: "rgba(12, 12, 12, 1)",
  },
  titleText: {
    fontSize: "1.2rem",
    fontWeight: 500,
  },
  iconBackground: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "rgba(0, 0, 0, 1)",
    width: "1em",
    height: "1em",
    borderRadius: "1em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    marginLeft: "1em",
  },
  inlineSpaceBetween: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  inlineCenter: {
    alignItems: "center",
  },
  coldIcon: {
    width: 28,
    height: 28,
  },
  goldPriceText: {
    fontSize: ".8rem",
    fontWeight: 500,
  },
  inputPrefix: {
    fontSize: "1rem",
    fontWeight: 300,
    padding: "5px 1em",
  },
  inputSuffix: {
    fontSize: "0.8rem",
    fontWeight: 300,
    padding: "5px 1em",
  },
  inputStyle: {
    fontSize: "1rem",
    "& .MuiInputBase-root": {
      padding: 0,
    },
    "& .MuiInputBase-input": {
      padding: "0 0 0 0",
    },
  },
  availableQtyTxt: {
    textAlign: "right",
    fontSize: "0.8rem",
    fontWeight: 300,
  },
  totalGold: {
    fontSize: "0.8rem",
    fontWeight: 300,
  },
  descText: {
    fontSize: "0.7rem",
    fontWeight: 300,
    textAlign: "center",
    maxWidth: "350px",
  },
  centerView: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "3em",
  },
  loadingTitle: {
    fontSize: "1.2rem",
    fontWeight: 600,
  },
  loadingDesc: {
    fontSize: "0.9rem",
    fontWeight: 300,
  },
  confirmationIcon: {
    width: "3.5em",
    height: "3.5em",
  },
  doneText: {
    fontSize: "1.5rem",
    fontWeight: 800,
    color: "#FFC800",
  },
  transactionSuccess: {
    fontSize: "1rem",
    fontWeight: 500,
  },
  transactionId: {
    fontSize: "0.7rem",
    fontWeight: 400,
  },
};
