import React, { SyntheticEvent, useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  InputAdornment,
  IconButton,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useFormik } from "formik";
import { RegisterSchema } from "../../validations/Validations";
import EyeShowIcon from "../../assets/svg/EyeShowIcon";
import EyeSlashIcon from "../../assets/svg/EyeSlashIcon";
import { RegisterRequest } from "../../types/RegisterModel";
import { requestRegisterApi } from "../../api/api";
import AlertComponent from "../../components/AlertComponent";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import { STORE_KEY } from "../../utils/AppConstants";
import { COLOR } from "../../theme";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ReCaptchaComponent from "../login/ReCaptchaComponent";
import { AlertDataProps } from "../../types/Common";
import { aesEncrypt } from "../../utils/Encryption";

const Registration = () => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [alertData, setAlertData] = useState<AlertDataProps>({
    message: "",
    type: "error",
  });

  useEffect(() => {
    if (recaptchaToken !== null) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recaptchaToken]);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      confirmPassword: "",
      password: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: (value) => {
      if (isChecked) {
        if (recaptchaToken !== null) {
          setLoading(true);
          const body: RegisterRequest = {
            firstName: value.firstName,
            lastName: value.lastName,
            email: value.email,
            password: value.password,
            recaptchaToken: recaptchaToken,
          };
          const encryptText = aesEncrypt(JSON.stringify(body));
          const encryptBody = {
            dataCipher: encryptText,
          };
          requestRegistration(encryptBody);
        } else {
          setAlertData({
            message: "Recaptcha verification failed, Please try again.",
            type: "error",
          });
        }
      } else {
        setAlertData({
          message: "Please accept the privacy policy & terms and conditions",
          type: "error",
        });
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const handleClipboardEvent = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const requestRegistration = async (body: RegisterRequest) => {
    const response = await requestRegisterApi(body);
    setLoading(false);
    if (response) {
      if (response.status === 200) {
        navigate("/auth/verify-email");
        localStorage.setItem(
          STORE_KEY.TEMP_USER_DATA,
          JSON.stringify({
            userId: response.data.data.userId,
            email: body.email,
          })
        );
        setAlertData({
          title: "Registration Successful",
          message: response.data.message,
          type: "success",
        });
      } else if (response.status === 400) {
        setAlertData({ message: response.data.message, type: "error" });
        formik.resetForm();
        setIsChecked(false);
      }
    } else {
    }
  };

  return (
    <Card
      sx={{
        backgroundColor: "rgba(10, 10, 10, 0.9)",
        border: "1px solid rgba(98, 77, 0, 1)",
      }}
    >
      <CardContent>
        <Box mb={2}>
          <Typography variant="body1" gutterBottom textAlign={"center"} mb={4}>
            Lets start with the few quick things
          </Typography>
          <TextField
            label="First Name"
            variant="outlined"
            fullWidth
            type={"text"}
            error={Boolean(touched.firstName && errors.firstName)}
            helperText={touched.firstName && errors.firstName}
            {...getFieldProps("firstName")}
            sx={{ mb: 2, borderColor: "rgba(56, 49, 24, 1)" }}
          />
          <TextField
            label="Last Name"
            variant="outlined"
            fullWidth
            type={"text"}
            error={Boolean(touched.lastName && errors.lastName)}
            helperText={touched.lastName && errors.lastName}
            {...getFieldProps("lastName")}
            sx={{ mb: 2, borderColor: "rgba(56, 49, 24, 1)" }}
          />
          <TextField
            label="Email address"
            variant="outlined"
            fullWidth
            type={"email"}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            {...getFieldProps("email")}
            sx={{ mb: 2, borderColor: "rgba(56, 49, 24, 1)" }}
          />
          <TextField
            label="Password"
            variant="outlined"
            fullWidth
            type={showPassword ? "text" : "password"}
            {...getFieldProps("password")}
            error={Boolean(touched.password && errors.password)}
            onPaste={handleClipboardEvent}
            onCopy={handleClipboardEvent}
            onCut={handleClipboardEvent}
            helperText={touched.password && errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword((prev) => !prev)}
                    edge="end"
                    style={{ color: "#FFC800" }}
                  >
                    {showPassword ? <EyeShowIcon /> : <EyeSlashIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography sx={styles.passwordValidationInfo} gutterBottom mb={2}>
            Required At least 8 characters, Including upper case, Number,
            Special characters
          </Typography>

          <TextField
            label="Confirm password"
            variant="outlined"
            fullWidth
            type={showConfirmPassword ? "text" : "password"}
            {...getFieldProps("confirmPassword")}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            helperText={touched.confirmPassword && errors.confirmPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowConfirmPassword((prev) => !prev)}
                    edge="end"
                    style={{ color: "#FFC800" }}
                  >
                    {showConfirmPassword ? <EyeShowIcon /> : <EyeSlashIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ mb: 2 }}
          />
          <FormControlLabel
            control={<Checkbox />}
            onChange={(event: SyntheticEvent, checked: boolean) => {
              setIsChecked(checked);
            }}
            label={
              <Typography style={styles.consentTextStyle}>
                I Accept the{" "}
                <Link
                  style={styles.linkStyle}
                  to="/privacy-policy"
                  relative="route"
                >
                  Privacy Policy
                </Link>
                {" & "}
                <Link
                  style={styles.linkStyle}
                  to="/privacy-policy"
                  relative="route"
                >
                  Terms and conditions
                </Link>
              </Typography>
            }
            sx={{ color: "white", mb: 8 }}
          />
          {loading ? (
            <Loader />
          ) : (
            <GoogleReCaptchaProvider
              useRecaptchaNet
              reCaptchaKey={siteKey as string}
              scriptProps={{ async: true, defer: true, appendTo: "body" }}
            >
              <ReCaptchaComponent
                onSuccess={(token) => {
                  if (token) {
                    setRecaptchaToken(token);
                  }
                }}
                onError={(error) => {
                  if (error) {
                    setAlertData({ message: error, type: "error" });
                  }
                }}
              />
            </GoogleReCaptchaProvider>
            // <PrimaryButton title={"Sign Up"} onClick={handleSubmit} />
          )}

          <Typography variant="body2" mt={2} textAlign={"center"}>
            Already have an Account?{" "}
            <Link to="/auth/login" style={styles.signInLink} relative="route">
              Sign in
            </Link>
          </Typography>
        </Box>
      </CardContent>

      <AlertComponent
        open={alertData.message !== "" ? true : false}
        onClose={() => setAlertData({ message: "", type: "error" })}
        message={alertData.message}
        type={alertData.type ? alertData.type : "error"}
      />
    </Card>
  );
};
export default Registration;

const styles = {
  passwordValidationInfo: {
    fontSize: "0.7rem",
    fontWeight: 300,
  },
  consentTextStyle: {
    fontSize: "0.8rem",
    fontWeight: 300,
  },
  consentTextBoldStyle: {
    fontSize: "0.8rem",
    fontWeight: 600,
  },
  linkStyle: {
    color: "#FFFFFF",
    fontWeight: 600,
    textDecoration: "none",
  },
  signInLink: {
    color: COLOR.primary,
    fontSize: "1em",
    fontWeight: 600,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
};
