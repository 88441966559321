import React, { useCallback } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";

interface IProps {
  title?: string;
  onSuccess: (token: string) => void;
  onError: (error: string) => void;
}

const ReCaptchaComponent = (props: IProps) => {
  const { onSuccess, onError, title = "Sign In" } = props;
  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      onError("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("authenticate");
    onSuccess(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executeRecaptcha]);

  return <PrimaryButton title={title} onClick={handleReCaptchaVerify} />;
};
export default ReCaptchaComponent;
