import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { QuestionMarkCircleIcon, UserIcon } from "../assets/index";
import WalletIcon from "../assets/svg/WalletIcon";
import TransactionsIcon from "../assets/svg/TransactionsIcon";

const SupportAccordion = () => {
  const [value, setValue] = useState(0);
  const [expanded, setExpanded] = React.useState("panel1"); // Default to panel1 being expanded

  const handleChangeTab = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const handleChangeAccordion =
    (panel: any) => (event: any, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const tabData = [
    {
      label: "My account",
      icon: (
        <Box sx={styles.iconBg}>
          <UserIcon width="1.3em" height="1.3em" />
        </Box>
      ),
      index: 0,
    },
    {
      label: "Wallet",
      icon: (
        <Box sx={styles.iconBg}>
          <WalletIcon color="#FFFFFF" width="1.3em" height="1.3em" />
        </Box>
      ),
      index: 1,
    },
    {
      label: "Transactions",
      icon: (
        <Box sx={styles.iconBg}>
          <TransactionsIcon color="#FFFFFF" width="1.3em" height="1.3em" />
        </Box>
      ),
      index: 2,
    },
    {
      label: "All FAQs",
      icon: (
        <Box sx={styles.iconBg}>
          <QuestionMarkCircleIcon width="1.5em" height="1.5em" />
        </Box>
      ),
      index: 3,
    },
    /*  {  ClassName: 'accordionCustom', label: 'All FAQs', icon: <QuestionAnswerIcon style={{marginBottom: '-5px',marginRight: '10px'}} />, index: 3 }, */
  ];

  const myAccount = [
    {
      title: "1. How can I reset my password ?",
      content:
        'To reset your password either go to login page and click on "Forgot Password", or go to the "Settings", select "Reset Password", enter your current password, and then update it with your new one.',
      panel: "panel1",
    },
    {
      title: "2. How do I update my personal information ?",
      content:
        'To update your personal information, navigate to the "Settings" section in the app, and tap on "Edit Profile." You can update your name, date of birth, profile and other details.',
      panel: "panel2",
    },
    {
      title:
        "3. What should I do if I suspect unauthorized access to my account ?",
      content:
        "If you suspect unauthorized access to your account, immediately change your password and enable two-factor authentication (2FA) for added security. Contact AEG customer support for further assistance.",
      panel: "panel3",
    },
  ];

  const wallet = [
    {
      title: "1. What should I do if my wallet balance is not updating?",
      content:
        "If your wallet balance is not updating, try refreshing the wallet section. If the issue persists, ensure you have a stable internet connection and contact AEG customer support.",
      panel: "panel11",
    },
    {
      title: "2. How do I update my wallet balance?",
      content: "Your wallet balance should automatically update.",
      panel: "panel12",
    },
    {
      title: "3. Can I have multiple wallets for the same cryptocurrency?",
      content:
        "No, you cannot have multiple wallets for the same cryptocurrency. Each user is limited to one wallet per cryptocurrency within the app.",
      panel: "panel13",
    },
  ];
  const transactions = [
    {
      title: "1. How can I view my transaction history?",
      content:
        'To view your transaction history, navigate to the "Transactions" section in the app. You will see a list of all your past transactions, including details such as date, amount, and transaction status.',
      panel: "panel21",
    },
    {
      title: "2. What does the status of my transaction mean?",
      content:
        'The transaction status can be "Pending", "Approved", "Rejected" or "Failed." "Pending" means the transaction is being processed, "Approved" means the transaction was successful, "Rejected" means the transaction was rejected by admin and "Failed" means there was an issue with the transaction.',
      panel: "panel22",
    },
    {
      title: "3. How do I cancel a pending transaction?",
      content:
        "Once a transaction is initiated, it cannot be canceled due to the nature of blockchain technology. Ensure you double-check all details before confirming a transaction.",
      panel: "panel23",
    },
    {
      title: "4. What should I do if my transaction fails?",
      content:
        "If your transaction fails, check the error message for details. Ensure you have sufficient funds and that the recipient address is correct. If the issue persists, contact AEG customer support for assistance.",
      panel: "panel24",
    },
    {
      title: "5. Can I schedule a transaction for a later date?",
      content:
        "Currently, scheduling transactions for a later date is not supported. You must manually initiate each transaction when you want it to be processed.",
      panel: "panel25",
    },
  ];

  const allFAQ = [
    {
      title: "1. How can I reset my password ?",
      content:
        'To reset your password either go to login page and click on "Forgot Password", or go to the "Settings", select "Reset Password", enter your current password, and then update it with your new one.',
      panel: "panel1",
    },
    {
      title: "2. How do I update my personal information ?",
      content:
        'To update your personal information, navigate to the "Settings" section in the app, and tap on "Edit Profile." You can update your name, date of birth, profile and other details.',
      panel: "panel2",
    },
    {
      title:
        "3. What should I do if I suspect unauthorized access to my account ?",
      content:
        "If you suspect unauthorized access to your account, immediately change your password and enable two-factor authentication (2FA) for added security. Contact AEG customer support for further assistance.",
      panel: "panel3",
    },
    {
      title: "4. What should I do if my wallet balance is not updating?",
      content:
        "If your wallet balance is not updating, try refreshing the wallet section. If the issue persists, ensure you have a stable internet connection and contact AEG customer support.",
      panel: "panel11",
    },
    {
      title: "5. How do I update my wallet balance?",
      content: "Your wallet balance should automatically update.",
      panel: "panel12",
    },
    {
      title: "6. Can I have multiple wallets for the same cryptocurrency?",
      content:
        "No, you cannot have multiple wallets for the same cryptocurrency. Each user is limited to one wallet per cryptocurrency within the app.",
      panel: "panel13",
    },
    {
      title: "7. How can I view my transaction history?",
      content:
        'To view your transaction history, navigate to the "Transactions" section in the app. You will see a list of all your past transactions, including details such as date, amount, and transaction status.',
      panel: "panel21",
    },
    {
      title: "8. What does the status of my transaction mean?",
      content:
        'The transaction status can be "Pending", "Approved", "Rejected" or "Failed." "Pending" means the transaction is being processed, "Approved" means the transaction was successful, "Rejected" means the transaction was rejected by admin and "Failed" means there was an issue with the transaction.',
      panel: "panel22",
    },
    {
      title: "9. How do I cancel a pending transaction?",
      content:
        "Once a transaction is initiated, it cannot be canceled due to the nature of blockchain technology. Ensure you double-check all details before confirming a transaction.",
      panel: "panel23",
    },
    {
      title: "10. What should I do if my transaction fails?",
      content:
        "If your transaction fails, check the error message for details. Ensure you have sufficient funds and that the recipient address is correct. If the issue persists, contact AEG customer support for assistance.",
      panel: "panel24",
    },
    {
      title: "11. Can I schedule a transaction for a later date?",
      content:
        "Currently, scheduling transactions for a later date is not supported. You must manually initiate each transaction when you want it to be processed.",
      panel: "panel25",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChangeTab}
        aria-label="AEG FAQs "
        sx={styles.tabStyle}
        TabIndicatorProps={{
          style: { backgroundColor: "#FFC800" },
        }}
      >
        {tabData.map((tab, index) => (
          <Tab
            key={index}
            sx={styles.tabTitleItemStyle}
            label={<Typography sx={styles.tabLable}>{tab.label}</Typography>}
            icon={tab.icon}
          />
        ))}
      </Tabs>
      {tabData.map((tab, index) => (
        <TabPanel key={index} value={value} index={tab.index}>
          {tab.index === 0 && (
            <div style={{ paddingLeft: "1em" }}>
              {myAccount.map((accordion, idx) => (
                <Accordion
                  key={idx}
                  sx={{
                    backgroundColor: "rgba(0,0,0,0.1)",
                  }}
                  expanded={expanded === accordion.panel}
                  onChange={handleChangeAccordion(accordion.panel)}
                  disableGutters
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${accordion.panel}bh-content`}
                    id={`${accordion.panel}bh-header`}
                  >
                    <Typography sx={styles.faqQuestion}>
                      {accordion.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={styles.faqAnswer}>
                      {accordion.content}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          )}
          {tab.index === 1 && (
            <div style={{ paddingLeft: "1em" }}>
              {wallet.map((accordion, idx) => (
                <Accordion
                  key={idx}
                  sx={{
                    backgroundColor: "rgba(0,0,0,0.1)",
                  }}
                  expanded={expanded === accordion.panel}
                  onChange={handleChangeAccordion(accordion.panel)}
                  disableGutters
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${accordion.panel}bh-content`}
                    id={`${accordion.panel}bh-header`}
                  >
                    <Typography sx={styles.faqQuestion}>
                      {accordion.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={styles.faqAnswer}>
                      {accordion.content}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          )}
          {tab.index === 2 && (
            <div style={{ paddingLeft: "1em" }}>
              {transactions.map((accordion, idx) => (
                <Accordion
                  key={idx}
                  sx={{
                    backgroundColor: "rgba(0,0,0,0.1)",
                  }}
                  expanded={expanded === accordion.panel}
                  onChange={handleChangeAccordion(accordion.panel)}
                  disableGutters
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${accordion.panel}bh-content`}
                    id={`${accordion.panel}bh-header`}
                  >
                    <Typography sx={styles.faqQuestion}>
                      {accordion.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={styles.faqAnswer}>
                      {accordion.content}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          )}
          {tab.index === 3 && (
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                paddingLeft: "1em",
                maxHeight: "50vh",
                overflow: "auto",
              }}
            >
              {allFAQ.map((accordion, idx) => (
                <Accordion
                  key={idx}
                  sx={{
                    backgroundColor: "rgba(0,0,0,0.1)",
                  }}
                  expanded={expanded === accordion.panel}
                  onChange={handleChangeAccordion(accordion.panel)}
                  disableGutters
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${accordion.panel}bh-content`}
                    id={`${accordion.panel}bh-header`}
                  >
                    <Typography sx={styles.faqQuestion}>
                      {accordion.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={styles.faqAnswer}>
                      {accordion.content}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          )}
        </TabPanel>
      ))}
    </Box>
  );
};

const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};

export default SupportAccordion;
const styles = {
  tabStyle: {
    borderRight: 1,
    borderColor: "divider",
    textAlign: "left",
    width: "12em",
    minWidth: "12em",
    padding: 0,
    margin: 0,
  },
  tabLable: {
    fontWeight: 400,
    fontSize: "0.9rem",
    color: "#FFFFFF",
    height: "1.8em",
  },
  tabTitleItemStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
  },
  iconBg: {
    backgroundColor: "#000000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "2em",
    height: "2.5em",
    width: "2.5em",
    marginRight: "0.5em",
  },
  faqQuestion: {
    width: "90%",
    fontSize: "0.9rem",
    fontWeight: 500,
    color: "#FFC800",
  },
  faqAnswer: {
    fontSize: "0.8rem",
    fontWeight: 400,
    color: "#FFFFFF",
  },
};
