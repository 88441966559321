import { Box, Button } from "@mui/material";
import React from "react";
import ForwardArrow from "../../assets/svg/ForwardArrow";
import { styled } from "@mui/material/styles";

type Props = {
  title: string;
  onClick?: () => void;
  type?: "button" | "submit" | "reset" | undefined;
  isDisabled?: boolean;
};

const PrimaryButton = (props: Props) => {
  const { title, onClick, type = "submit", isDisabled } = props;
  return (
    <>
      {isDisabled ? (
        <DisabledGradientButton
          type={type}
          startIcon={<Box></Box>}
          endIcon={
            <Box style={style.forwardDisableBtn}>
              <ForwardArrow />
            </Box>
          }
          onClick={onClick}
        >
          {title}
        </DisabledGradientButton>
      ) : (
        <GradientButton
          type={type}
          startIcon={<Box></Box>}
          endIcon={
            <Box style={style.forwardBtn}>
              <ForwardArrow />
            </Box>
          }
          onClick={onClick}
        >
          {title}
        </GradientButton>
      )}
    </>
  );
};
export default PrimaryButton;

const GradientButton = styled(Button)(({ theme: any }) => ({
  background:
    "linear-gradient(to right, rgba(255, 246, 1, 1), rgba(241, 178, 15, 1))",
  color: "#000000",
  border: "none",
  borderRadius: "30px",
  padding: "0.25em 0.8em",
  textTransform: "none",
  fontSize: "0.8rem",
  fontWeight: "800",
  boxShadow: "none",
  transition: "background 0.3s ease",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  textAlign: "center",
  "&:hover": {
    background:
      "linear-gradient(to right, rgba(241, 178, 15, 1), rgba(255, 246, 1, 1))",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
  "&:active": {
    boxShadow: "inset 0px 2px 4px rgba(0, 0, 0, 0.2)",
  },
  "&:disabled": {
    background:
      "linear-gradient(to right, rgba(255, 246, 1, 0.5), rgba(241, 178, 15, 0.5))",
    color: "#000000",
  },
}));

const DisabledGradientButton = styled(Button)(({ theme: any }) => ({
  background:
    "linear-gradient(to right, rgba(255, 246, 1, 0.5), rgba(241, 178, 15, 0.5))",
  color: "#000000",
  border: "none",
  borderRadius: "30px",
  padding: "0.25em 0.8em",
  textTransform: "none",
  fontSize: "0.8rem",
  fontWeight: "800",
  boxShadow: "none",
  transition: "background 0.3s ease",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  textAlign: "center",
  "&:hover": {
    background:
      "linear-gradient(to right, rgba(241, 178, 15, 0.5), rgba(255, 246, 1, 0.5))",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
  "&:active": {
    boxShadow: "inset 0px 2px 4px rgba(0, 0, 0, 0.2)",
  },
  "&:disabled": {
    background:
      "linear-gradient(to right, rgba(255, 246, 1, 0.5), rgba(241, 178, 15, 0.5))",
    color: "#000000",
  },
}));

const style = {
  forwardBtn: {
    display: "flex",
    background: "rgba(255, 255, 255, 0.4)",
    height: "30px",
    width: "30px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "30px",
  },
  forwardDisableBtn: {
    display: "flex",
    background: "rgba(255, 255, 255, 0.1)",
    height: "30px",
    width: "30px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "30px",
  },
};
