import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const CopyIcon = (props: Props) => {
  const { width = 18, height = 18 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2253 2.6039C15.4125 2.61685 15.5993 2.63107 15.7859 2.64655C17.0686 2.75302 18.0059 3.84154 18.0059 5.09603V13.1308C18.0059 14.5116 16.8866 15.6309 15.5059 15.6309V13.7559C15.5059 8.82092 11.6929 4.77656 6.85229 4.4082C7.12244 3.46525 7.93557 2.7329 8.97594 2.64656C9.16245 2.63107 9.34932 2.61686 9.53653 2.6039C9.95313 1.80292 10.7906 1.25586 11.7559 1.25586H13.0059C13.9712 1.25586 14.8087 1.80292 15.2253 2.6039ZM10.5059 3.75586C10.5059 3.0655 11.0655 2.50586 11.7559 2.50586H13.0059C13.6962 2.50586 14.2559 3.0655 14.2559 3.75586H10.5059Z"
        fill="white"
      />
      <path
        d="M3.00586 7.19336C3.00586 6.33041 3.70541 5.63086 4.56836 5.63086H4.88086C6.60675 5.63086 8.00586 7.02997 8.00586 8.75586V10.3184C8.00586 11.1813 8.70541 11.8809 9.56836 11.8809H11.1309C12.8567 11.8809 14.2559 13.28 14.2559 15.0059V17.1934C14.2559 18.0563 13.5563 18.7559 12.6934 18.7559H4.56836C3.70541 18.7559 3.00586 18.0563 3.00586 17.1934V7.19336Z"
        fill="white"
      />
      <path
        d="M9.25586 8.75586C9.25586 7.66165 8.85416 6.66126 8.19019 5.89411C11.0226 6.63401 13.2527 8.86409 13.9926 11.6965C13.2255 11.0326 12.2251 10.6309 11.1309 10.6309H9.56836C9.39577 10.6309 9.25586 10.4909 9.25586 10.3184V8.75586Z"
        fill="white"
      />
    </svg>
  );
};
export default CopyIcon;
