import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { BankDetailData } from "../types/BankDetailsModel";
import TransactionComponent from "./TransactionComponent";
import { CloseIcon } from "../assets";
import { toPascalCase } from "../utils/Utils";
type Props = {
  open: boolean;
  handleClose: () => void;
  bankDetails: BankDetailData;
};
const BankDetailsDialog = (props: Props) => {
  const { open, handleClose, bankDetails } = props;
  return (
    <Dialog
      open={open}
      TransitionComponent={TransactionComponent}
      keepMounted
      PaperProps={{
        style: {
          backgroundColor: "rgba(12, 12, 12, 1)",
          border: "0.5px solid rgba(255, 255, 255, 0.4)",
        },
      }}
      onClose={handleClose}
    >
      <DialogContent sx={styles.container}>
        <Box sx={styles.headerStyle} mb={0.5}>
          <Typography sx={styles.dialogTitle}>Bank Details</Typography>
          <IconButton sx={styles.iconBackground} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Table>
          <TableBody>
            <TableRow sx={styles.tableRow}>
              <TableCell sx={styles.tableColHeader}>Bank Name</TableCell>
              <TableCell sx={styles.tableColValue}>
                {bankDetails.bankName}
              </TableCell>
            </TableRow>
            <TableRow sx={styles.tableRow}>
              <TableCell sx={styles.tableColHeader}>Account Number</TableCell>
              <TableCell sx={styles.tableColValue}>
                {bankDetails.accountNumber}
              </TableCell>
            </TableRow>
            <TableRow sx={styles.tableRow}>
              <TableCell sx={styles.tableColHeader}>Swift Code</TableCell>
              <TableCell sx={styles.tableColValue}>
                {bankDetails.swiftBic}
              </TableCell>
            </TableRow>
            <TableRow sx={styles.tableRow}>
              <TableCell sx={styles.tableColHeader}>IBAN Code</TableCell>
              <TableCell sx={styles.tableColValue}>
                {bankDetails.iban}
              </TableCell>
            </TableRow>
            <TableRow sx={styles.tableRow}>
              <TableCell sx={styles.tableColHeader}>Beneficiary Name</TableCell>
              <TableCell sx={styles.tableColValue}>
                {bankDetails.beneficiaryName}
              </TableCell>
            </TableRow>
            <TableRow sx={styles.tableRow}>
              <TableCell sx={styles.tableColHeader}>Primary Account</TableCell>
              <TableCell sx={styles.tableColValue}>
                {bankDetails.isPrimary === 1 ? "Yes" : "No"}
              </TableCell>
            </TableRow>
            <TableRow sx={styles.tableRow}>
              <TableCell sx={styles.tableColHeader}>Account Status</TableCell>
              <TableCell sx={styles.tableColValue}>
                {toPascalCase(bankDetails.status)}
              </TableCell>
            </TableRow>
            <TableRow sx={styles.tableRow}>
              <TableCell sx={styles.tableColHeader}>
                Verification Status
              </TableCell>
              <TableCell sx={styles.tableColValue}>
                {toPascalCase(bankDetails.verificationStatus)}
              </TableCell>
            </TableRow>

            <TableRow sx={styles.tableRow}>
              <TableCell sx={styles.tableColHeader}>Rejection Reason</TableCell>
              <TableCell sx={styles.tableColValue}>
                {bankDetails.rejectionReason}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
};
export default BankDetailsDialog;
const styles = {
  container: {
    backgroundColor: "rgba(12, 12, 12, 1)",
  },
  headerStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: "1.2rem",
    color: "#FFFFFF",
  },
  tableColHeader: {
    fontWeight: 350,
    color: "#ffffff",
  },
  tableColValue: {
    fontWeight: 600,
    color: "#d9d9d9",
  },
  iconBackground: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "rgba(0, 0, 0, 1)",
    width: "1em",
    height: "1em",
    borderRadius: "1em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    marginLeft: "1em",
  },
  tableRow: {
    "td,th": {
      borderBottom: "0.5px solid rgba(255, 255, 255, 0.2)",
    },
    "&:last-child td, &:last-child th": { border: 0 },
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.3)",
      transition: "all 0.3s ease-in-out",
    },
    cursor: "pointer",
  },
};
