import {
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import TransactionComponent from "./TransactionComponent";
import { CloseIcon } from "../assets";
import { useState } from "react";
import { isEmpty } from "../utils/Validation";
import PrimaryButton from "./PrimaryButton/PrimaryButton";
import { contactUs } from "../api/api";
import Loader from "./Loader";

type Props = {
  open: boolean;
  handleClose: () => void;
  onError: (error: string) => void;
  onSuccess: (message: string) => void;
};

const ContactUsForm = (props: Props) => {
  const { open, handleClose, onError, onSuccess } = props;
  const [subject, setSubject] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState("");
  const [loading, setLoading] = useState(false);

  const resetFields = () => {
    setSubject("");
    setMessage("");
    setMessageError("");
    setSubjectError("");
  };

  const handleContactUs = async () => {
    if (isEmpty(subject)) {
      setSubjectError("Subject is required");
    } else if (subject.length < 5) {
      setSubjectError("Subject should be mimium 5 characters");
    } else if (isEmpty(message)) {
      setMessageError("Message is required");
    } else if (message.length < 5) {
      setMessageError("Message should be mimium 10 characters");
    } else {
      setLoading(true);
      const body = {
        subject: subject,
        description: message,
      };
      const response = await contactUs(body);
      setLoading(false);
      if (response) {
        if (response.status === 200) {
          resetFields();
          onSuccess(response.data.message);
        } else {
          resetFields();
          onError(response.data.message);
        }
      }
    }
  };

  const handleCloseRequest = () => {
    resetFields();
    handleClose();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={TransactionComponent}
      keepMounted
      onClose={handleCloseRequest}
      aria-describedby="dialog-profile-description"
    >
      <DialogContent>
        <Stack direction={"row"} sx={styles.inlineSpaceBetween} mb={0.5}>
          <Typography sx={styles.titleText}>Contact Us</Typography>
          <IconButton sx={styles.iconBackground} onClick={handleCloseRequest}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
        <TextField
          variant="outlined"
          type="text"
          label="Subject"
          value={subject}
          onChange={(event) => {
            if (event.target.value && event.target.value.length > 0) {
              setSubjectError("");
            }
            setSubject(event.target.value);
          }}
          fullWidth
          error={subjectError !== "" ? true : false}
          helperText={subjectError === "" ? "" : subjectError}
          sx={styles.inputStyle}
        />
        <TextField
          variant="outlined"
          type="text"
          label="Description"
          multiline
          rows={4}
          value={message}
          onChange={(event) => {
            if (event.target.value && event.target.value.length > 0) {
              setMessageError("");
            }
            setMessage(event.target.value);
          }}
          fullWidth
          error={messageError !== "" ? true : false}
          helperText={messageError === "" ? "" : messageError}
          sx={styles.inputMultilineStyle}
        />
        {loading ? (
          <Loader />
        ) : (
          <PrimaryButton title="Send" onClick={handleContactUs} />
        )}
      </DialogContent>
    </Dialog>
  );
};
export default ContactUsForm;
const styles = {
  iconBackground: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "rgba(0, 0, 0, 1)",
    width: "1em",
    height: "1em",
    borderRadius: "1em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    marginLeft: "1em",
  },
  titleText: {
    fontSize: "1.2rem",
    fontWeight: 500,
  },
  inlineSpaceBetween: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  inlineCenter: {
    alignItems: "center",
  },
  inputStyle: {
    fontSize: "1rem",
    fontWeight: 400,
    marginTop: "1em",
  },
  inputMultilineStyle: {
    fontSize: "1rem",
    fontWeight: 400,
    marginTop: "1em",
    marginBottom: "3em",
  },
};
