import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Grid,
  Stack,
  Typography,
  DialogTitle,
} from "@mui/material";
import TransactionComponent from "./TransactionComponent";
import {
  ChevronRightIcon,
  CircleCheckedIcon,
  CircleStackIcon,
  CloseIcon,
  CopyIcon,
  DocumentClipIcon,
  QuestionMarkCircleIcon,
} from "../assets";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useEffect, useState } from "react";
import { requestGetTransactionDetails } from "../api/api";
import { AxiosResponse } from "axios";
import {
  TransactionDetail,
  TransactionDetailResponse,
} from "../types/TransactionResponseModel";
import { getTransactionListDateTimeFormat } from "../utils/DateUtils";
import { formatNumber, zeroNumber } from "../utils/Utils";
import AlertComponent from "./AlertComponent";
import EyeShowIcon from "../assets/svg/EyeShowIcon";
import EyeSlashIcon from "../assets/svg/EyeSlashIcon";
import { useNavigate } from "react-router-dom";

type Props = {
  open: boolean;
  txnID: number;
  handleClose: () => void;
  onError: (error: string) => void;
};

const TransactionDetails = (props: Props) => {
  const { open, txnID, handleClose, onError } = props;
  const navigate = useNavigate();
  const [trnxDetails, setTrnxDetails] = useState<TransactionDetail>();
  const [toastMessage, setToastMessage] = useState("");
  const [showRedeemCode, setShowRedeemCode] = useState(false);

  const getTransactionDetails = async () => {
    try {
      const response: AxiosResponse<TransactionDetailResponse> =
        await requestGetTransactionDetails(txnID);
      if (response) {
        if (response.status === 200) {
          setTrnxDetails(response.data.data);
        } else {
          onError(response.data.message);
        }
      }
    } catch (error) {
      console.log("Error : ", error);
    }
  };

  useEffect(() => {
    getTransactionDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTransactionTypeTextStyle = (type: string) => {
    switch (type) {
      case "Transfer":
        return {
          fontSize: "1.2rem",
          fontWeight: "500",
          color: "rgba(203, 31, 20, 1)",
        };
      case "Redeem":
        return {
          fontSize: "1.2rem",
          fontWeight: "500",
          color: "rgba(231, 188, 68, 1)",
        };
      case "Sell":
        return {
          fontSize: "1.2rem",
          fontWeight: "500",
          color: "rgba(203, 31, 20, 1)",
        };
      case "Received":
        return {
          fontSize: "1.2rem",
          fontWeight: "500",
          color: "rgba(20, 203, 27, 1)",
        };
      case "Buy":
        return {
          fontSize: "1.2rem",
          fontWeight: "500",
          color: "rgba(20, 203, 27, 1)",
        };
    }
  };

  const getPricePerUnit = (type: string) => {
    switch (type) {
      case "Transfer":
        return trnxDetails?.unitPrice;
      case "Redeem":
        return trnxDetails?.unitPrice;
      case "Sell":
        return trnxDetails?.unitPrice;
      case "Received":
        return trnxDetails?.unitPrice;
      case "Buy":
        return trnxDetails?.unitPrice;
      default:
        return trnxDetails?.unitPrice;
    }
  };

  const copyToClipboard = async (text: string | undefined) => {
    try {
      if (text) {
        await navigator.clipboard.writeText(text);
        setToastMessage("Address copied to clipboard");
      }
    } catch (error) {
      setToastMessage("Failed to copy address to clipboard");
    }
  };

  const handleNeedHelpClick = (event: any) => {
    event.stopPropagation();
    navigate("/dashboard/support");
    handleClose();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={TransactionComponent}
      keepMounted
      PaperProps={{
        style: {
          backgroundColor: "rgba(12, 12, 12, 1)",
          border: "0.5px solid rgba(255, 255, 255, 0.4)",
        },
      }}
      onClose={handleClose}
      aria-describedby="dialog-profile-description"
    >
      <DialogTitle>
        <Stack direction={"row"} sx={styles.inlineSpaceBetween}>
          <Typography variant="h4" component="h4">
            Transaction Details
          </Typography>
          <IconButton sx={styles.iconBackground} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent sx={styles.container}>
        <Stack direction={"row"} sx={styles.inlineCenter} mb={1}>
          <img
            src={trnxDetails?.cryptoIconUrl}
            style={{ width: 48, height: 48 }}
            alt="AEGCoinIcon"
          />
          <Stack direction={"row"} ml={1} gap={0.5} alignItems={"baseline"}>
            <Typography sx={styles.qtyTxt}>{`${formatNumber(
              trnxDetails?.txValue
            )}`}</Typography>
            <Typography sx={styles.qtyAegTxt}>
              {trnxDetails?.cryptoTicker}
            </Typography>
          </Stack>
          {(trnxDetails?.status === "Approved" ||
            trnxDetails?.status === "Successful") && (
            <VerifiedIcon sx={{ color: "#14CB1B", ml: 5 }} />
          )}
        </Stack>
        <Divider />
        <Stack direction={"row"} sx={styles.inlineCenter} py={2}>
          <Box sx={styles.iconBg}>
            <DocumentClipIcon />
          </Box>
          <Typography sx={styles.transactiontype} ml={0.5}>
            Transaction Type
          </Typography>
          <Typography
            sx={getTransactionTypeTextStyle(trnxDetails?.transactionType || "")}
            ml={2}
          >
            {trnxDetails?.transactionType}
          </Typography>
          {trnxDetails?.transactionType === "Transfer" ||
          trnxDetails?.transactionType === "Received" ? (
            <></>
          ) : (
            <>
              <Box sx={styles.iconBg} ml={3}>
                <CircleStackIcon />
              </Box>
              <Typography sx={styles.transactiontype} ml={0.5}>
                Transaction Price
              </Typography>
              <Typography sx={styles.smAED} ml={2}>
                AED &nbsp;<Typography>{trnxDetails?.txValueAed}</Typography>
              </Typography>
            </>
          )}
        </Stack>
        <Divider />
        {trnxDetails?.transactionType === "Redeem" ? (
          <>
            <Stack direction={"column"} sx={styles.inlineCenter} my={1}>
              <Typography sx={styles.transactiontype}>Redeem Code</Typography>
              <Stack direction={"row"} sx={styles.inlineCenter} mt={0.5}>
                <Typography mr={1}>
                  {showRedeemCode ? trnxDetails?.redeemCode : "XXXXXX"}
                </Typography>
                <IconButton onClick={() => setShowRedeemCode((prev) => !prev)}>
                  {showRedeemCode ? <EyeShowIcon /> : <EyeSlashIcon />}
                </IconButton>
              </Stack>
              <Typography sx={styles.smAED} mt={0.5}>
                Note: Please do not share your redeem code with anyone.
              </Typography>
            </Stack>

            <Divider />
          </>
        ) : (
          <></>
        )}
        {trnxDetails?.transactionType === "Transfer" ||
        trnxDetails?.transactionType === "Received" ? (
          <></>
        ) : (
          <Grid container spacing={2} sx={styles.inlineCenter} py={2}>
            <Grid item xs={12} md={4}>
              <Stack direction={"column"}>
                <Typography sx={styles.smTitle}>Price Per Coin</Typography>
                <Typography sx={styles.smAED}>
                  {trnxDetails?.cryptoTicker} &nbsp;
                  <Typography component="span">
                    {trnxDetails?.transactionType
                      ? getPricePerUnit(trnxDetails?.transactionType)
                      : zeroNumber()}
                  </Typography>
                </Typography>
              </Stack>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Stack direction={"column"} alignItems="center">
                <Typography sx={styles.smTitle}>Quantity</Typography>
                <Typography sx={styles.smAED}>
                  <Typography component="span">
                    {formatNumber(trnxDetails?.txValue)}
                  </Typography>
                </Typography>
              </Stack>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Stack direction={"column"} alignItems="flex-end">
                <Typography sx={styles.smTitle}>Total Price</Typography>
                <Typography sx={styles.smAED}>
                  AED &nbsp;
                  <Typography component="span">
                    {trnxDetails?.txValueAed}
                  </Typography>
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        )}
        <Divider />
        <Stack direction={"row"} sx={styles.inlineSpaceBetween} my={2}>
          <Stack direction={"row"} sx={styles.inlineCenter}>
            <Typography sx={styles.statusTxt}>Status:</Typography>
            <Typography sx={styles.statusValue} ml={1}>
              {trnxDetails?.status}
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            sx={styles.needHelp}
            onClick={(event) => {
              handleNeedHelpClick(event);
            }}
          >
            <QuestionMarkCircleIcon />
            <Typography sx={styles.smTitle} mx={0.5}>
              Need help
            </Typography>
            <ChevronRightIcon />
          </Stack>
        </Stack>

        <Stack direction={"row"} sx={styles.inlineSpaceBetween} mt={2}>
          <Stack direction={"row"} sx={styles.inlineCenter}>
            <CircleCheckedIcon />
            <Stack direction={"column"} ml={0.5}>
              <Typography sx={styles.dateTitleText}>Order Placed</Typography>
              <Typography sx={styles.smAED}>
                {trnxDetails?.orderPlaced
                  ? getTransactionListDateTimeFormat(trnxDetails?.orderPlaced)
                  : "-"}
              </Typography>
            </Stack>
          </Stack>

          <Stack direction={"row"} sx={styles.inlineCenter}>
            <CircleCheckedIcon />
            <Stack direction={"column"} ml={0.5}>
              <Typography sx={styles.dateTitleText}>Order Executed</Typography>
              <Typography sx={styles.smAED}>
                {getTransactionListDateTimeFormat(trnxDetails?.orderExecuted) ||
                  "-"}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Divider sx={{ my: 1 }} />
        <Stack direction={"column"} ml={0.5}>
          <Typography sx={styles.transactionTitle}>
            {trnxDetails?.transactionType === "Transfer" ||
            trnxDetails?.transactionType === "Received"
              ? "Transaction Hash"
              : "Order Id"}
          </Typography>
          <Stack direction={"row"} sx={styles.inlineCenter}>
            <Typography sx={styles.smTransactionId} mr={2}>
              {trnxDetails?.transactionType === "Transfer" ||
              trnxDetails?.transactionType === "Received"
                ? trnxDetails.transactionHash
                : trnxDetails?.orderId}
            </Typography>
            <IconButton
              onClick={() => {
                if (
                  trnxDetails?.transactionType === "Transfer" ||
                  trnxDetails?.transactionType === "Receive"
                ) {
                  copyToClipboard(trnxDetails.transactionHash);
                } else {
                  copyToClipboard(
                    trnxDetails?.orderId ? trnxDetails.orderId.toString() : ""
                  );
                }
              }}
            >
              <CopyIcon />
            </IconButton>
          </Stack>
        </Stack>
      </DialogContent>
      {toastMessage ? (
        <AlertComponent
          open={toastMessage ? true : false}
          onClose={() => setToastMessage("")}
          type={"info"}
          message={toastMessage}
        />
      ) : (
        <></>
      )}
    </Dialog>
  );
};
export default TransactionDetails;

const styles = {
  container: {
    backgroundColor: "rgba(12, 12, 12, 1)",
  },
  iconBackground: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "rgba(0, 0, 0, 1)",
    width: "1em",
    height: "1em",
    borderRadius: "1em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    marginLeft: "1em",
  },
  iconBg: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#1F1F1F",
    width: "2em",
    height: "2em",
    borderRadius: "2em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
  },
  inlineSpaceBetween: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  inlineCenter: {
    alignItems: "center",
  },
  needHelp: {
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  qtyTxt: {
    fontSize: "2.4rem",
    fontWeight: 600,
  },
  qtyAegTxt: {
    fontSize: "1rem",
    fontWeight: 500,
  },
  transactiontype: {
    fontSize: "1rem",
    fontWeight: 500,
  },
  trnxTypeValue: {
    fontSize: "1.2rem",
    fontWeight: 500,
  },
  verifyStyle: {
    width: "1em",
    height: "1em",
  },
  smAED: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    fontSize: "0.7rem",
    color: "#FFFFFF",
    fontWeight: 550,
  },
  smTransactionId: {
    fontSize: "0.7rem",
    fontWeight: 400,
  },
  smTitle: {
    color: "#b0b0b0",
    fontSize: "0.7rem",
    fontWeight: 500,
  },
  dateTitleText: {
    color: "#e0e0e0",
    fontSize: "0.7rem",
    fontWeight: 500,
  },
  statusTxt: {
    fontSize: "0.9rem",
    fontWeight: 400,
  },
  statusValue: {
    fontSize: "0.9rem",
    fontWeight: 600,
  },
  transactionTitle: {
    color: "#ffffff",
    fontSize: "0.7rem",
    fontWeight: 600,
  },
};
