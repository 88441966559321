import { Box } from "@mui/material";

const ItemSeparator = () => {
  return <Box sx={styles.separatorStyle} />;
};
export default ItemSeparator;

const styles = {
  separatorStyle: {
    width: "100%",
    height: "0.5px",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  },
};
