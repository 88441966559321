import { Box, Typography } from "@mui/material";
import Loader from "./Loader";

const DialogLoadingView = () => {
  return (
    <Box sx={styles.centerView}>
      <Typography sx={styles.loadingTitle} mb={2}>
        Please wait
      </Typography>
      <Typography sx={styles.loadingDesc} mb={3}>
        Waiting for confirmation
      </Typography>
      <Loader />
    </Box>
  );
};
export default DialogLoadingView;
const styles = {
  centerView: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "3em",
  },
  loadingTitle: {
    fontSize: "1.2rem",
    fontWeight: 600,
  },
  loadingDesc: {
    fontSize: "0.9rem",
    fontWeight: 300,
  },
};
