import {
  Box,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import TransactionComponent from "./TransactionComponent";
import {
  CloseIcon,
  CopyIcon,
  DownloadIcon,
  DropdownIcon,
  ProfileIcon,
} from "../assets";
import ItemSeparator from "./ItemSeparator";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers/RootReducer";
import QRCode from "react-qr-code";
import { trimAddress } from "../utils/Utils";
import AlertComponent from "./AlertComponent";
import { AlertDataProps } from "../types/Common";
import { toPng } from "html-to-image";

type Props = {
  open: boolean;
  handleClose: () => void;
  selectedCryptoCoin?: number;
};

interface Option {
  value: number;
  label: string;
  icon: string;
}

const ReceiveDialog = (props: Props) => {
  const { open, handleClose, selectedCryptoCoin } = props;
  const [selectedCoin, setSelectedCoin] = useState(-1);
  const [alertData, setAlertData] = useState<AlertDataProps>({
    message: "",
    type: "error",
  });
  const [dropdownOptions, setDropdownOptions] = useState<Option[]>([]);
  const walletData = useSelector((state: RootState) => state.walletState);
  const profileData = useSelector((state: RootState) => state.userProfileState);
  const qrCodeRef = useRef<any>(null);

  useEffect(() => {
    if (selectedCryptoCoin) {
      setSelectedCoin(selectedCryptoCoin);
    }
  }, [selectedCryptoCoin]);

  useEffect(() => {
    if (walletData.result?.wallets && walletData.result?.wallets.length > 0) {
      let walletArray: Option[] = [];
      walletData.result.wallets.forEach((wallet) => {
        const obj: Option = {
          value: wallet.cryptoId,
          label: wallet.cryptoName,
          icon: wallet.cryptoIconUrl,
        };
        walletArray.push(obj);
      });
      setDropdownOptions(walletArray);
      setSelectedCoin(walletData.result?.wallets[0]?.cryptoId);
    }
  }, [walletData.result?.wallets, open]);

  const selectedCoinData = useMemo(() => {
    if (selectedCoin === -1) {
      return walletData.result?.wallets[0];
    }
    return walletData?.result?.wallets.find(
      (option) => option.cryptoId === selectedCoin
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCoin]);

  const renderValue = useCallback(() => {
    if (selectedCoin === -1) {
      return (
        <Stack
          sx={{ alignItems: "center", padding: "0.3em" }}
          direction={"row"}
          gap={1}
        >
          <img
            src={walletData.result?.wallets[0].cryptoIconUrl}
            alt={walletData.result?.wallets[0]?.cryptoName}
            style={styles.dropdownIconStyle}
          />
          <Typography>{walletData.result?.wallets[0]?.cryptoName}</Typography>
        </Stack>
      );
    }
    const selectedItem = walletData?.result?.wallets.find(
      (option) => option.cryptoId === selectedCoin
    );
    return (
      <Stack
        sx={{ alignItems: "center", padding: "0.3em" }}
        direction={"row"}
        gap={1}
      >
        <img
          src={selectedItem?.cryptoIconUrl}
          alt={selectedItem?.cryptoName}
          style={styles.dropdownIconStyle}
        />
        <Typography>{selectedItem?.cryptoName}</Typography>
      </Stack>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCoin]);

  const copyToClipboard = async (text: string | undefined) => {
    try {
      if (text) {
        await navigator.clipboard.writeText(text);
        setAlertData({
          message: "address copied to clipboard",
          type: "success",
        });
      }
    } catch (error) {
      setAlertData({
        message: "failed to copy address to clipboard",
        type: "error",
      });
    }
  };

  const handleDownloadQr = () => {
    const qrCodeElement = qrCodeRef.current;
    toPng(qrCodeElement)
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = "qrcode.png";
        link.click();
      })
      .catch((error) => {
        console.error("Error generating QR code image:", error);
      });
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={TransactionComponent}
      keepMounted
      PaperProps={{
        style: {
          backgroundColor: "rgba(12, 12, 12, 1)",
          border: "0.5px solid rgba(255, 255, 255, 0.4)",
        },
      }}
      onClose={handleClose}
      aria-describedby="dialog-profile-description"
    >
      <DialogContent sx={styles.container}>
        <Box sx={styles.headerStyle}>
          <Typography sx={styles.dialogTitle}>Receive</Typography>
          <IconButton sx={styles.iconBackground} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <ItemSeparator />
        <Box sx={styles.dropdownRow}>
          <Typography sx={styles.dropDownLabel} mr={2}>
            Select Coin
          </Typography>
          <Select
            value={selectedCoin}
            displayEmpty
            IconComponent={() => <DropdownIcon />}
            sx={{
              width: "18em",
              backgroundColor: "#000000",
              boxShadow: "none",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              paddingRight: "1em",
            }}
            onChange={(event: any) => {
              setSelectedCoin(event.target.value);
              console.log("Event: ", event);
            }}
            renderValue={renderValue}
          >
            {dropdownOptions.map((element) => {
              return (
                <MenuItem value={element.value} sx={{ padding: "5px 0px" }}>
                  <Stack
                    sx={{ alignItems: "center", padding: "0.3em 1em" }}
                    direction={"row"}
                    gap={1}
                  >
                    <img
                      src={element.icon}
                      alt={element.label}
                      style={styles.dropdownIconStyle}
                    />
                    <Typography>{element.label}</Typography>
                  </Stack>
                </MenuItem>
              );
            })}
          </Select>
        </Box>
        <Card sx={styles.qrContainer} elevation={0}>
          <CardContent sx={{ padding: "1.5em 0px" }}>
            <Box sx={styles.qrCodeContainer}>
              <Stack
                sx={styles.centerVertical}
                direction={"row"}
                gap={2}
                mb={2}
              >
                {profileData.result?.profilePic ? (
                  <img
                    src={profileData.result?.profilePic}
                    style={styles.profileImageStyle}
                    alt={profileData.result?.firstName || ""}
                  />
                ) : (
                  <ProfileIcon />
                )}

                <Typography variant="h4" component={"h6"}>{`${
                  profileData.result?.firstName
                    ? profileData.result?.firstName
                    : ""
                } ${
                  profileData.result?.lastName
                    ? profileData.result?.lastName
                    : ""
                }`}</Typography>
              </Stack>
              <div ref={qrCodeRef}>
                <QRCode
                  size={128}
                  style={styles.qrStyle}
                  level="Q"
                  value={
                    selectedCoinData?.address ? selectedCoinData?.address : ""
                  }
                  viewBox={`0 0 128 128`}
                />
              </div>

              <Box sx={styles.selectedCoinContainer}>
                <img
                  src={selectedCoinData?.cryptoIconUrl}
                  style={styles.selectedCoin}
                  alt={selectedCoinData?.cryptoName}
                />
              </Box>
            </Box>
            <Box sx={styles.addressStrip} mt={2}>
              <Typography sx={{ fontWeight: 500, fontSize: "0.9em" }} mr={2}>
                {trimAddress(selectedCoinData?.address, 10)}
              </Typography>
              <IconButton
                onClick={() => {
                  copyToClipboard(selectedCoinData?.address);
                }}
              >
                <CopyIcon />
              </IconButton>
            </Box>
          </CardContent>
        </Card>
        <Box sx={styles.downloadBtnStyle} mt={3} onClick={handleDownloadQr}>
          <Typography sx={styles.downloadQRText} mr={2}>
            Download QR Code
          </Typography>
          <DownloadIcon />
        </Box>
      </DialogContent>
      <DialogActions></DialogActions>
      <AlertComponent
        open={alertData.message !== "" ? true : false}
        onClose={() => setAlertData({ message: "", type: "error" })}
        message={alertData.message}
        type={alertData.type ? alertData.type : "error"}
      />
    </Dialog>
  );
};
export default ReceiveDialog;
const styles = {
  container: {
    backgroundColor: "rgba(12, 12, 12, 1)",
  },
  headerStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: "1.4rem",
    color: "#FFFFFF",
  },
  iconBackground: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "rgba(0, 0, 0, 1)",
    width: "1em",
    height: "1em",
    borderRadius: "1em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    marginLeft: "1em",
  },
  dropDownLabel: {
    fontWeight: 600,
    fontSize: "1rem",
    color: "#FFFFFF",
  },
  dropdownRow: {
    my: 3,
    display: "flex",
    flexDirection: "row",
  },
  dropdownIconStyle: {
    width: 20,
    height: 20,
  },
  qrContainer: {
    background:
      "radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(217, 154, 38, 0.2) 0%, rgba(249, 250, 250, 0.0447917) 77.08%, rgba(181, 181, 181, 0) 100%)",
    color: "#fff",
    border: "0.5px solid rgba(135,123,61,1)",
    borderImage:
      "radial-gradient(circle, rgba(135,123,61,1) 0%, rgba(255,241,191,1) 31%, rgba(183,142,38,1) 72%, rgba(255,255,255,1) 100%) border-box",
  },
  qrCodeContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  profileImageStyle: {
    width: "3em",
    height: "3em",
    borderRadius: "3em",
    padding: "0.1em",
    border: "1px dashed #FFC800",
    backgroundColor: "#000000",
  },
  centerVertical: {
    alignItems: "center",
  },
  qrStyle: {
    height: "12em",
    width: "12em",
    padding: "0.5em",
    backgroundColor: "#FFFFFF",
    borderRadius: "1em",
  },
  selectedCoinContainer: {
    position: "absolute",
    backgroundColor: "#000000",
    padding: "0.8em",
    borderRadius: "3em",
    top: "50%",
    height: "2.8em",
    width: "2.8em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  selectedCoin: {
    height: "2em",
    width: "2em",
  },
  addressStrip: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.4)",
    padding: "0.2em",
  },
  downloadBtnStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "1em 0.5em",
    borderRadius: "4em",
    border: "0.5px solid rgba(135,123,61,1)",
    borderImage:
      "radial-gradient(circle, rgba(135,123,61,1) 0%, rgba(255,241,191,1) 31%, rgba(183,142,38,1) 72%, rgba(255,255,255,1) 100%) border-box",
    cursor: "pointer",
  },
  downloadQRText: {
    fontSize: "0.8rem",
    fontWeight: 400,
  },
};
