import React from "react";
import {
  Grid,
  Box,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  Stack,
  IconButton,
  Divider,
} from "@mui/material";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import TransactionComponent from "../../components/TransactionComponent";
import { CloseIcon } from "../../assets";
import { BankDetailRequest } from "../../types/BankDetailsModel";
import { addBankAccountRequest } from "../../api/api";
import { Form, FormikProvider, useFormik } from "formik";
import { addBankSchema } from "../../validations/Validations";

type Props = {
  open: boolean;
  handleClose: () => void;
  onSuccess: (message: string) => void;
  onError: (error: string) => void;
};

const AddBankAccountForm = (props: Props) => {
  const { open, handleClose, onError, onSuccess } = props;

  const submitBankDetails = async (body: BankDetailRequest) => {
    try {
      const response = await addBankAccountRequest(body);
      console.log("bank account added");
      if (response) {
        if (response.status === 200) {
          resetForm();
          onSuccess(response.data.message);
        } else {
          resetForm();
          onError(response.data.message);
        }
      }
    } catch (error) {
      resetForm();
      console.log("error: ", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      receipientName: "",
      bankName: "",
      bankAccountNumber: "",
      swiftCode: "",
      iBanCode: "",
      beneficiaryAddress: "",
    },
    validationSchema: addBankSchema,
    onSubmit: (value) => {
      const body: BankDetailRequest = {
        bank_name: value.bankName,
        account_number: value.bankAccountNumber,
        swift_bic: value.swiftCode,
        iban: value.iBanCode,
        beneficiary_name: value.beneficiaryAddress,
      };
      submitBankDetails(body);
    },
  });
  const { errors, touched, handleSubmit, getFieldProps, resetForm } = formik;

  const handleBankFormClose = () => {
    resetForm();
    handleClose();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={TransactionComponent}
      keepMounted
      onClose={handleBankFormClose}
      aria-describedby="dialog-bank-description"
    >
      <DialogContent>
        <Stack direction={"row"} sx={styles.inlineSpaceBetween} mb={1}>
          <Typography sx={styles.aedPrice}>Add Bank Account</Typography>
          <IconButton sx={styles.iconBackground} onClick={handleBankFormClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack direction={"column"} sx={styles.inlineCenter}>
              <Box sx={{ mt: 3 }}>
                <Grid container spacing={2} mb={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label="Recipient Name"
                      variant="outlined"
                      fullWidth
                      size="small"
                      inputProps={{ maxLength: 100 }}
                      {...getFieldProps("receipientName")}
                      error={Boolean(
                        touched.receipientName && errors.receipientName
                      )}
                      helperText={
                        touched.receipientName && errors.receipientName
                      }
                      InputProps={{ style: { color: "#FFD700" } }}
                      sx={{ input: { color: "white", fontWeight: 400 } }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label="Bank Name"
                      variant="outlined"
                      size="small"
                      inputProps={{ maxLength: 50 }}
                      sx={{ input: { color: "white", fontWeight: 400 } }}
                      fullWidth
                      InputProps={{ style: { color: "#FFD700" } }}
                      {...getFieldProps("bankName")}
                      error={Boolean(touched.bankName && errors.bankName)}
                      helperText={touched.bankName && errors.bankName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label="Bank Account Number"
                      variant="outlined"
                      size="small"
                      inputProps={{ maxLength: 30 }}
                      sx={{ input: { color: "white", fontWeight: 400 } }}
                      fullWidth
                      InputProps={{ style: { color: "#FFD700" } }}
                      {...getFieldProps("bankAccountNumber")}
                      error={Boolean(
                        touched.bankAccountNumber && errors.bankAccountNumber
                      )}
                      helperText={
                        touched.bankAccountNumber && errors.bankAccountNumber
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="SWIFT/BIC Code"
                      variant="outlined"
                      size="small"
                      inputProps={{ maxLength: 15 }}
                      sx={{ input: { color: "white", fontWeight: 400 } }}
                      fullWidth
                      InputProps={{ style: { color: "#FFD700" } }}
                      {...getFieldProps("swiftCode")}
                      error={Boolean(touched.swiftCode && errors.swiftCode)}
                      helperText={touched.swiftCode && errors.swiftCode}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="IBAN"
                      variant="outlined"
                      size="small"
                      inputProps={{ maxLength: 50 }}
                      sx={{ input: { color: "white", fontWeight: 400 } }}
                      fullWidth
                      InputProps={{ style: { color: "#FFD700" } }}
                      {...getFieldProps("iBanCode")}
                      error={Boolean(touched.iBanCode && errors.iBanCode)}
                      helperText={touched.iBanCode && errors.iBanCode}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Beneficiary Address"
                      variant="outlined"
                      size="small"
                      sx={{ input: { color: "white", fontWeight: 400 } }}
                      fullWidth
                      inputProps={{ maxLength: 100 }}
                      InputProps={{ style: { color: "#FFD700" } }}
                      {...getFieldProps("beneficiaryAddress")}
                      error={Boolean(
                        touched.beneficiaryAddress && errors.beneficiaryAddress
                      )}
                      helperText={
                        touched.beneficiaryAddress && errors.beneficiaryAddress
                      }
                    />
                  </Grid>
                </Grid>
                <PrimaryButton title="Add Account" onClick={handleSubmit} />
              </Box>
            </Stack>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
};

export default AddBankAccountForm;

const styles = {
  iconBackground: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "rgba(0, 0, 0, 1)",
    width: "1em",
    height: "1em",
    borderRadius: "1em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    marginLeft: "1em",
  },
  inlineSpaceBetween: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  inlineCenter: {
    alignItems: "center",
  },
  aedPrice: {
    fontSize: "1rem",
    fontWeight: 600,
  },
};
