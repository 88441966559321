import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  Dialog,
  DialogContent,
  Stack,
  IconButton,
  Divider,
} from "@mui/material";
import TransactionComponent from "./TransactionComponent";
import { CloseIcon, CopyIcon, VerifyIcon } from "../assets";
import { setupTwoFA } from "../api/api";
import QRCode from "react-qr-code";
import PrimaryButton from "./PrimaryButton/PrimaryButton";
import { AlertDataProps } from "../types/Common";
import AlertComponent from "./AlertComponent";
import EnableTwoFAContent from "./EnableTwoFAContent";
import { COLOR } from "../theme";

type Props = {
  open: boolean;
  handleClose: () => void;
  onSuccess: () => void;
};

const enum ViewState {
  TWF_QR,
  TWO_FA,
  CONFIRMATION,
}

interface QRData {
  secret: string;
  otpauthUrl: string;
}

const EnableTwoFA = (props: Props) => {
  const { open, handleClose, onSuccess } = props;
  const [viewState, setViewState] = useState(ViewState.TWF_QR);
  const [title, setTitle] = useState("Two step verification");
  const [qrResponse, setQrResponse] = useState<QRData>();
  const [alertData, setAlertData] = useState<AlertDataProps>({
    message: "",
    type: "error",
  });

  const getTwoFAQr = async () => {
    try {
      const response = await setupTwoFA();
      if (response && response.status === 200) {
        console.log("Response qr: ", response.data);
        setQrResponse(response.data.data);
      }
    } catch (error) {
      console.log("QR Error: ", error);
    }
  };

  useEffect(() => {
    getTwoFAQr();
  }, []);

  useEffect(() => {
    if (viewState === ViewState.CONFIRMATION) {
      setTimeout(() => {
        onSuccess();
      }, 1500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewState]);

  const handleQRScanned = () => {
    setTitle("Enable Two Step Verification");
    setViewState(ViewState.TWO_FA);
  };

  const copyToClipboard = async (text: string | undefined) => {
    try {
      if (text) {
        await navigator.clipboard.writeText(text);
        setAlertData({
          message: "address copied to clipboard",
          type: "success",
        });
      }
    } catch (error) {
      setAlertData({
        message: "failed to copy address to clipboard",
        type: "error",
      });
    }
  };

  const onClose = () => {
    setAlertData({ message: "", type: "error" });
    setViewState(ViewState.TWF_QR);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={TransactionComponent}
      keepMounted
      onClose={onClose}
      aria-describedby="dialog-bank-description"
    >
      <DialogContent sx={{ m: 0, p: 0 }}>
        <Stack direction={"row"} sx={styles.inlineSpaceBetween}>
          <Typography variant="h5" component="h6">
            {title}
          </Typography>
          <IconButton sx={styles.iconBackground} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />

        {viewState === ViewState.TWF_QR ? (
          <Box sx={styles.qrContainer}>
            <Typography sx={styles.descText} my={2} px={3}>
              Scan this QR code with your mobile app or copy secret and enter
              manually
            </Typography>
            <Box sx={styles.qrBox} mb={3}>
              <QRCode
                size={256}
                style={styles.qrCodeStyle}
                value={qrResponse?.otpauthUrl ? qrResponse?.otpauthUrl : ""}
                viewBox={`0 0 256 256`}
              />
            </Box>
            <Stack sx={styles.copyCodeContainer} direction={"row"} gap={1}>
              <Typography sx={styles.copyCode}>Copy Code</Typography>
              <IconButton onClick={() => copyToClipboard(qrResponse?.secret)}>
                <CopyIcon />
              </IconButton>
            </Stack>
            <Container sx={{ my: 2 }}>
              <PrimaryButton title="Proceed" onClick={handleQRScanned} />
            </Container>
          </Box>
        ) : (
          <></>
        )}
        {viewState === ViewState.TWO_FA ? (
          <EnableTwoFAContent
            onSuccess={() => {
              setTitle("Two step verification");
              setViewState(ViewState.CONFIRMATION);
            }}
          />
        ) : (
          <></>
        )}
        {viewState === ViewState.CONFIRMATION ? (
          <Box sx={styles.confirmationContainer}>
            <img src={VerifyIcon} style={styles.verifyIcon} alt="Verify" />
            <Typography sx={styles.enabledText}>Enabled</Typography>
            <Typography sx={styles.enabledDescText}>
              Two step verification is enabled
            </Typography>
          </Box>
        ) : (
          <></>
        )}
      </DialogContent>
      <AlertComponent
        open={alertData.message !== "" ? true : false}
        onClose={() => setAlertData({ message: "", type: "error" })}
        message={alertData.message}
        type={alertData.type ? alertData.type : "error"}
      />
    </Dialog>
  );
};

export default EnableTwoFA;
const styles = {
  iconBackground: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "rgba(0, 0, 0, 1)",
    width: "1em",
    height: "1em",
    borderRadius: "1em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    marginLeft: "1em",
  },
  inlineSpaceBetween: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.5em 1em",
  },
  inlineCenter: {
    alignItems: "center",
  },
  copyCodeContainer: {
    width: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    alignItems: "center",
    justifyContent: "center",
    padding: "0.5em 1em",
  },
  coldIcon: {
    width: 28,
    height: 28,
  },
  qrContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  confirmationContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "1em 2em",
  },
  qrBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "0.5em",
    backgroundColor: "#FFFFFF",
    width: "9em",
    height: "9em",
  },
  qrCodeStyle: {
    width: "8em",
    height: "8em",
  },
  verifyIcon: {
    width: "5em",
    height: "5em",
  },
  inputPrefix: {
    fontSize: "1rem",
    fontWeight: 300,
    backgroundColor: COLOR.background,
    padding: "0.1em 0.3em",
    paddingLeft: "0.5em",
    paddingRight: "0.5em",
    height: "3.5rem",
    display: "flex",
    alignItems: "center",
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
    boxSizing: "border-box",
  },
  inputStyle: {
    fontSize: "1rem",
  },
  descText: {
    fontSize: "0.7rem",
    fontWeight: 300,
    textAlign: "center",
    maxWidth: "300px",
    wordWrap: "break-word",
  },
  copyCode: {
    fontSize: "0.8rem",
    fontWeight: 300,
  },
  enabledText: {
    fontSize: "1.2rem",
    fontWeight: 600,
    color: "#FFC800",
    margin: "1em 0em",
  },
  enabledDescText: {
    fontSize: "0.8rem",
    fontWeight: 300,
    color: "#FFFFFF",
    marginBottom: "1em",
  },
};
