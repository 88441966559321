import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { Container, Typography, Box } from "@mui/material";

const TermsAndCondition = () => {
  return (
    <div style={{ backgroundColor: "black" }}>
      <Navbar />
      <Typography
        variant="h2"
        component="div"
        sx={{
          mb: 4,
          textAlign: "center",
          mt: 5,
          size: "52px",
          padding: "0px 100px 10px 100px",
        }}
      >
        Terms And Condition
      </Typography>
      <Container style={{ color: "white" }}>
        <Box my={4}>
          <Typography variant="body1" paragraph>
            Last updated: 12 June 2024
          </Typography>
          <Typography variant="body1" paragraph sx={{ pt: 4 }}>
            Welcome to AEG FOR INVESTMENT IN TECHNOLOGICAL ENTERPRISES AND
            MANAGEMENT L.L.C ("https://aegt.io"). By accessing or using our
            Site, you agree to be bound by these Terms and Conditions (the
            “Terms”). If you do not agree with any part of these Terms, you must
            not use our Site.
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ pt: 4 }}>
            1. Acceptance of Terms
          </Typography>
          <Typography variant="body1" paragraph>
            By accessing and using the Site, you confirm that you have read,
            understood, and agree to these Terms. If you are using the Site on
            behalf of a business or entity, you represent and warrant that you
            have the authority to bind that business or entity to these Terms.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ pt: 4 }}>
            2. Changes to Terms
          </Typography>
          <Typography variant="body1" paragraph>
            We may modify these Terms from time to time. If we make significant
            changes, we will notify you by posting a notice on the Site or via
            email. Your continued use of the Site after changes are made
            constitutes your acceptance of the new Terms.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ pt: 4 }}>
            3. User Accounts
          </Typography>
          <Typography variant="body1" paragraph>
            <ul>
              <li>
                You may be required to create an account to access certain
                features of the Site. You are responsible for maintaining the
                confidentiality of your account information and for all
                activities that occur under your account.
              </li>
              <li>
                You must provide accurate and complete information when creating
                your account and update it as necessary.
              </li>
              <li>
                We reserve the right to suspend or terminate your account at our
                discretion if you violate these Terms or engage in any
                fraudulent or harmful activity.
              </li>
            </ul>
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ pt: 4 }}>
            4. Use of the Site
          </Typography>
          <Typography variant="body1" paragraph>
            <ul>
              <li>
                You agree to use the Site only for lawful purposes and in a
                manner that does not infringe the rights of, or restrict or
                inhibit the use and enjoyment of the Site by any third party.
              </li>
              <li>You agree not to:</li>
              <ul>
                <li>
                  Use the Site in any way that violates any applicable federal,
                  state, local, or international law or regulation.
                </li>
                <li>
                  Transmit or procure the sending of any advertising or
                  promotional material without our prior written consent.
                </li>
              </ul>
            </ul>
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ pt: 4 }}>
            Contact Us
          </Typography>
          <Typography variant="body1" paragraph>
            If you have any questions about this Privacy Policy, please contact
            us at info@aegt.io.
          </Typography>
        </Box>
      </Container>
      <Footer />
    </div>
  );
};

export default TermsAndCondition;
