import React from "react";

type Props = {
  width?: string;
  height?: string;
};

const UserIcon = (props: Props) => {
  const { width = "1em", height = "1em" } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.56248 5.25C6.56248 3.07538 8.32536 1.3125 10.5 1.3125C12.6746 1.3125 14.4375 3.07538 14.4375 5.25C14.4375 7.42462 12.6746 9.1875 10.5 9.1875C8.32536 9.1875 6.56248 7.42462 6.56248 5.25Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.28232 17.5922C3.35 13.6636 6.55547 10.5 10.5 10.5C14.4446 10.5 17.6501 13.6638 17.7177 17.5924C17.7221 17.853 17.572 18.0915 17.3352 18.2002C15.2536 19.1553 12.9382 19.6875 10.5003 19.6875C8.06211 19.6875 5.74649 19.1552 3.66477 18.1999C3.42793 18.0912 3.27784 17.8527 3.28232 17.5922Z"
        fill="white"
      />
    </svg>
  );
};
export default UserIcon;
