import React from "react";
import { Box, styled, Typography } from "@mui/material";
import DashboardNavbar from "./DashboardNavbar";
import { useState } from "react";
import DashboardSidebar from "./DashboardSidebar";
import {
  APP_BAR_DESKTOP,
  APP_BAR_MOBILE,
  APP_COPYRIGHT,
  APP_VERSION,
} from "../../utils/AppConstants";
import { Outlet } from "react-router-dom";

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  height: "100vh",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: 5,
  background: "#000000",
  color: "white",
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 22,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: 0,
  },
}));

const DashboardLayout = () => {
  const [open, setOpen] = useState(false);
  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />
      <MainStyle>
        <Outlet />
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            right: 0,
            left: { xs: 0, sm: 0, lg: 180 },
            backgroundColor: "#000000",
            display: "flex",
            alignSelf: "flex-end",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={copyrightStyle}
          >{`Version: ${APP_VERSION}`}</Typography>
          <Typography sx={copyrightStyle}>{APP_COPYRIGHT}</Typography>
        </Box>
      </MainStyle>
    </RootStyle>
  );
};
export default DashboardLayout;

const copyrightStyle = {
  fontSize: "0.8em",
  color: "rgba(255,255,255,0.3)",
  fontWeight: 500,
  padding: "0.5em 3em",
};
