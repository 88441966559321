import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Stack,
} from "@mui/material";
import Iconify from "../../components/icons/Iconify";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers/RootReducer";
import { ProfileIcon } from "../../assets";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { DRAWER_WIDTH } from "./DashboardSidebar";
import { junit } from "node:test/reporters";

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 70;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: "#000000",
  width: "100%",
  sm: { left: 0 },
  lg: { left: DRAWER_WIDTH },
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  backgroundColor: "#000000",
  [theme.breakpoints.up("lg")]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const DashboardNavbar = ({ onOpenSidebar }: any) => {
  const location = useLocation();
  const userProfile = useSelector((state: RootState) => state.userProfileState);

  const pageTitle = useMemo(() => {
    switch (location.pathname) {
      case "/dashboard":
        return "Dashboard";
      case "/dashboard/wallet":
        return "Wallet";
      case "/dashboard/transactions":
        return "Transactions";
      case "/dashboard/support":
        return "Support";
      case "/dashboard/settings":
        return "Settings";
      default:
        return "hi 👋, Welcome";
    }
  }, [location.pathname]);

  return (
    <RootStyle
      style={{
        backgroundColor: "#000000",
        position: "absolute",
        top: 0,
        right: 0,
        width: window.innerWidth - 50,
        boxShadow:
          "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
        padding: "0px",
        margin: "0px",
      }}
    >
      <ToolbarStyle
        sx={{
          padding: 0,
          marginLeft: -7,
          sm: {
            marginLeft: 0,
          },
          xs: {
            marginLeft: 0,
          },
        }}
      >
        <IconButton
          onClick={onOpenSidebar}
          sx={{
            position: "absolute",
            left: 8,
            top: "0.5em",
            color: "#FFFFFF",
            display: { lg: "none" },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            width: "80vw",
            marginLeft: { sm: 5, md: 5, lg: 32 },
            md: { width: window.innerWidth - DRAWER_WIDTH },
            lg: { width: window.innerWidth - DRAWER_WIDTH },
          }}
        >
          <Typography
            sx={{ fontSize: "1.5rem", color: "#FFFFFF", fontWeight: 700 }}
          >
            {pageTitle}
          </Typography>
          <Box sx={styles.profileRow}>
            <Typography
              variant={"h6"}
              sx={{
                color: "#FFFFFF",
                fontWeight: 700,
                display: { md: "block", lg: "block", xs: "none", sm: "none" },
              }}
            >
              {`${
                userProfile.result?.firstName
                  ? userProfile.result?.firstName
                  : ""
              } ${
                userProfile.result?.lastName ? userProfile.result?.lastName : ""
              }`}
            </Typography>
            <Box sx={styles.profilePicture}>
              {userProfile.result?.profilePic ? (
                <img
                  src={userProfile.result?.profilePic}
                  style={styles.profilePictureImg}
                  alt="ProfileImage"
                />
              ) : (
                <ProfileIcon />
              )}
            </Box>
          </Box>
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
};
export default DashboardNavbar;

const styles = {
  profileRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  profilePicture: {
    marginRight: "0.5em",
    marginLeft: "0.5em",
  },
  profilePictureImg: {
    height: "50px",
    width: "50px",
    borderRadius: "200px",
    padding: "5px",
    border: "0.5px dashed rgba(255, 200, 0, 1)",
    backgroundColor: "#000000",
  },
};
