import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const TransferIcon = (props: Props) => {
  const { width = 43, height = 19 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.6667 0H6.33333C3.11683 0 0.5 2.61683 0.5 5.83333V22.1667C0.5 25.3832 3.11683 28 6.33333 28H22.6667C25.8832 28 28.5 25.3832 28.5 22.1667V5.83333C28.5 2.61683 25.8832 0 22.6667 0ZM13.065 12.5218L11 10.3122V19.8333C11 20.4773 10.4785 21 9.83333 21C9.18817 21 8.66667 20.4773 8.66667 19.8333V10.2585L6.43367 12.4915C5.9775 12.9477 5.24017 12.9477 4.784 12.4915C4.32783 12.0353 4.32783 11.298 4.784 10.8418L7.77183 7.854C8.90933 6.7165 10.7597 6.7165 11.896 7.854L14.7695 10.9293C15.2093 11.3995 15.1837 12.138 14.7135 12.579C14.4895 12.789 14.2025 12.8928 13.9167 12.8928C13.604 12.8928 13.2937 12.7692 13.065 12.523V12.5218ZM24.2172 17.1582L21.2293 20.146C20.6612 20.7142 19.9145 20.9988 19.1667 20.9988C18.4188 20.9988 17.6733 20.7142 17.104 20.146L14.1162 17.1582C13.66 16.702 13.66 15.9647 14.1162 15.5085C14.5723 15.0523 15.3097 15.0523 15.7658 15.5085L17.9988 17.7415V8.16667C17.9988 7.52267 18.5203 7 19.1655 7C19.8107 7 20.3322 7.52267 20.3322 8.16667V17.7415L22.5652 15.5085C23.0213 15.0523 23.7587 15.0523 24.2148 15.5085C24.671 15.9647 24.671 16.702 24.2148 17.1582H24.2172Z"
        fill="white"
      />
    </svg>
  );
};
export default TransferIcon;
