import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { COLOR } from "../../theme";
import { useLocation } from "react-router-dom";

type Props = {
  height?: number;
  width?: string;
  page?: string;
};

const ChartWidget = (props: Props) => {
  const { height = window.innerHeight, width = "100%", page = "inner" } = props;
  const location = useLocation();
  const [containerLeft, setContainerLeft] = useState(0);
  const [containerTop, setContainerTop] = useState(0);
  const [mobilePosition, setMobilePosition] = useState({
    top: "",
    left: "",
    padding: "",
    height: "",
  });

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get("source") === "mobile") {
      setMobilePosition({
        top: "0.212em",
        left: "3.35em",
        padding: "8px 4em 8px 0px",
        height: "10em",
      });
    }

    // Inject TradingView widget script dynamically
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      symbols: [["FX_IDC:XAUUSDG|1Y|AED"]],
      chartOnly: false,
      locale: "en",
      colorTheme: "dark",
      width: "100%",
      height: "100%",
      backgroundColor: COLOR.background,
      autosize: true,
      showVolume: false,
      showMA: false,
      hideDateRanges: false,
      hideMarketStatus: true,
      hideSymbolLogo: false,
      scalePosition: "right",
      scaleMode: "Normal",
      fontFamily: "Trebuchet MS, sans-serif",
      fontSize: "10",
      noTimeScale: false,
      valuesTracking: "1",
      changeMode: "price-and-percent",
      chartType: "area",
      maLineColor: "#2962FF",
      maLineWidth: 1,
      maLength: 9,
      headerFontSize: "medium",
      gridLineColor: "rgba(67, 70, 81, 0.61)",
      widgetFontColor: "rgba(255, 255, 255, 1)",
      lineWidth: 1,
      lineType: 0,
      dateRanges: [
        "1d|1",
        "1w|60",
        "1m|30",
        "3m|60",
        "12m|1D",
        "60m|1W",
        "all|1M",
      ],
      lineColor: "rgba(241, 178, 15, 1)",
      topColor: "rgba(251, 192, 45, 0.85)",
      bottomColor: "rgba(241, 178, 15, 0.15)",
    });
    document
      .getElementsByClassName("tradingview-widget-container__widget")[0]
      .appendChild(script);
    const container: any = document.getElementsByClassName(
      "tradingview-widget-container"
    )[0];
    setContainerLeft(container.getBoundingClientRect().left);
    setContainerTop(container.getBoundingClientRect().top);
    console.log("Container Size: ", container.getBoundingClientRect());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {page === "inner" ? (
        <Box
          sx={{
            position: "absolute",
            top: 32,
            left: {
              sm: containerLeft + 100,
              md: containerLeft ? containerLeft - 110 : 0,
            },
            backgroundColor: COLOR.background,
            color: "white",
            padding: "8px 10em 8px 0px",
            fontSize: "1.1rem",
            fontWeight: 600,
            fontFamily: "Trebuchet MS, sans-serif",
            zIndex: 1000,
          }}
        >
          GOLD / AED GRAM
        </Box>
      ) : (
        <></>
      )}

      <Box className="tradingview-widget-container" sx={{ height: height }}>
        <Box
          className="tradingview-widget-container__widget"
          sx={{
            height: height,
            borderRadius: 2,
            overflow: "hidden",
            border: "1px solid #383118",
          }}
        ></Box>
      </Box>
    </>
  );
};

export default ChartWidget;
