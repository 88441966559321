import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const MailIcon = (props: Props) => {
  const { width = 18, height = 18 } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 21 17" fill="none">
      <path
        d="M0 4.9191V13.5C0 15.1569 1.34315 16.5 3 16.5H18C19.6569 16.5 21 15.1569 21 13.5V4.9191L12.0723 10.4131C11.1081 11.0064 9.89193 11.0064 8.92771 10.4131L0 4.9191Z"
        fill="white"
      />
      <path
        d="M21 3.15783V3C21 1.34315 19.6569 0 18 0H3C1.34315 0 0 1.34315 0 3V3.15783L9.71386 9.13558C10.196 9.43227 10.804 9.43227 11.2861 9.13558L21 3.15783Z"
        fill="white"
      />
    </svg>
  );
};
export default MailIcon;
