import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import Navbar from "../../components/Navbar";
import { useRef } from "react";
import { COLOR } from "../../theme";
import { items } from "./productData";
import Footer from "../../components/Footer";
import { useNavigate } from "react-router-dom";

const Products = () => {
  const containerRef = useRef<any>(null);
  const navigate = useNavigate();
  const scrollToAbout = () => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleLoginClick = () => {
    navigate("/auth/login");
  };

  return (
    <div style={styles.container}>
      <Navbar scrollToAbout={scrollToAbout} />
      <Container sx={styles.containerStyle}>
        <Grid container spacing={2} alignItems={"center"} mt={4}>
          <Grid item xs={12} sm={12} md={6}>
            <img
              src="./static/images/gold-bar.png"
              alt="Gold Bars"
              style={{ width: "60%" }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Typography sx={styles.headingText}>OUR PRODUCTS</Typography>
            <Typography sx={styles.subHeadingText}>
              Invest in the highest purity 24K gold with confidence
            </Typography>
            <Typography sx={styles.descriptionText}>
              We proudly offer 24 Karat gold bars of the highest purity, stored
              securely in our private vaults within the UAE. Our gold bars
              provide a reliable, tangible asset for investors seeking long-term
              stability and value.
            </Typography>
            <Typography sx={styles.descriptionText} mt={1}>
              In addition to their unmatched purity, our gold bars offer
              flexibility and accessibility for investors. You can easily buy,
              store, or redeem your gold at any time, with the option for
              physical delivery within the UAE. Our commitment to transparency
              means that all transactions are based on the Global Price Index,
              ensuring fair market value at every step. Whether you're a
              seasoned investor or new to precious metals, our secure vaults, no
              storage fees, and seamless redemption process provide a
              comprehensive solution for preserving and growing your wealth in
              gold.
            </Typography>
          </Grid>
        </Grid>
        <Typography
          variant="h3"
          component="div"
          sx={{
            textAlign: "center",
            mb: 4,
            padding: { xs: "20px", md: "40px" },
            fontSize: { xs: "24px", md: "32px" },
          }}
        >
          OUR KEY FEATURES
        </Typography>
        <Grid container spacing={4}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Paper sx={styles.featureCard}>
                <Box sx={styles.featureCardIcon}>{item.icon}</Box>
                <Typography sx={styles.featureCardTitle}>
                  {item.title}
                </Typography>
                <Typography sx={styles.featureCardDescription}>
                  {item.description}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>

        <Container
          sx={{
            mt: 8,
            mb: 2,
            backgroundColor: "#F1B20F",
            padding: { xs: "20px", md: "40px" },
            borderRadius: "10px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={9}>
              <Typography
                component="h3"
                sx={{
                  fontSize: { xs: "20px", md: "30px" },
                  color: "black",
                  fontWeight: "900",
                  pl: { xs: "20px", md: "40px" },
                }}
              >
                THE FUTURE OF DIGITAL GOLD OWNERSHIP
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "flex-end" },
                alignItems: "center",
              }}
            >
              <Button
                variant="outlined"
                onClick={handleLoginClick}
                style={{
                  color: "white",
                  backgroundColor: "black",
                  borderRadius: "25px",
                  padding: "10px 30px",
                }}
              >
                Buy Now
              </Button>
            </Grid>
          </Grid>
        </Container>
        <Footer scrollToAbout={scrollToAbout} />
      </Container>
    </div>
  );
};
export default Products;

const styles = {
  container: {
    backgroundColor: "#000000",
  },
  containerStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    padding: { xs: "20px", md: "10px" },
    gap: "20px",
    backgroundColor: "black",
    color: "white",
    textAlign: "center",
  },
  contentContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  headingText: {
    color: COLOR.primary,
    fontWeight: 300,
    fontSize: "0.95rem",
    marginBottom: "0.5em",
    textAlign: "left",
  },
  subHeadingText: {
    color: "#FFFFFF",
    fontWeight: 700,
    fontSize: "2rem",
    marginBottom: "0.5em",
    textAlign: "left",
  },
  descriptionText: {
    fontSize: "0.95rem",
    fontWeight: 300,
    textAlign: "left",
  },
  featureCard: {
    backgroundColor: "#1c1c1c",
    padding: "20px",
    height: "100%",
  },
  featureCardIcon: {
    display: "flex",
    flexDirection: "column",
    width: "40px",
    alignItems: "center",
    justifyContent: "left",
    p: "10px 25px",
    mb: 2,
    borderRadius: "10px",
    bgcolor: "#f1b20f2e",
  },
  featureCardTitle: {
    fontSize: "1.1rem",
    marginLeft: "10px",
    color: "white",
    fontWeight: "bold",
    textAlign: "left",
    mb: 2,
  },
  featureCardDescription: {
    fontSize: "0.9rem",
    marginLeft: "10px",
    textAlign: "left",
    color: "white",
    fontFamily: "system-ui",
    fontWeight: "300",
  },
};
