import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const SupportIcon = (props: Props) => {
  const { width = 43, height = 19 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.31842 11.8124C0.507292 11.8124 -0.122711 11.08 0.0203522 10.282C0.491542 7.65572 1.80011 5.21446 3.76755 3.37039C6.30725 0.988198 9.62395 -0.199619 13.1205 0.0274448C19.7106 0.452696 24.8582 6.18965 24.7611 13.0436C24.7086 16.7422 21.6255 19.6888 17.9256 19.6888H14.0773C13.6048 20.1429 12.9669 20.4251 12.2608 20.4251C10.8105 20.4251 9.63577 19.2504 9.63577 17.8001C9.63577 16.3498 10.8105 15.1751 12.2608 15.1751C13.4525 15.1751 14.4474 15.9744 14.7677 17.0638H17.9256C20.2041 17.0638 22.1111 15.2486 22.1361 12.9714C22.1964 7.52972 18.1395 2.98452 12.9512 2.64852C10.1962 2.47789 7.56857 3.40714 5.56175 5.28796C3.97493 6.77503 2.95249 8.67948 2.59418 10.7716C2.48918 11.3832 1.93792 11.8151 1.31842 11.8151V11.8124ZM12.2936 22.3125C7.44388 22.3125 3.2478 25.3207 1.85261 29.7964C1.72792 30.1954 1.80011 30.6298 2.04817 30.9658C2.29624 31.3018 2.68868 31.5 3.10605 31.5H21.4811C21.8985 31.5 22.2909 31.3018 22.539 30.9658C22.7871 30.6298 22.8593 30.1954 22.7346 29.7964C21.3394 25.3207 17.1433 22.3125 12.2936 22.3125ZM16.1445 14.4374H19.2341C19.4113 13.8114 19.5124 13.1525 19.5124 12.4687C19.5124 8.48129 16.281 5.2499 12.2936 5.2499C8.3062 5.2499 5.07481 8.48129 5.07481 12.4687C5.07481 14.4256 5.85706 16.1975 7.12101 17.4982C7.27982 14.7866 9.50845 12.6301 12.2608 12.6301C13.824 12.6301 15.2008 13.3402 16.1445 14.4374Z" />
    </svg>
  );
};
export default SupportIcon;
