import { TableCell, TableRow, Typography } from "@mui/material";
import { DataNotFound } from "../assets";

const TableNoDataFound = () => {
  return (
    <TableRow sx={styles.noBorder}>
      <TableCell colSpan={6} align="center">
        <img
          src={DataNotFound}
          alt="NoDataFound"
          style={{
            width: "15em",
            height: "15em",
            margin: "2em auto 0 auto",
          }}
        />
        <Typography sx={styles.noDataFoundTextHeading}>Sorry!</Typography>
        <Typography sx={styles.noDataFoundText} mb={4}>
          No transaction data found
        </Typography>
      </TableCell>
    </TableRow>
  );
};
export default TableNoDataFound;

const styles = {
  noBorder: {
    "td,th": { border: 0 },
  },
  noDataFoundText: {
    fontSize: "0.9rem",
    fontWeight: "500",
    color: "rgba(165, 164, 164, 1)",
    textAlign: "center",
  },
  noDataFoundTextHeading: {
    fontSize: "1.8rem",
    fontWeight: 800,
    color: "#454973",
    textAlign: "center",
  },
};
