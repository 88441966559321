import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import { Drawer, IconButton, Stack, Typography } from "@mui/material";
import useResponsive from "../../hooks/useResponsive";
import NavSection from "../../components/NavSection";
import LogoIcon from "../../assets/svg/Logo";
import { Scrollbar } from "../../components/Scrollbar";
import Iconify from "../../components/icons/Iconify";
export const DRAWER_WIDTH = 280;
const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

type Props = {
  isOpenSidebar?: boolean;
  onCloseSidebar: () => void;
};

const DashboardSidebar = (props: Props) => {
  const { isOpenSidebar, onCloseSidebar } = props;
  const { pathname } = useLocation();
  const isDesktop = useResponsive({ query: "up", key: "lg" });

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const useStyles = makeStyles({
    item: {
      backgroundColor: "#000000",
    },
    middle: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      fontWeight: 700,
      fontSize: 24,
    },
    title: {
      fontWeight: 700,
      fontSize: "1.3rem",
    },
    body: {
      fontSize: 13,
      color: "#1f1f1f",
    },
  });
  const classes = useStyles();
  const renderContent = (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          display: "flex",
          flexDirection: "column",
          flex: 1,
          height: "100vh",
        },
      }}
      className={classes.item}
    >
      <Stack direction={"column"} gap={1} sx={{ flex: 1 }}>
        <Stack
          direction={"row"}
          gap={1}
          mt={2}
          ml={"2em"}
          sx={{ alignItems: "center" }}
        >
          <LogoIcon />
          <Typography className={classes.title} ml={1}>
            AEG
          </Typography>
          <IconButton
            onClick={onCloseSidebar}
            sx={{
              marginLeft: "1em",
              color: "text.primary",
              display: { md: "none", lg: "none" },
            }}
          >
            <Iconify icon="eva:close-outline" />
          </IconButton>
        </Stack>
        <NavSection style={{ padding: "2em 0px", flex: 1 }} />
      </Stack>
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "#000000",
              border: "0px",
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
};
export default DashboardSidebar;
