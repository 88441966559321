import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const PlatformTagIcon = (props: Props) => {
  const { width = 12, height = 18 } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 14 20" fill="none">
      <path
        d="M4.15636 0H9.14423C11.4362 0 13.3008 1.86917 13.3008 4.16667V15.8333C13.3008 18.1308 11.4362 20 9.14423 20H4.15636C1.86444 20 -0.000188828 18.1308 -0.000188828 15.8333V4.16667C-0.000188828 1.86917 1.86444 0 4.15636 0ZM5.81899 17.5H7.48161C7.94049 17.5 8.31292 17.1267 8.31292 16.6667C8.31292 16.2067 7.94049 15.8333 7.48161 15.8333H5.81899C5.3601 15.8333 4.98767 16.2067 4.98767 16.6667C4.98767 17.1267 5.3601 17.5 5.81899 17.5Z"
        fill="#FADD07"
      />
    </svg>
  );
};
export default PlatformTagIcon;
