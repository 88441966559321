import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const TransferIcon = (props: Props) => {
  const { width = 43, height = 19 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.501 6.081C4.501 4.416 6.084 2.606 7.641 1.857C7.458 1.674 7.282 1.474 7.131 1.259C6.759 0.731 7.18 0 7.826 0H10.173C10.856 0 11.229 0.776 10.824 1.326C10.684 1.516 10.526 1.694 10.362 1.857C11.919 2.607 13.5 4.416 13.5 6.08C13.5 7.69 12.267 8.999 10.75 8.999H7.25C5.733 8.999 4.5 7.689 4.5 6.08L4.501 6.081ZM16.208 4.293L17.501 3V6C17.501 6.552 17.948 7 18.501 7C19.054 7 19.501 6.552 19.501 6V3L20.794 4.293C20.989 4.488 21.245 4.586 21.501 4.586C21.757 4.586 22.013 4.488 22.208 4.293C22.599 3.902 22.599 3.27 22.208 2.879L19.915 0.586C19.542 0.212 19.056 0.018 18.565 0.002C18.544 0.001 18.523 0 18.501 0C18.479 0 18.458 0 18.437 0.002C17.946 0.018 17.46 0.212 17.087 0.586L14.794 2.879C14.403 3.27 14.403 3.902 14.794 4.293C15.185 4.684 15.817 4.684 16.208 4.293ZM23.649 8.681C23.134 8.212 22.463 7.969 21.771 8.003C21.074 8.035 20.432 8.337 19.977 8.838L16.436 12.575C16.468 12.785 16.501 12.995 16.501 13.213C16.501 15.296 14.946 17.089 12.884 17.383L8.632 17.979C8.085 18.057 7.579 17.677 7.501 17.131C7.423 16.584 7.803 16.078 8.349 16L12.511 15.417C13.447 15.283 14.259 14.611 14.451 13.685C14.747 12.26 13.661 11 12.287 11H4.5C2.291 11 0.5 12.791 0.5 15V20C0.5 22.209 2.291 24 4.5 24H8.762C11.567 24 14.242 22.822 16.136 20.754L23.838 12.345C24.786 11.283 24.7 9.639 23.649 8.681Z"
        fill="white"
      />
    </svg>
  );
};
export default TransferIcon;
