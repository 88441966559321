import React, { useEffect, useMemo, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Avatar,
  Divider,
  Stack,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import VerifiedIcon from "@mui/icons-material/Verified";
import TransactionsLists from "../../components/TransactionsLists";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/reducers/RootReducer";
import { useLocation } from "react-router-dom";
import { userProfileRequest } from "../../store/reducers/ProfileReducer";
import { dashboardRequest } from "../../store/reducers/DashboardReducer";
import LogoIcon from "../../assets/svg/Logo";
import { bankRequest } from "../../store/reducers/BankReducer";
import {
  getBankName,
  maskBankAccount,
  toFixedDecimal,
  zeroNumber,
} from "../../utils/Utils";
import { walletRequest } from "../../store/reducers/WalletReducer";
import DashboardBuyButton from "../../components/DashboardBuyButton/DashboardBuyButton";
import { AEGCoinIcon, RedeemIcon, SellIcon, TransferIcon } from "../../assets";
import ReceiveIcon from "../../assets/svg/ReceiveIcon";
import ItemSeparator from "../../components/ItemSeparator";
import ReceiveDialog from "../../components/ReceiveDialog";
import RedeemDialog from "../../components/RedeemDialog";
import { aegDetailRequest } from "../../store/reducers/AEGDetailsReducer";
import { AlertDataProps } from "../../types/Common";
import AlertComponent from "../../components/AlertComponent";
import { styles } from "./dashboard.styles";
import BuyAegDialog from "../../components/BuyAegDialog";
import SellDialog from "../../components/SellDialog";
import TransferDialog from "../../components/TransferDialog";
import TransactionDetails from "../../components/TransactionDetails";
import { COLOR } from "../../theme";
import ChartWidget from "../Chart/ChartWidget";

const DashboardPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { userProfileState, dashboardState, bankState } = useSelector(
    (state: RootState) => state
  );
  const [openRedeemPopup, setOpenRedeemPopup] = useState(false);
  const [openSellPopup, setOpenSellPopup] = useState(false);
  const [openBuyAEGPopup, setOpenBuyAEGPopup] = useState(false);
  const [openTransferPopup, setOpenTransferPopup] = useState(false);
  const [openReceivePopup, setOpenReceivePopup] = useState(false);
  const [showTransactionDetails, setShowTransactionDetails] = useState(-1);

  const [alertData, setAlertData] = useState<AlertDataProps>({
    message: "",
    type: "error",
  });

  useEffect(() => {
    dispatch(dashboardRequest());
    dispatch(userProfileRequest());
    dispatch(bankRequest());
    dispatch(walletRequest());
    dispatch(aegDetailRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  const updateAPIRequest = () => {
    dispatch(walletRequest());
    dispatch(dashboardRequest());
    dispatch(aegDetailRequest());
  };

  const primaryBank = useMemo(() => {
    if (bankState.result && bankState.result.length > 0) {
      const primaryBank = bankState?.result?.find(
        (element) => element.isPrimary === 1
      );
      return primaryBank;
    }
  }, [bankState.result]);

  const getTrendStyles = () => {
    if (dashboardState?.result?.trend === "up") {
      return { fontWeight: 400, color: "rgba(20, 203, 27, 1)" };
    } else if (dashboardState?.result?.trend === "down") {
      return { fontWeight: 500, color: "rgba(203, 31, 20, 1)" };
    } else {
      return { fontWeight: 400, color: "grey" };
    }
  };

  const getTrendIcon = () => {
    if (dashboardState?.result?.trend === "up") {
      return "../../static/svg/stroke-up.svg";
    } else if (dashboardState?.result?.trend === "down") {
      return "../../static/svg/stroke-down.svg";
    } else {
      return "../../static/svg/stroke-stable.svg";
    }
  };

  const renderContactCard = () => {
    return (
      <Card
        sx={{
          backgroundColor: COLOR.background,
          color: "#fff",
          mt: 3,
        }}
      >
        <CardContent sx={{ padding: "1em" }}>
          <Box sx={{ display: "flex", mt: 0, mb: 1 }}>
            <Avatar
              sx={{
                bgcolor: "#6a6a6a7d",
                mr: 2,
                padding: "3px",
                width: 32,
                height: 32,
              }}
            >
              <EmailIcon sx={{ fontSize: 20 }} />
            </Avatar>
            <Typography variant="body1" sx={{ textWrap: "wrap" }}>
              {userProfileState.result?.email}
            </Typography>
          </Box>
          <Divider sx={{ width: "100%", backgroundColor: "#444" }} />
          <Box sx={{ display: "flex", alignItems: "center", mt: 1, mb: -1.2 }}>
            <Avatar
              sx={{
                bgcolor: "#6a6a6a7d",
                mr: 2,
                padding: "3px",
                width: 32,
                height: 32,
              }}
            >
              <PhoneIcon sx={{ fontSize: 20 }} />
            </Avatar>
            <Typography variant="body1">
              {userProfileState.result?.phoneNumber
                ? userProfileState.result?.phoneNumber
                : "+XX XXXXXXXXXX"}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    );
  };

  const renderBankCard = () => {
    return (
      <Card
        sx={{ backgroundColor: COLOR.background, color: "#fff", padding: 0 }}
      >
        <CardContent sx={styles.activeBankName}>
          {primaryBank?.bankName ? (
            <>
              <Typography sx={styles.primaryBankAccountText}>
                Primary Bank Account
              </Typography>
              <Box sx={styles.flexCenter} mt={1}>
                <Box sx={styles.bankLogoContainer}>
                  <Box sx={styles.bankLogoGlass}>
                    <Typography sx={styles.bankLogoText}>
                      {getBankName(primaryBank?.bankName)}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Stack direction={"row"} sx={styles.inlineCenter} gap={4}>
                    <Typography sx={styles.activeBankName}>
                      {primaryBank?.bankName}
                    </Typography>
                    {primaryBank?.verificationStatus === "Verified" ? (
                      <VerifiedIcon
                        sx={{
                          color:
                            primaryBank?.status === "active"
                              ? "#14CB1B"
                              : "#5A5463",
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </Stack>

                  <Typography sx={styles.maskedBankAccountText}>
                    {maskBankAccount(primaryBank?.accountNumber)}
                    <Typography sx={{ color: "#14CB1B", pl: 1 }}>
                      {" "}
                      (Primary)
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </>
          ) : (
            <Stack direction={"column"}>
              <Typography variant="h6">Primary Bank Account</Typography>
              <Typography sx={styles.noDataFoundTextHeading} mt={2}>
                Sorry!
              </Typography>
              <Typography sx={styles.noDataFoundText}>
                No Bank Account Linked
              </Typography>
            </Stack>
          )}
        </CardContent>
      </Card>
    );
  };

  const renderActionButton = () => {
    return (
      <Grid item xs={12} md={2} m={0}>
        <Card sx={styles.actionContainer}>
          <CardContent sx={styles.actionContent}>
            <Box
              sx={styles.actionItem}
              onClick={() => {
                setOpenRedeemPopup(true);
              }}
            >
              <Box sx={styles.actionItemIconContainer}>
                <RedeemIcon />
              </Box>
              <Typography variant="body2" sx={{ ml: 2 }}>
                Redeem
              </Typography>
            </Box>
            <ItemSeparator />
            <Box
              sx={styles.actionItem}
              onClick={() => {
                setOpenSellPopup(true);
              }}
            >
              <Box sx={styles.actionItemIconContainer}>
                <SellIcon />
              </Box>
              <Typography variant="body2" sx={{ ml: 2 }}>
                Sell
              </Typography>
            </Box>
            <ItemSeparator />
            <Box
              sx={styles.actionItem}
              onClick={() => {
                setOpenTransferPopup(true);
              }}
            >
              <Box sx={styles.actionItemIconContainer}>
                <TransferIcon />
              </Box>
              <Typography variant="body2" sx={{ ml: 2 }}>
                Transfer
              </Typography>
            </Box>
            <ItemSeparator />

            <Box
              sx={styles.actionItem}
              onClick={() => {
                setOpenReceivePopup(true);
              }}
            >
              <Box sx={styles.actionItemIconContainer}>
                <ReceiveIcon />
              </Box>
              <Typography variant="body2" sx={{ ml: 2 }}>
                Receive
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={5}>
          <Card sx={styles.holdingCardBg} elevation={0}>
            <CardContent>
              <Box sx={styles.flexCenterSpaceBetween}>
                <Box sx={styles.flexCenter}>
                  <Avatar sx={{ backgroundColor: "#000000", mr: 1 }}>
                    <LogoIcon width={30} height={30} />
                  </Avatar>
                  <Typography sx={styles.aegHoldingText}>
                    AEG Balance
                  </Typography>
                  <Typography
                    sx={{ ...styles.trendingText, ...getTrendStyles() }}
                  >
                    <img
                      src={getTrendIcon()}
                      alt="stroke"
                      style={{ marginRight: "5px" }}
                    />
                    {toFixedDecimal(dashboardState?.result?.changePercentage)}%
                  </Typography>
                </Box>

                <DashboardBuyButton
                  title={"Buy AEG"}
                  onClick={() => {
                    setOpenBuyAEGPopup(true);
                  }}
                />
              </Box>
              <Stack direction={"row"} sx={{ alignItems: "baseline" }} mt={2}>
                <Typography sx={styles.aegHoldingAEDText} mr={0.5}>
                  AED
                </Typography>
                <Typography sx={styles.aegBalanceHoldingValue} mr={1.5}>
                  {toFixedDecimal(dashboardState?.result?.aegBalanceAed)}
                </Typography>
                <Typography sx={styles.aegBalanceText}>
                  {`( ${
                    dashboardState.result?.aegBalance
                      ? dashboardState.result?.aegBalance
                      : zeroNumber()
                  } AEG )`}
                </Typography>
              </Stack>
            </CardContent>
            <Box sx={styles.holdingAEDStrip}>
              <AEGCoinIcon width={36} height={36} />
              <Typography sx={{ fontSize: "1rem", fontWeight: 550 }} mx={1}>
                {`x 1 = AED ${dashboardState.result?.unitPrice}`}
              </Typography>
            </Box>
          </Card>
        </Grid>

        {/* Action Buttons */}
        {renderActionButton()}

        {/* Bank and Contact Card */}
        <Grid item xs={12} md={5}>
          {renderBankCard()}
          {renderContactCard()}
        </Grid>

        {/* Global Gold Index */}
        <Grid
          item
          xs={12}
          md={5}
          sx={{ borderRadius: "20px", height: "100%", position: "relative" }}
        >
          <ChartWidget height={345} width={"auto"} />
        </Grid>

        {/* Transaction List card */}
        <Grid item xs={12} md={7}>
          <TransactionsLists
            onClick={(transactionId: number) => {
              setShowTransactionDetails(transactionId);
            }}
          />
        </Grid>
      </Grid>

      <React.Fragment>
        <ReceiveDialog
          open={openReceivePopup}
          handleClose={() => setOpenReceivePopup(false)}
        />
        {/* RedeemPopup  Dialog */}
        <RedeemDialog
          open={openRedeemPopup}
          handleClose={() => setOpenRedeemPopup(false)}
          onSuccess={(tnxId: number) => {
            updateAPIRequest();
            setShowTransactionDetails(tnxId);
            setOpenRedeemPopup(false);
          }}
          onError={(error) => {
            setAlertData({
              title: "Redeem Error",
              message: error,
              type: "error",
            });
            setOpenRedeemPopup(false);
          }}
        />

        {/* BuyAEGPopup  Dialog */}
        <BuyAegDialog
          open={openBuyAEGPopup}
          handleClose={() => setOpenBuyAEGPopup(false)}
          onError={(error) => {
            setAlertData({
              title: "Redeem Error",
              message: error,
              type: "error",
            });
            setOpenBuyAEGPopup(false);
          }}
        />

        <SellDialog
          open={openSellPopup}
          handleClose={() => setOpenSellPopup(false)}
          onError={(error) => {
            setAlertData({
              title: "Sell Error",
              message: error,
              type: "error",
            });
            setOpenSellPopup(false);
          }}
          onSuccess={(trnxId: number) => {
            updateAPIRequest();
            setOpenSellPopup(false);
            setShowTransactionDetails(trnxId);
          }}
        />

        {openTransferPopup ? (
          <TransferDialog
            open={openTransferPopup}
            handleClose={() => setOpenTransferPopup(false)}
            onError={(error) => {
              setAlertData({
                title: "Sell Error",
                message: error,
                type: "error",
              });
              setOpenTransferPopup(false);
            }}
            onSuccess={(trnxId: number) => {
              updateAPIRequest();
              setOpenTransferPopup(false);
              setShowTransactionDetails(trnxId);
            }}
          />
        ) : (
          <></>
        )}

        {showTransactionDetails !== -1 ? (
          <TransactionDetails
            open={true}
            txnID={showTransactionDetails}
            onError={() => {
              setShowTransactionDetails(-1);
            }}
            handleClose={() => {
              setShowTransactionDetails(-1);
            }}
          />
        ) : (
          <></>
        )}
      </React.Fragment>
      <AlertComponent
        open={alertData.message !== "" ? true : false}
        onClose={() => setAlertData({ message: "", type: "error" })}
        message={alertData.message}
        type={alertData.type ? alertData.type : "error"}
      />
    </Box>
  );
};
export default DashboardPage;
