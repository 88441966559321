import React from "react";

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const CloseIcon = (props: Props) => {
  const { width = 16, height = 16, color = "#FFFFFF" } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 11 12" fill="none">
      <path
        d="M1 1.5L10 10.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M1 10.5L10 1.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
export default CloseIcon;
