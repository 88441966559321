import * as Yup from "yup";

export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .required("Mail address is required")
    .email("Invalid email address"),
  password: Yup.string()
    .matches(/^.{8,}$/, "password should have at least 8 characters")
    .required("Password is required"),
});

export const RegisterSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("First name is required")
    .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9\s\-\/.]+$/, "Please enter valid name")
    .max(40),
  lastName: Yup.string()
    .required("Last name is required")
    .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9\s\-\/.]+$/, "Please enter valid name")
    .max(40),
  email: Yup.string()
    .required("Mail address is required")
    .email("Invalid email address"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Password must contain at least one special character"
    )
    .required("Password is required"),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("password")], "Passwords must match"),
});

export const addBankSchema = Yup.object().shape({
  receipientName: Yup.string()
    .matches(
      /^[A-Za-z\s]+$/,
      "Receipent name only contain alphebets and spaces"
    )
    .required("Receipient name is required"),
  bankName: Yup.string()
    .min(3, "Bank name must be at least 3 charaters")
    .matches(
      /^[A-Za-z0-9 ]{3,50}$/,
      "Bank name only contain alphanumeric or spaces"
    )
    .required("Bank name is required"),
  bankAccountNumber: Yup.string()
    .min(5, "Bank account number must be at least 5 charaters")
    .matches(
      /^[A-Za-z0-9 ]{5,50}$/,
      "Bank account number only contain alphanumeric or spaces"
    )
    .required("Bank account number is required"),
  swiftCode: Yup.string()
    .matches(
      /^[a-zA-Z]{4}[a-zA-Z]{2}[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?$/,
      "Please enter valid swift code"
    )
    .required("Swift code is required"),
  iBanCode: Yup.string()
    .matches(/^[A-Z]{2}\d{2}[A-Z\d]{1,30}$/, "Please enter valid IBAN code")
    .required("IBAN code is required"),
  beneficiaryAddress: Yup.string()
    .min(5, "Beneficiary address must be at least 5 charaters")
    .required("Beneficiary address is required"),
});

export const ForgetPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required("E-Mail address is required")
    .email("Invalid email address"),
});

export const ContactUsFormSchema = Yup.object().shape({
  email: Yup.string()
    .required("Mail address is required")
    .email("Invalid email address"),
  description: Yup.string()
    .min(10, "description should have at least 10 characters")
    .required("description is required"),
});
