import { COLOR } from "../../theme";

type Props = {
  width?: string;
  height?: string;
  color?: string;
};
const AccountDeletionIcon = (props: Props) => {
  const { width = 20, height = 16 } = props;
  return (
    <svg fill="none" height={height} viewBox="0 0 24 24" width={width}>
      <path
        d="M17.5001 11.9998C20.5377 11.9998 23.0001 14.4622 23.0001 17.4998C23.0001 20.5373 20.5377 22.9998 17.5001 22.9998C14.4626 22.9998 12.0001 20.5373 12.0001 17.4998C12.0001 14.4622 14.4626 11.9998 17.5001 11.9998ZM12.0224 13.9991C11.3753 15.0095 11.0001 16.2108 11.0001 17.4998C11.0001 19.1439 11.6106 20.6455 12.6172 21.7903C11.815 21.9311 10.9421 22.0008 10.0001 22.0008C7.11062 22.0008 4.87181 21.3442 3.30894 20.0006C2.48032 19.2882 2.00366 18.2498 2.00366 17.157V16.2497C2.00366 15.0071 3.01102 13.9997 4.25366 13.9997L12.0224 13.9991ZM15.0932 14.9661L15.0239 15.0239L14.9661 15.0932C14.8479 15.2637 14.8479 15.4913 14.9661 15.6618L15.0239 15.731L16.7935 17.5005L15.0265 19.2673L14.9687 19.3365C14.8505 19.507 14.8505 19.7346 14.9687 19.9051L15.0265 19.9744L15.0958 20.0322C15.2663 20.1503 15.4939 20.1503 15.6644 20.0322L15.7336 19.9744L17.5005 18.2075L19.2695 19.9766L19.3388 20.0345C19.5093 20.1526 19.7369 20.1526 19.9074 20.0345L19.9766 19.9766L20.0345 19.9074C20.1526 19.7369 20.1526 19.5093 20.0345 19.3388L19.9766 19.2695L18.2075 17.5005L19.9794 15.7311L20.0372 15.6618C20.1554 15.4913 20.1554 15.2637 20.0372 15.0932L19.9794 15.024L19.9101 14.9661C19.7396 14.848 19.512 14.848 19.3415 14.9661L19.2723 15.024L17.5005 16.7935L15.731 15.0239L15.6618 14.9661C15.5156 14.8648 15.3275 14.8503 15.1694 14.9227L15.0932 14.9661ZM10.0001 2.00439C12.7615 2.00439 15.0001 4.24297 15.0001 7.00439C15.0001 9.76582 12.7615 12.0044 10.0001 12.0044C7.2387 12.0044 5.00012 9.76582 5.00012 7.00439C5.00012 4.24297 7.2387 2.00439 10.0001 2.00439Z"
        fill={COLOR.danger}
      />
    </svg>
  );
};
export default AccountDeletionIcon;
