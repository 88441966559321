import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const DropdownIcon = (props: Props) => {
  const { width = 12, height = 12 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9641 18C12.4245 20.6667 8.5755 20.6667 7.0359 18L0.540708 6.74999C-0.998892 4.08333 0.92561 0.749998 4.00481 0.749998L16.9952 0.749999C20.0744 0.749999 21.9989 4.08333 20.4593 6.75L13.9641 18Z"
        fill="#D9D9D9"
      />
    </svg>
  );
};
export default DropdownIcon;
