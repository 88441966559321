import React, { useState } from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  Box,
  Divider,
  Stack,
  Paper,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SupportAccordion from "../../components/SupportAccordion";
import { COLOR } from "../../theme";
import ContactUsForm from "../../components/ContactUsForm";
import AlertComponent from "../../components/AlertComponent";
import { AlertDataProps } from "../../types/Common";

const SupportPage = () => {
  const [openContactUs, setOpenContactUs] = useState(false);
  const [alertData, setAlertData] = useState<AlertDataProps>({
    message: "",
    type: "error",
  });

  return (
    <Box>
      <Grid container style={{ height: "calc(100vh - 104px)" }} spacing={2}>
        <Grid item xs={12} md={12}>
          <Paper sx={styles.container}>
            <Stack sx={{ width: "100%" }}>
              <Typography sx={styles.welcomeText} mb={1}>
                Hi, How can we help you?
              </Typography>
              <Typography sx={styles.welcomeText} mb={1}>
                Need help related to
              </Typography>
              <Divider sx={{ my: 1 }} />
            </Stack>

            <SupportAccordion />
          </Paper>
        </Grid>

        <Grid item xs={12} md={12}>
          <Card sx={styles.contactUsContainer}>
            <CardContent sx={styles.contactUsCardContent}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                onClick={() => setOpenContactUs(true)}
              >
                <img
                  src="../../static/svg/support.svg"
                  alt="stroke"
                  style={{ height: "3em", width: "3em" }}
                />
                <Typography sx={styles.contactUsText} mx={1}>
                  Contact Us
                </Typography>
                <ArrowForwardIosIcon fontSize="medium" />
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {openContactUs ? (
        <ContactUsForm
          open={true}
          onError={(error: string) => {
            setOpenContactUs(false);
            setAlertData({ message: error, type: "error" });
          }}
          onSuccess={(message: string) => {
            setOpenContactUs(false);
            setAlertData({ message: message, type: "success" });
          }}
          handleClose={() => setOpenContactUs(false)}
        />
      ) : (
        <></>
      )}

      <AlertComponent
        open={alertData.message !== "" ? true : false}
        onClose={() => setAlertData({ message: "", type: "error" })}
        message={alertData.message}
        type={alertData.type ? alertData.type : "error"}
      />
    </Box>
  );
};

export default SupportPage;

const styles = {
  zeroPadding: {
    padding: 0,
  },
  container: {
    backgroundColor: COLOR.background,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    color: "#fff",
    borderRadius: 2,
    alignItems: "flex-start",
    padding: "1.3em",
    height: "100%",
  },
  welcomeText: {
    fontSize: "1rem",
    fontWeight: 600,
    textAlign: { xs: "center", sm: "left" },
    marginLeft: "1.3em",
  },
  contactUsContainer: {
    backgroundColor: COLOR.background,
    color: "#fff",
    borderRadius: 2,
    alignItems: "center",
    padding: "0px",
  },
  contactUsCardContent: {
    padding: "1em",
  },
  contactUsText: {
    fontSize: "1.2rem",
    justifyContent: { xs: "center", sm: "flex-start" },
    fontWeight: 500,
    cursor: "pointer",
  },
};
