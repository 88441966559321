import React, { useState, useRef, useEffect } from "react";
import { Grid, Card, CardContent, Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import {
  ActivateAccountModelRequest,
  ActivateAccountResponse,
  ActivateAccountResponseData,
} from "../../types/ActivateAccountModel";
import { useNavigate } from "react-router-dom";
import { requestActivateAccount, requestActivationEmail } from "../../api/api";
import Loader from "../../components/Loader";
import { AxiosResponse } from "axios";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../store/reducers/AuthReducer";
import { setAuthToken } from "../../api/config";
import AlertComponent from "../../components/AlertComponent";
import { STORE_KEY } from "../../utils/AppConstants";
import { isNumber } from "../../utils/Validation";
import { aesEncrypt } from "../../utils/Encryption";

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  "&:hover": {
    textDecoration: "underline",
  },
}));

const RegistrationCodeVerification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [code1, setCode1] = useState("");
  const [code2, setCode2] = useState("");
  const [code3, setCode3] = useState("");
  const [code4, setCode4] = useState("");
  const input1Refs = useRef<HTMLInputElement | null>(null);
  const input2Refs = useRef<HTMLInputElement | null>(null);
  const input3Refs = useRef<HTMLInputElement | null>(null);
  const input4Refs = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (
      code1 &&
      code1.length > 0 &&
      code2 &&
      code2.length > 0 &&
      code3 &&
      code3.length > 0 &&
      code4 &&
      code4.length > 0
    ) {
      handleVerify();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code4]);

  const saveToAuthState = (loginData: ActivateAccountResponseData) => {
    dispatch({
      type: loginSuccess.type,
      payload: {
        token: loginData.token,
        result: loginData,
      },
    });
    setAuthToken(loginData.token);
  };

  const resetForm = () => {
    setCode1("");
    setCode2("");
    setCode3("");
    setCode4("");
    input1Refs?.current?.focus();
  };

  const handleVerify = async () => {
    if (code1 && code2 && code3 && code4) {
      const otp = code1 + code2 + code3 + code4;
      const userData = localStorage.getItem(STORE_KEY.TEMP_USER_DATA);
      if (userData) {
        const { userId } = JSON.parse(userData);
        setLoading(true);
        const body: ActivateAccountModelRequest = {
          user_id: userId,
          otp: otp,
        };
        const encryptText = aesEncrypt(JSON.stringify(body));
        const encryptBody = {
          dataCipher: encryptText,
        };
        const response = await requestActivateAccount(encryptBody);
        if (response.responseCode === 130101) {
          localStorage.removeItem(STORE_KEY.TEMP_USER_DATA);
          localStorage.setItem(
            STORE_KEY.SESSION_DATA,
            JSON.stringify(response.data)
          );
          saveToAuthState(response.data);
          resetForm();
          setLoading(false);
          navigate("/auth/registration-confirmation");
        } else {
          setAlertMessage(
            response?.data?.message
              ? response?.data?.message
              : response?.message
          );
          setLoading(false);
        }
      } else {
        setLoading(false);
        navigate("/auth/registration");
      }
    }
  };

  const resendOTP = async () => {
    const userData = localStorage.getItem(STORE_KEY.TEMP_USER_DATA);
    if (userData) {
      const { email } = JSON.parse(userData);
      const body = {
        email: email,
      };
      const response = await requestActivationEmail(body);
      if (response) {
        if (response.status === 200) {
          setAlertMessage(response.data.message);
        } else {
          setAlertMessage(response.data.message);
        }
      }
    } else {
      navigate("/auth/registration");
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedText = event.clipboardData.getData("text");
    console.log("Pasted text:", pastedText);
    if (pastedText.length === 4 && isNumber(pastedText)) {
      setCode1(pastedText.slice(0, 1));
      setCode2(pastedText.slice(1, 2));
      setCode3(pastedText.slice(2, 3));
      setCode4(pastedText.slice(3, 4));
    }
  };

  return (
    <Card
      sx={{
        backgroundColor: "rgba(10, 10, 10, 0.9)",
        border: "1px solid rgba(98, 77, 0, 1)",
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          mb={2}
        >
          <Typography variant="h4" gutterBottom textAlign={"center"} mb={4}>
            Two Step Verification
          </Typography>
          <Typography gutterBottom textAlign={"center"} mb={4} fontSize={17}>
            Enter Your Two Step Verification Code
          </Typography>

          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <TextField
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                inputRef={input1Refs}
                onPaste={handlePaste}
                onChange={(e) => {
                  if (e.target.value && e.target.value.length > 0) {
                    input2Refs?.current?.focus();
                    setCode1(e.target.value);
                  }
                }}
                onKeyDown={(event) => {
                  if (event.key === "Backspace") {
                    if (code1 && code1.length > 0) {
                      setCode1("");
                    }
                  }
                }}
                sx={styles.inputStyle}
                value={code1}
              />
            </Grid>
            <Grid item>
              <TextField
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                inputRef={input2Refs}
                onChange={(e) => {
                  if (e.target.value && e.target.value.length > 0) {
                    input3Refs?.current?.focus();
                    setCode2(e.target.value);
                  }
                }}
                onKeyDown={(event) => {
                  if (event.key === "Backspace") {
                    if (code2 && code2.length > 0) {
                      setCode2("");
                    } else {
                      input1Refs?.current?.focus();
                    }
                  }
                }}
                sx={styles.inputStyle}
                value={code2}
              />
            </Grid>
            <Grid item>
              <TextField
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                inputRef={input3Refs}
                onChange={(e) => {
                  if (e.target.value && e.target.value.length > 0) {
                    input4Refs?.current?.focus();
                    setCode3(e.target.value);
                  }
                }}
                onKeyDown={(event) => {
                  console.log(" handleChange event: ", event.key);
                  if (event.key === "Backspace") {
                    if (code3 && code3.length > 0) {
                      setCode3("");
                    } else {
                      input2Refs?.current?.focus();
                    }
                  }
                }}
                sx={styles.inputStyle}
                value={code3}
              />
            </Grid>
            <Grid item>
              <TextField
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                inputRef={input4Refs}
                onChange={(e) => {
                  if (e.target.value && e.target.value.length > 0) {
                    setCode4(e.target.value);
                  }
                }}
                onKeyDown={(event) => {
                  if (event.key === "Backspace") {
                    if (code4 && code4.length > 0) {
                      setCode4("");
                    } else {
                      input3Refs?.current?.focus();
                    }
                  }
                }}
                sx={styles.inputStyle}
                value={code4}
              />
            </Grid>
          </Grid>

          <Typography
            gutterBottom
            textAlign={"center"}
            mb={2}
            mt={6}
            fontSize={15}
          >
            Don't Received the Code
          </Typography>
          <Button
            sx={{ alignSelf: "center", mb: 10 }}
            color={"primary"}
            variant="text"
            onClick={() => resendOTP()}
          >
            Resend the OTP
          </Button>

          {loading ? (
            <Loader />
          ) : (
            <PrimaryButton title={"Verify"} onClick={handleVerify} />
          )}

          <Typography variant="body2" mt={2} textAlign={"center"}>
            Already have an Account?{" "}
            <StyledLink fontWeight={"bold"} href="login">
              Sign in
            </StyledLink>
          </Typography>
        </Box>
      </CardContent>
      <AlertComponent
        open={alertMessage !== "" ? true : false}
        onClose={() => setAlertMessage("")}
        message={alertMessage}
        type={"error"}
      />
    </Card>
  );
};
export default RegistrationCodeVerification;
const styles = {
  inputStyle: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "yellow",
      },
      "&.Mui-focused fieldset": {
        borderColor: "yellow",
      },
      "& input": {
        textAlign: "center",
        fontSize: "1.5rem",
        color: "white",
        height: "18px",
      },
    },
    width: "50px",
    height: "50px",
  },
};
