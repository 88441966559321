import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const PhoneIcon = (props: Props) => {
  const { width = 18, height = 18 } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 21 21" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.3125 3.9375C1.3125 2.48775 2.48775 1.3125 3.9375 1.3125H5.13767C5.8905 1.3125 6.54673 1.82486 6.72931 2.55521L7.69688 6.4255C7.85698 7.06589 7.6177 7.73985 7.08962 8.13591L5.9577 8.98485C5.84016 9.073 5.81461 9.20209 5.84763 9.29222C6.8412 12.0046 8.99545 14.1588 11.7078 15.1524C11.7979 15.1854 11.927 15.1598 12.0152 15.0423L12.8641 13.9104C13.2601 13.3823 13.9341 13.143 14.5745 13.3031L18.4448 14.2707C19.1751 14.4533 19.6875 15.1095 19.6875 15.8623V17.0625C19.6875 18.5122 18.5122 19.6875 17.0625 19.6875H15.0938C7.48258 19.6875 1.3125 13.5174 1.3125 5.90625V3.9375Z"
        fill="white"
      />
    </svg>
  );
};
export default PhoneIcon;
