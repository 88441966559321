import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import TransactionComponent from "./TransactionComponent";
import { CloseIcon } from "../assets";
import { COLOR } from "../theme";
import { useState } from "react";
import PrimaryButton from "./PrimaryButton/PrimaryButton";
import { requestAccountDeletion } from "../api/api";

type Props = {
  open: boolean;
  handleClose: () => void;
  onError: (error: string) => void;
  onSuccess: (message: string) => void;
};

const AccountDeletionDialog = (props: Props) => {
  const { open, handleClose, onError, onSuccess } = props;
  const [confirmText, setConfirmText] = useState<string | null>(null);
  const [confirmTextError, setConfirmTextError] = useState<string | null>(null);

  const handleAccountDeletion = async () => {
    if (confirmText === "CONFIRM") {
      const response = await requestAccountDeletion();
      console.log("<== response ==>", response);
      if (response) {
        if (response.status === 200) {
          onSuccess(response.data.message);
        } else {
          onError(response.data.message);
        }
      } else {
        onError("Something went wrong!!!");
      }
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={TransactionComponent}
      keepMounted
      PaperProps={{
        style: {
          backgroundColor: "rgba(12, 12, 12, 1)",
          border: "0.5px solid rgba(255, 255, 255, 0.4)",
        },
      }}
    >
      <DialogContent sx={styles.container}>
        <Box sx={styles.headerStyle} mb={0.5}>
          <Typography sx={styles.dialogTitle}>
            Request Account Deletion
          </Typography>
          <IconButton sx={styles.iconBackground} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box sx={styles.content}>
          <Typography sx={styles.descText}>
            <span style={styles.boldText}>Important Notice:</span> Account
            Deletion Request
          </Typography>
          <Typography sx={styles.descText}>
            By submitting this request, please be aware that your account will
            be permanently deleted within{" "}
            <span style={styles.boldText}>7 days</span>. This action is
            irreversible, and once your account is deleted, all your personal
            details and data will be permanently removed from our databases.
          </Typography>
          <Typography sx={styles.descText}>
            <span style={styles.boldText}>
              Before proceeding, please ensure that you have moved all your
              wallet balance to another wallet
            </span>
            . If you do not transfer your funds before the deletion, all your
            balance will be lost once your account is deleted.
          </Typography>
          <Typography sx={styles.descText}>
            If you change your mind about this request, you must contact us at{" "}
            <span style={styles.mailLink}>info@aegt.io</span> within 24 hours of
            submitting your deletion request. After this period, we will proceed
            with the deletion, and recovery of your account will not be
            possible.
          </Typography>
          <Typography sx={styles.descText}>
            Thank you for your understanding.
          </Typography>

          <TextField
            sx={styles.inputField}
            placeholder="Type 'CONFIRM' to proceed"
            size="small"
            onChange={(e) => {
              if (e.target.value && e.target.value.length > 0) {
                setConfirmTextError(null);
              }
              setConfirmText(e.target.value);
            }}
            value={confirmText}
            error={confirmTextError !== null ? true : false}
            helperText={confirmTextError !== null ? confirmTextError : ""}
          />
          <PrimaryButton
            isDisabled={confirmText === "CONFIRM" ? false : true}
            title="Confirm Account Deletion"
            onClick={handleAccountDeletion}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default AccountDeletionDialog;

const styles = {
  container: {
    backgroundColor: "rgba(12, 12, 12, 1)",
  },
  headerStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: "1.2rem",
    color: "#FFFFFF",
  },
  tableColHeader: {
    fontWeight: 350,
    color: "#ffffff",
  },
  tableColValue: {
    fontWeight: 600,
    color: "#d9d9d9",
  },
  iconBackground: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "rgba(0, 0, 0, 1)",
    width: "1em",
    height: "1em",
    borderRadius: "1em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    marginLeft: "1em",
  },
  descText: {
    fontWeight: 450,
    fontSize: "0.8rem",
    color: "#FFFFFF",
    marginBottom: "0.5em",
  },
  boldText: {
    fontWeight: 800,
  },
  mailLink: {
    color: COLOR.primary,
  },
  content: {
    padding: "1em 0em",
  },
  inputField: {
    width: "100%",
    marginTop: "2em",
    marginBottom: "1em",
  },
};
