import React from "react";
type Props = {
  width?: string;
  height?: string;
  color?: string;
};
const BookmarkIcon = (props: Props) => {
  const { width = "0.9em", height = "0.9em", color = "#FFFFFF" } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 32 40" fill="none">
      <path
        d="M8 34.9998L0 39.9998V3.02742C0 0.999999 1.33333 -0.000167621 5.33333 -0.000167621H26.6667C30.6667 -0.000167621 32 0.999999 32 3.02742V39.9998L24 34.9998L16 39.9998L8 34.9998Z"
        fill={color}
      />
    </svg>
  );
};
export default BookmarkIcon;
