import { format, parse } from "date-fns";

export const getTransactionListDateTimeFormat = (date: string | undefined) => {
  if (date) {
    const formattedDate = format(new Date(date), "dd MMM yyyy hh:mm:ss a");
    return formattedDate;
  } else {
    return "";
  }
};

export const getCurrentYear = () => {
  const formattedDate = format(new Date(), "yyyy");
  return formattedDate;
};

export const getTransferDateTimeFormat = (date: string | undefined) => {
  if (date) {
    const formattedDate = format(new Date(date), "dd/MM/yyyy hh:mm:ss a");
    return formattedDate;
  } else {
    return "";
  }
};

export const dmyToYmd = (dateString: string) => {
  const parsedDate = parse(dateString, "dd/MM/yyyy", new Date());
  const formattedDate = format(parsedDate, "yyyy-MM-dd");
  return formattedDate;
};

export const YMDToDMY = (dateString: string | undefined) => {
  try {
    if (dateString) {
      const parsedDate = parse(dateString, "yyyy-MM-dd", new Date());
      const formattedDate = format(parsedDate, "dd/MM/yyyy");
      return formattedDate;
    } else {
      return "";
    }
  } catch (error) {
    return "";
  }
};

export const getDateOfBirthFormat = (date: string | undefined) => {
  if (date) {
    const formattedDate = format(new Date(date), "dd/MM/yyyy");
    return formattedDate;
  } else {
    return "";
  }
};

export const toDMYDate = (date: Date) => {
  if (date) {
    const formattedDate = format(date, "dd/MM/yyyy");
    return formattedDate;
  } else {
    return "";
  }
};

export const toYMDDate = (date: Date) => {
  if (date) {
    const formattedDate = format(date, "yyyy-MM-dd");
    return formattedDate;
  } else {
    return "";
  }
};

export const getCurrentDateTimeFormat = () => {
  const formattedDate = format(new Date(), "dd MMM,yyyy | hh:mm:ss");
  return formattedDate;
};

export const getTimeInMilliseconds = () => {
  const date = new Date();
  return date.getTime();
};

export const getPickerDateFormat = (date: Date) => {
  const formattedDate = format(date, "dd MMM,yyyy");
  return formattedDate;
};

export const getPickerTimeFormat = (date: Date) => {
  const formattedDate = format(date, "HH:mm:ss");
  return formattedDate;
};

export const serverDateFormat = (date: Date) => {
  if (date) {
    const formattedDate = format(date, "yyyy-MM-dd");
    return formattedDate;
  } else {
    return "";
  }
};
