import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const LogoIcon = (props: Props) => {
  const { width = 43, height = 19 } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 43 19" fill="none">
      <path
        d="M42.5152 18.4919L34.876 9.35107L12.0239 9.41637L17.2589 15.3809C18.9945 17.3582 21.4979 18.4919 24.1289 18.4919H42.5152Z"
        fill="url(#paint0_linear_239_7118)"
      />
      <path
        d="M12.0239 9.35107H34.876L13.7868 11.3098L12.0239 9.35107Z"
        fill="#F2B80E"
        fillOpacity="0.5"
      />
      <path
        d="M12.0239 9.41637L34.876 9.35107L13.7215 11.3751L12.0239 9.41637Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M13.7215 11.3098L34.876 9.35107L15.4191 13.3339L13.7215 11.3098Z"
        fill="black"
        fillOpacity="0.1"
      />
      <path
        d="M23.2696 0.667297H0.858887L5.29768 6.11485C7.03353 8.24532 9.6358 9.48168 12.3839 9.48168L34.8759 9.3511L30.3047 3.97171C28.5681 1.87846 25.9894 0.667297 23.2696 0.667297Z"
        fill="url(#paint1_linear_239_7118)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_239_7118"
          x1="40.4258"
          y1="17.0555"
          x2="13.2645"
          y2="10.3305"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAFF00" />
          <stop offset="1" stopColor="#FBB115" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_239_7118"
          x1="27.1715"
          y1="1.51609"
          x2="9.99973"
          y2="7.7841"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1B20F" />
          <stop offset="1" stopColor="#FFF600" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default LogoIcon;
