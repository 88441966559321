import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const ReceiveIcon = (props: Props) => {
  const { width = 43, height = 19 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.0085 8.30783C8.55233 7.85167 8.55233 7.11433 9.0085 6.65817C9.46467 6.202 10.202 6.202 10.6582 6.65817L13.3333 9.33333V1.16667C13.3333 0.5215 13.8548 0 14.5 0C15.1452 0 15.6667 0.5215 15.6667 1.16667V9.33333L18.3418 6.65817C18.798 6.202 19.5353 6.202 19.9915 6.65817C20.4477 7.11433 20.4477 7.85167 19.9915 8.30783L16.1497 12.1497C15.6982 12.6012 15.1043 12.8287 14.5105 12.831L14.5 12.8333L14.4895 12.831C13.8957 12.8287 13.3018 12.6012 12.8503 12.1497L9.0085 8.30783ZM25 14H21.5C20.2132 14 19.1667 15.0465 19.1667 16.3333C19.1667 17.6202 18.1202 18.6667 16.8333 18.6667H12.1667C10.8798 18.6667 9.83333 17.6202 9.83333 16.3333C9.83333 15.0465 8.78683 14 7.5 14H4C2.07033 14 0.5 15.5703 0.5 17.5V22.1667C0.5 25.3832 3.11683 28 6.33333 28H22.6667C25.8832 28 28.5 25.3832 28.5 22.1667V17.5C28.5 15.5703 26.9297 14 25 14Z"
        fill="white"
      />
    </svg>
  );
};
export default ReceiveIcon;
