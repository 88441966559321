import Close from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Slide,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { useEffect, useRef, useState } from "react";
import PrimaryButton from "./PrimaryButton/PrimaryButton";
import { requestVerifyTwoFa } from "../api/api";
import { VerifyTwoFARequest } from "../types/TwoStepVerificationModel";
import Loader from "./Loader";
import AlertComponent from "./AlertComponent";
type Props = {
  open: boolean;
  title?: string;
  handleClose?: () => void;
  onTwoFASuccess: (token?: string) => void;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const VerifyTwoFAModel = (props: Props) => {
  const {
    open,
    handleClose,
    onTwoFASuccess,
    title = "Two Step Verification",
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [code1, setCode1] = useState("");
  const [code2, setCode2] = useState("");
  const [code3, setCode3] = useState("");
  const [code4, setCode4] = useState("");
  const [code5, setCode5] = useState("");
  const [code6, setCode6] = useState("");
  const input1Refs = useRef<HTMLInputElement | null>(null);
  const input2Refs = useRef<HTMLInputElement | null>(null);
  const input3Refs = useRef<HTMLInputElement | null>(null);
  const input4Refs = useRef<HTMLInputElement | null>(null);
  const input5Refs = useRef<HTMLInputElement | null>(null);
  const input6Refs = useRef<HTMLInputElement | null>(null);

  const resetCode = () => {
    setCode1("");
    setCode2("");
    setCode3("");
    setCode4("");
    setCode5("");
    setCode6("");
    input1Refs?.current?.focus();
  };

  useEffect(() => {
    if (code1 && code2 && code3 && code4 && code5 && code6) {
      verifyTwoFA();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code6]);

  const verifyTwoFA = async () => {
    if (code1 && code2 && code3 && code4 && code5 && code6) {
      setIsLoading(true);
      const otp = code1 + code2 + code3 + code4 + code5 + code6;
      const body: VerifyTwoFARequest = {
        token: otp,
      };
      const response = await requestVerifyTwoFa(body);

      if (response) {
        if (response.status === 200) {
          setIsLoading(false);
          onTwoFASuccess(otp);
        } else if (response.status === 401) {
          setIsLoading(false);
          resetCode();
          setAlertMessage(response?.data?.message);
        } else {
          setIsLoading(false);
          if (response?.data?.message) {
            setAlertMessage(response?.data?.message);
          }
        }
      } else {
        setIsLoading(false);
      }
    }
  };

  return (
    <Dialog
      open={open}
      keepMounted
      TransitionComponent={Transition}
      onClose={() => null}
      aria-describedby="dialog-2fa-description"
    >
      <DialogContent sx={{ backgroundColor: "rgba(12,12,12,0.9)" }}>
        {isLoading ? (
          <Box sx={styles.loadingStyleContainer}>
            <Typography sx={styles.loadingTitle} mb={2}>
              Please wait
            </Typography>
            <Typography sx={styles.loadingDesc} mb={5}>
              Waiting for Confirmation
            </Typography>
            <Loader />
          </Box>
        ) : (
          <>
            <Box>
              <Box sx={styles.headerStyle}>
                <Box />
                <Typography sx={styles.titleText}>{title}</Typography>
                <IconButton sx={styles.iconBackground} onClick={handleClose}>
                  <Close sx={styles.closeIconSize} />
                </IconButton>
              </Box>

              <Divider />
              <Box sx={styles.contentArea}>
                <Typography sx={styles.titleText}>
                  Two Step Verification
                </Typography>
                <Typography sx={styles.subTitleText} mb={3}>
                  Enter your two step verification code
                </Typography>
                <Stack sx={{ mb: 10 }} direction={"row"} gap={2}>
                  <TextField
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    inputRef={input1Refs}
                    onChange={(e) => {
                      if (e.target.value && e.target.value.length > 0) {
                        input2Refs?.current?.focus();
                        setCode1(e.target.value);
                      }
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Backspace") {
                        if (code1 && code1.length > 0) {
                          setCode1("");
                        }
                      }
                    }}
                    sx={styles.inputStyle}
                    value={code1}
                  />
                  <TextField
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    inputRef={input2Refs}
                    onChange={(e) => {
                      if (e.target.value && e.target.value.length > 0) {
                        input3Refs?.current?.focus();
                        setCode2(e.target.value);
                      }
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Backspace") {
                        if (code2 && code2.length > 0) {
                          setCode2("");
                        } else {
                          input1Refs?.current?.focus();
                        }
                      }
                    }}
                    sx={styles.inputStyle}
                    value={code2}
                  />
                  <TextField
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    inputRef={input3Refs}
                    onChange={(e) => {
                      if (e.target.value && e.target.value.length > 0) {
                        input4Refs?.current?.focus();
                        setCode3(e.target.value);
                      }
                    }}
                    onKeyDown={(event) => {
                      console.log(" handleChange event: ", event.key);
                      if (event.key === "Backspace") {
                        if (code3 && code3.length > 0) {
                          setCode3("");
                        } else {
                          input2Refs?.current?.focus();
                        }
                      }
                    }}
                    sx={styles.inputStyle}
                    value={code3}
                  />
                  <TextField
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    inputRef={input4Refs}
                    onChange={(e) => {
                      if (e.target.value && e.target.value.length > 0) {
                        input5Refs?.current?.focus();
                        setCode4(e.target.value);
                      }
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Backspace") {
                        if (code4 && code4.length > 0) {
                          setCode4("");
                        } else {
                          input3Refs?.current?.focus();
                        }
                      }
                    }}
                    sx={styles.inputStyle}
                    value={code4}
                  />
                  <TextField
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    inputRef={input5Refs}
                    onChange={(e) => {
                      if (e.target.value && e.target.value.length > 0) {
                        input6Refs?.current?.focus();
                        setCode5(e.target.value);
                      }
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Backspace") {
                        if (code5 && code5.length > 0) {
                          setCode5("");
                        } else {
                          input4Refs?.current?.focus();
                        }
                      }
                    }}
                    sx={styles.inputStyle}
                    value={code5}
                  />
                  <TextField
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    inputRef={input6Refs}
                    onChange={(e) => {
                      if (e.target.value && e.target.value.length > 0) {
                        setCode6(e.target.value);
                      }
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Backspace") {
                        if (code6 && code6.length > 0) {
                          setCode6("");
                        } else {
                          input5Refs?.current?.focus();
                        }
                      }
                    }}
                    sx={styles.inputStyle}
                    value={code6}
                  />
                </Stack>

                <PrimaryButton
                  title={"Verify"}
                  onClick={() => {
                    verifyTwoFA();
                  }}
                />
              </Box>
            </Box>
          </>
        )}
        <AlertComponent
          open={alertMessage !== "" ? true : false}
          onClose={() => setAlertMessage("")}
          message={alertMessage}
          type={"error"}
        />
      </DialogContent>
    </Dialog>
  );
};
export default VerifyTwoFAModel;

const styles = {
  titleText: {
    fontSize: "1rem",
    fontWeight: 500,
  },
  loadingTitle: {
    fontSize: "1.2rem",
    fontWeight: 700,
  },
  loadingDesc: {
    fontSize: "1.2rem",
    fontWeight: 300,
  },
  closeIconSize: {
    height: 15,
    width: 15,
  },
  loadingStyleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "40px 10px",
  },
  subTitleText: {
    fontSize: "0.8rem",
    fontWeight: 300,
  },
  headerStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  iconBackground: {
    backgroundColor: "#000000",
  },
  contentArea: {
    padding: "20px 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  inputStyle: {
    "& .MuiOutlinedInput-root": {
      width: "45px", // Set width and height to ensure square aspect ratio
      minHeight: "45px",
      padding: 0, // Remove default padding
      display: "flex",
      alignItems: "center",
      "& .MuiInputBase-input": {
        textAlign: "center", // Center the input text
        height: "45px",
        padding: 0,
      },
    },
  },
};
