import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import TransactionComponent from "./TransactionComponent";
import { CloseIcon, DropdownIcon, VerifyIcon } from "../assets";
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers/RootReducer";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import PrimaryButton from "./PrimaryButton/PrimaryButton";
import { TransferTokenModel } from "../types/TransferTokenModel";
import {
  isEmpty,
  isLessThenOrEqual,
  isNumber,
  isValidAmount,
  isValidWalletAddress,
} from "../utils/Validation";
import VerifyTwoFAContent from "./VerifyTwoFAContent";
import DialogLoadingView from "./DialogLoadingView";
import { requestTransferFees, requestTransferToken } from "../api/api";
import { addBigNumber, trimAddress, zeroNumber } from "../utils/Utils";
import { COLOR } from "../theme";

type Props = {
  open: boolean;
  selectedCryptoCoin?: number;
  handleClose: () => void;
  onError: (error: string) => void;
  onSuccess: (trnxId: number) => void;
};

interface Option {
  value: number;
  label: string;
  icon: string;
}

const enum ViewState {
  TRANSFER_FORM,
  TWOFA,
  LOADING,
  CONFIRMATION,
}

const TransferDialog = (props: Props) => {
  const { open, handleClose, onError, onSuccess, selectedCryptoCoin } = props;
  const { userProfileState, walletState } = useSelector(
    (state: RootState) => state
  );
  const [viewState, setViewState] = useState<ViewState>(
    ViewState.TRANSFER_FORM
  );
  const [selectedCoin, setSelectedCoin] = useState<number>(0);
  const [dropdownOptions, setDropdownOptions] = useState<Option[]>([]);
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState("");
  const [transferAmount, setTransferAmount] = useState("");
  const [transferAmountError, setTransferAmountError] = useState("");
  const [transactionId, setTransactionId] = useState<number>(0);
  const [transactionHash, setTransactionHash] = useState("");
  const [transferFees, setTransferFees] = useState<number | null>(null);
  const [transferFeesError, setTransferFeesError] = useState("");
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (walletState.result?.wallets && walletState.result?.wallets.length > 0) {
      let walletArray: Option[] = [];
      walletState.result.wallets.forEach((wallet) => {
        const obj: Option = {
          value: wallet.cryptoId,
          label: wallet.cryptoName,
          icon: wallet.cryptoIconUrl,
        };
        walletArray.push(obj);
      });
      setDropdownOptions(walletArray);
      setSelectedCoin(walletState?.result?.wallets[0]?.cryptoId);
    }
  }, [walletState?.result?.wallets, open]);

  useEffect(() => {
    if (selectedCryptoCoin) {
      setSelectedCoin(selectedCryptoCoin);
    }
  }, [selectedCryptoCoin]);

  useEffect(() => {
    if (
      !isEmpty(transferAmount) &&
      isNumber(transferAmount) &&
      isLessThenOrEqual(transferAmount, coinBalance?.balance) &&
      selectedCoin
    ) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        getTransferFees(transferAmount);
      }, 1200);
    } else {
      setTransferFees(null);
      console.log("Empty transfer fees");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferAmount]);

  const getTransferFees = async (transferAmount: string) => {
    const response = await requestTransferFees({
      amount: transferAmount,
      cryptoId: selectedCoin.toString(),
    });
    if (response) {
      if (response.status === 200) {
        setTransferFees(response.data.data.transferFees);
        setTransferFeesError("");
      } else {
        console.log("Error: ", response.data);
      }
    }
  };

  const renderValue = useCallback(() => {
    if (selectedCoin === 0) {
      return (
        <Stack
          sx={{ alignItems: "center", padding: "0.3em" }}
          direction={"row"}
          gap={1}
        >
          <img
            src={walletState.result?.wallets[0].cryptoIconUrl}
            alt={walletState.result?.wallets[0]?.cryptoName}
            style={styles.dropdownIconStyle}
          />
          <Typography>{walletState.result?.wallets[0]?.cryptoName}</Typography>
        </Stack>
      );
    }
    const selectedItem = walletState?.result?.wallets.find(
      (option) => option.cryptoId === selectedCoin
    );
    return (
      <Stack
        sx={{ alignItems: "center", padding: "0.3em" }}
        direction={"row"}
        gap={1}
      >
        <img
          src={selectedItem?.cryptoIconUrl}
          alt={selectedItem?.cryptoName}
          style={styles.dropdownIconStyle}
        />
        <Typography>{selectedItem?.cryptoName}</Typography>
      </Stack>
    );
  }, [selectedCoin, walletState.result?.wallets]);

  const grandTotal = useMemo(() => {
    if (transferFees) {
      return addBigNumber(transferFees, transferAmount, 8);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferFees]);

  const transferToken = async (token: string) => {
    try {
      setViewState(ViewState.LOADING);
      const body: TransferTokenModel = {
        receiver_address: address,
        amount: transferAmount,
        crypto_id: selectedCoin.toString(),
        token: token,
      };
      const response = await requestTransferToken(body);
      if (response) {
        if (response.status === 200) {
          setTransactionId(response.data.data.responseData.transactionId);
          setTransactionHash(response.data.data.responseData.transactionHash);
          setViewState(ViewState.CONFIRMATION);
        } else {
          resetFields();
          onError(response.data.message);
        }
      }
    } catch (error: any) {
      resetFields();
      onError(error.message);
    }
  };

  const handleTransfer = () => {
    if (isEmpty(transferAmount) || !isNumber(transferAmount)) {
      setTransferAmountError("Please enter valid number");
      return;
    } else if (!isLessThenOrEqual(transferAmount, coinBalance?.balance)) {
      setTransferAmountError(
        "Transfer amount should be less then or equal to available balance"
      );
      return;
    } else if (isEmpty(address) || !isValidWalletAddress(address)) {
      setAddressError("Please enter valid receivers address");
      return;
    } else if (transferFees && transferFees >= 0) {
      if (userProfileState.result?.twoFactorEnabled === 1) {
        setViewState(ViewState.TWOFA);
      } else {
        transferToken("000000");
      }
    } else {
      setTransferFeesError("Invalid transaction fees");
      return;
    }
  };

  const coinBalance = useMemo(() => {
    if (selectedCoin !== 0) {
      const walletData = walletState?.result?.wallets.find(
        (element) => element.cryptoId === selectedCoin
      );
      return walletData;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCoin]);

  useEffect(() => {
    if (transactionId) {
      setTimeout(() => {
        onSuccess(transactionId);
      }, 1500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionId]);

  const resetFields = () => {
    setViewState(ViewState.TRANSFER_FORM);
    setTransferAmount("");
    setAddress("");
    setTransactionHash("");
    setTransactionId(0);
    setAddressError("");
    setTransferFees(null);
    setTransferFeesError("");
    setTransferAmountError("");
  };

  const handleDialogClose = () => {
    resetFields();
    handleClose();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={TransactionComponent}
      keepMounted
      PaperProps={{
        style: {
          backgroundColor: "rgba(12, 12, 12, 1)",
          border: "0.5px solid rgba(255, 255, 255, 0.4)",
        },
      }}
      onClose={handleDialogClose}
      aria-describedby="dialog-profile-description"
    >
      <DialogTitle>
        <Stack direction={"row"} sx={styles.inlineSpaceBetween}>
          <Typography sx={styles.titleText}>Transfer Currency</Typography>
          <IconButton sx={styles.iconBackground} onClick={handleDialogClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent sx={styles.container}>
        {ViewState.TRANSFER_FORM === viewState ? (
          <Stack direction={"column"}>
            <Box sx={styles.dropdownRow}>
              <Typography sx={styles.dropDownLabel} mr={2}>
                Select Coin
              </Typography>
              <Select
                value={selectedCoin}
                displayEmpty
                IconComponent={() => <DropdownIcon />}
                sx={{
                  width: "18em",
                  backgroundColor: "#000000",
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  paddingRight: "1em",
                }}
                onChange={(event: any) => {
                  setSelectedCoin(event.target.value);
                  setAddress("");
                  setTransferAmount("");
                  setTransferFees(null);
                  setTransferFeesError("");
                  setTransferAmountError("");
                  setAddressError("");
                }}
                renderValue={renderValue}
              >
                {dropdownOptions.map((element) => {
                  return (
                    <MenuItem
                      value={element.value}
                      sx={{ padding: "5px 0px" }}
                      key={element.label}
                    >
                      <Stack
                        sx={{ alignItems: "center", padding: "0.3em 1em" }}
                        direction={"row"}
                        gap={1}
                      >
                        <img
                          src={element.icon}
                          alt={element.label}
                          style={styles.dropdownIconStyle}
                        />
                        <Typography>{element.label}</Typography>
                      </Stack>
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
            <TextField
              variant="outlined"
              type="text"
              placeholder="Address"
              value={address}
              onChange={(event) => {
                if (event.target.value.length > 0) {
                  setAddressError("");
                }
                setAddress(event.target.value);
              }}
              InputProps={{
                inputProps: {
                  style: {
                    boxSizing: "border-box",
                    padding: "1.4em 1em",
                  },
                },
              }}
              error={addressError !== "" ? true : false}
              helperText={addressError === "" ? "" : addressError}
              fullWidth
              sx={styles.inputStyle}
            />
            <Typography sx={styles.descText} mb={3}>
              Enter receivers public address to transfer the amount
            </Typography>
            <TextField
              variant="outlined"
              type="text"
              autoComplete="off"
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{
                      backgroundColor: COLOR.background,
                      minHeight: "2.5em",
                      borderRadius: "8px 0px 0px 8px",
                    }}
                  >
                    <Typography sx={styles.inputPrefix}>{`${
                      coinBalance?.cryptoTicker
                        ? coinBalance?.cryptoTicker
                        : "AEG"
                    } Qty`}</Typography>
                  </InputAdornment>
                ),
                inputProps: {
                  style: {
                    textAlign: "right",
                    fontWeight: 700,
                    letterSpacing: "0.1em",
                    boxSizing: "border-box",
                    paddingRight: "10px",
                  },
                },
              }}
              value={transferAmount}
              onChange={(event) => {
                if (isValidAmount(event.target.value)) {
                  if (
                    isLessThenOrEqual(
                      event.target.value,
                      coinBalance?.balance
                    ) ||
                    event.target.value === ""
                  ) {
                    setTransferAmount(event.target.value);
                    setTransferAmountError("");
                  }
                }
              }}
              error={transferAmountError !== "" ? true : false}
              helperText={transferAmountError === "" ? "" : transferAmountError}
              fullWidth
              sx={styles.inputStyle}
            />
            <Typography
              sx={styles.availableBalance}
              mt={1}
            >{`Available Balance: ${
              coinBalance?.balance ? coinBalance?.balance : zeroNumber()
            } ${
              coinBalance?.cryptoTicker ? coinBalance?.cryptoTicker : "AEG"
            }`}</Typography>
            {transferFeesError && (
              <Typography sx={styles.transactionFeesError}>
                {transferFeesError}
              </Typography>
            )}
            <Stack
              direction={"row"}
              sx={styles.fullscreen}
              alignItems={"center"}
              justifyContent={"space-between"}
              mb={1}
              mt={2}
            >
              <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                <img
                  src={coinBalance?.cryptoIconUrl}
                  style={{ width: "1.2em", height: "1.2em" }}
                  alt={coinBalance?.cryptoName}
                />
                <Typography sx={styles.transactionFees}>
                  {"Transaction Fees:"}
                </Typography>
              </Stack>

              <Typography sx={styles.transactionFees}>
                {transferFees
                  ? `≈ ${transferFees}`
                  : zeroNumber() + " " + coinBalance?.cryptoTicker}
              </Typography>
            </Stack>
            <Divider />
            <Stack
              direction={"row"}
              sx={styles.fullscreen}
              alignItems={"center"}
              justifyContent={"space-between"}
              mt={1}
            >
              <Typography sx={styles.grandTotal} mb={3}>
                {"Grand Total"}
              </Typography>
              <Typography sx={styles.grandTotal} mb={3}>
                {`${grandTotal ? grandTotal : zeroNumber()} ${
                  coinBalance?.cryptoTicker
                }`}
              </Typography>
            </Stack>

            <PrimaryButton title={"Send"} onClick={handleTransfer} />
          </Stack>
        ) : (
          <></>
        )}
        {ViewState.TWOFA === viewState ? (
          <VerifyTwoFAContent onSuccess={(code) => transferToken(code)} />
        ) : (
          <></>
        )}
        {viewState === ViewState.LOADING ? <DialogLoadingView /> : <></>}
        {viewState === ViewState.CONFIRMATION ? (
          <Stack direction={"column"} sx={styles.inlineCenter} p={3}>
            <img
              src={VerifyIcon}
              alt="RedeemVerified"
              style={styles.confirmationIcon}
            />
            <Typography style={styles.doneText}>Done</Typography>
            <Typography style={styles.transactionSuccess} mt={2}>
              Transaction Successful
            </Typography>
            <Typography style={styles.transactionId} mt={3}>
              Transaction Hash
            </Typography>
            <Typography style={styles.transactionId}>
              {trimAddress(transactionHash)}
            </Typography>
          </Stack>
        ) : (
          <></>
        )}
      </DialogContent>
    </Dialog>
  );
};
export default TransferDialog;
const styles = {
  container: {
    backgroundColor: "rgba(12, 12, 12, 1)",
  },
  iconBackground: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "rgba(0, 0, 0, 1)",
    width: "1em",
    height: "1em",
    borderRadius: "1em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    marginLeft: "1em",
  },
  titleText: {
    fontSize: "1.2rem",
    fontWeight: 500,
  },
  inlineSpaceBetween: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  fullscreen: {
    width: "100%",
  },
  inlineCenter: {},
  inputPrefix: {
    fontSize: "1rem",
    fontWeight: 300,
    padding: "5px 1em",
  },
  inputStyle: {
    fontSize: "1rem",
    "& .MuiInputBase-root": {
      padding: 0,
    },
    "& .MuiInputBase-input": {
      padding: "0 0 0 0",
    },
  },
  dropDownLabel: {
    fontWeight: 600,
    fontSize: "1rem",
    color: "#FFFFFF",
  },
  dropdownRow: {
    display: "flex",
    flexDirection: "row",
    mb: 2,
  },
  dropdownIconStyle: {
    width: 20,
    height: 20,
  },
  descText: {
    fontSize: "0.8rem",
    fontWeight: 300,
    marginTop: "8px",
  },
  availableBalance: {
    fontSize: "0.8rem",
    fontWeight: 300,
    textAlign: "right",
  },
  transactionFees: {
    fontSize: "0.8rem",
    fontWeight: 300,
  },
  grandTotal: {
    fontSize: "0.8rem",
    fontWeight: 500,
  },
  confirmationIcon: {
    width: "3.5em",
    height: "3.5em",
  },
  doneText: {
    fontSize: "1.5rem",
    fontWeight: 800,
    color: "#FFC800",
  },
  transactionSuccess: {
    fontSize: "1rem",
    fontWeight: 500,
  },
  transactionId: {
    fontSize: "0.7rem",
    fontWeight: 400,
  },
  transactionFeesError: {
    fontSize: "0.9rem",
    fontWeight: 400,
    color: "#EE2E31",
  },
};
