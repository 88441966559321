import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  IconButton,
  Divider,
  Stack,
  TextField,
  Dialog,
  DialogContent,
  Paper,
  Tooltip,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import AddBankAccountForm from "./AddBankAccountForm";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/reducers/RootReducer";
import { userProfileRequest } from "../../store/reducers/ProfileReducer";
import { getDateOfBirthFormat } from "../../utils/DateUtils";
import {
  AccountDeletionIcon,
  ActiveAccountIcon,
  BookmarkIcon,
  CakeIcon,
  ChevronRightIcon,
  CloseIcon,
  DataNotFound,
  DeactiveAccountIcon,
  EditPencilIcon,
  MailIcon,
  PhoneIcon,
  ProfileIcon,
  UserIcon,
} from "../../assets";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import EnableTwoFA from "../../components/EnableTwoFA";
import { bankRequest } from "../../store/reducers/BankReducer";
import { getBankName, maskBankAccount } from "../../utils/Utils";
import VerifiedIcon from "@mui/icons-material/Verified";
import VerifyTwoFAModel from "../../components/VerifyTwoFAModel";
import {
  disableTwoFa,
  requestSetAsPrimaryBank,
  requestUpdateBankDetailStatus,
  updateProfile,
  uploadProfileImage,
} from "../../api/api";
import { AlertDataProps } from "../../types/Common";
import AlertComponent from "../../components/AlertComponent";
import { useLocation } from "react-router-dom";
import ResetPasswordDialog from "../../components/ResetPasswordDialog";
import {
  isEmpty,
  isValidFullName,
  isValidPhoneNumber,
} from "../../utils/Validation";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { ProfileImageUploadRequest } from "../../types/ProfileImageModel";
import { UpdateProfileRequest } from "../../types/UpdateProfile";
import { styles } from "./Settings.styles";
import BankDetailsDialog from "../../components/BankDetailsDialog";
import { BankDetailData } from "../../types/BankDetailsModel";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import ForgetPasswordDialog from "../../components/ForgetPasswordDialog";
import AccountDeletionDialog from "../../components/AccountDeletionDialog";

const SettingsPage = () => {
  const location = useLocation();
  const { userProfileState, bankState } = useSelector(
    (state: RootState) => state
  );
  const dispatch = useDispatch();
  const [openBank, setOpenBank] = React.useState(false);
  const [openResetPassword, setOpenResetPassword] = React.useState(false);
  const [openTwoFA, setOpenTwoFA] = React.useState(false);
  const [verifyTwoFA, setVerifyTwoFa] = useState(false);
  const [profileEditToggle, setProfileEditToggle] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [showForgetPassword, setShowForgetPassword] = useState(false);
  const [showAccountDeletionDialog, setShowAccountDeletionDialog] =
    useState(false);

  const [phoneNoError, setPhoneNoError] = useState("");
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [selectedImage, setSelectedImage] = useState<string>("");
  const fileInputRef = useRef<any>(null);
  const [crop, setCrop] = useState<Crop>();
  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const [showBankOption, setShowBankOption] = useState<number>(0);
  const [showBankDetails, setShowBankDetails] = useState<BankDetailData | null>(
    null
  );

  const [alertData, setAlertData] = useState<AlertDataProps>({
    message: "",
    type: "error",
  });

  const toggleProfileEdit = () => {
    setProfileEditToggle((prev) => !prev);
  };

  useEffect(() => {
    dispatch(userProfileRequest());
    dispatch(bankRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  useEffect(() => {
    if (userProfileState?.result) {
      setFirstName(userProfileState?.result?.firstName || "");
      setLastName(userProfileState?.result?.lastName || "");
      setPhoneNo(userProfileState?.result?.phoneNumber || "");
      if (userProfileState?.result?.dob) {
        setSelectedDate(dayjs(userProfileState?.result?.dob));
      }
    }
  }, [userProfileState]);

  const twoFASwitch = useMemo(() => {
    if (userProfileState.result?.twoFactorEnabled === 1) {
      return true;
    } else {
      return false;
    }
  }, [userProfileState.result?.twoFactorEnabled]);

  const handleEnableTFAClicked = (event: any) => {
    if (userProfileState?.result?.twoFactorEnabled === 1) {
      setVerifyTwoFa(true);
    } else {
      setOpenTwoFA(true);
    }
  };

  const disableTwoFA = async (token: string) => {
    try {
      const response = await disableTwoFa({ token: token });
      if (response) {
        if (response.status === 200) {
          setAlertData({
            title: "Two FA Disabled",
            message: response.data.message,
            type: "success",
          });
          dispatch(userProfileRequest());
        } else {
          setAlertData({
            title: "Error",
            message: response.data.message,
            type: "error",
          });
        }
      }
    } catch (error) {}
  };

  const handleUpdateProfile = async () => {
    if (isEmpty(firstName)) {
      setFirstNameError("Required first name");
    } else if (isEmpty(lastName)) {
      setLastNameError("Required last name");
    } else if (isEmpty(phoneNo)) {
      setPhoneNoError("Required phone no.");
    } else if (!isValidFullName(firstName)) {
      setFirstNameError("Please enter valid first name");
    } else if (!isValidFullName(lastName)) {
      setLastNameError("Please enter valid last name");
    } else if (!isValidPhoneNumber(phoneNo)) {
      setPhoneNoError("Please enter valid phone no.");
    } else {
      const parsedDate = dayjs(selectedDate, "DD/MM/YYYY");
      const body: UpdateProfileRequest = {
        first_name: firstName,
        last_name: lastName,
        dob: parsedDate.format("YYYY-MM-DD"),
        phone_number: phoneNo,
        profile: croppedImageUrl
          ? croppedImageUrl
          : userProfileState.result?.profilePic || "",
      };

      const response = await updateProfile(body);
      if (response) {
        if (response.status === 200) {
          setAlertData({
            title: "Profile Update",
            message: response.data.message,
            type: "success",
          });
          setProfileEditToggle(false);
          dispatch(userProfileRequest());
        } else {
          setAlertData({
            title: "Profile Update",
            message: response.data.message,
            type: "error",
          });
        }
      }
    }
  };

  const handleImageChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader?.result) {
          setSelectedImage(reader.result.toString());
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const onCropComplete = (crop: Crop) => {
    makeCroppedImage(crop);
  };

  const makeCroppedImage = (crop: Crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const canvas = document.createElement("canvas");
      const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
      const scaleY = imageRef.current.naturalHeight / imageRef.current.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");

      if (ctx) {
        ctx.drawImage(
          imageRef.current,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );

        const base64Image = canvas.toDataURL("image/jpeg");
        setCroppedImageUrl(base64Image);
      }
    }
  };

  const userProfilePreview = () => {
    return (
      <Card sx={styles.profileCard}>
        <CardContent sx={{ padding: "1em" }}>
          <Box display="flex" alignItems="center" mb={1}>
            {profileEditToggle ? (
              <Stack direction={"column"} sx={styles.profilePictureHover}>
                <input
                  id="profile-image-chooser"
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
                <div
                  style={{ marginBottom: 0, paddingBottom: 0, height: "3em" }}
                  onClick={() => {
                    fileInputRef.current.click();
                  }}
                >
                  {userProfileState.result?.profilePic ? (
                    <img
                      src={userProfileState.result.profilePic}
                      alt="ProfileImage"
                      style={styles.croppedImageStyle}
                    />
                  ) : (
                    <ProfileIcon />
                  )}
                </div>
                <label
                  htmlFor="profile-image-chooser"
                  style={styles.imageChooserText}
                >
                  Edit
                </label>
              </Stack>
            ) : (
              <Stack direction={"column"}>
                {userProfileState.result?.profilePic ? (
                  <img
                    src={userProfileState.result.profilePic}
                    alt="ProfileImage"
                    style={styles.croppedImageStyle}
                  />
                ) : (
                  <ProfileIcon />
                )}
              </Stack>
            )}

            <Box flexGrow={1} ml={2}>
              <Typography variant="h6">
                {userProfileState.result?.firstName}{" "}
                {userProfileState.result?.lastName}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Account Details
              </Typography>
            </Box>
            <IconButton
              onClick={() => toggleProfileEdit()}
              sx={styles.editButton}
            >
              <Typography sx={styles.profileItemText} mr={1}>
                Edit
              </Typography>{" "}
              {profileEditToggle ? (
                <CloseIcon color={"#83807F"} width={14} height={15} />
              ) : (
                <EditPencilIcon />
              )}
            </IconButton>
          </Box>
          <Divider />
          {profileEditToggle ? (
            <Paper
              style={{
                height: "100%",
                overflow: "auto",
                paddingBottom: "1em",
                paddingRight: "1em",
                backgroundColor: "rgba(12, 12, 12, 1)",
              }}
            >
              <Box mt={2}>
                <Stack direction={"row"} sx={styles.inlineCenter}>
                  <Box sx={styles.iconBtn}>
                    <UserIcon width={"1.3em"} height={"1.3em"} />
                  </Box>
                  <Stack
                    direction={"row"}
                    sx={styles.inlineCenterFullWidth}
                    mb={1}
                    gap={2}
                  >
                    <TextField
                      variant="outlined"
                      type="text"
                      label={"First Name"}
                      value={firstName}
                      size="small"
                      onChange={(event) => {
                        if (
                          event.target.value &&
                          event.target.value.length > 0
                        ) {
                          setFirstNameError("");
                        }
                        setFirstName(event.target.value);
                      }}
                      error={firstNameError !== "" ? true : false}
                      helperText={firstNameError !== "" ? firstNameError : ""}
                      fullWidth
                      sx={styles.inputStyle}
                    />
                    <TextField
                      variant="outlined"
                      type="text"
                      label={"Last Name"}
                      value={lastName}
                      size="small"
                      onChange={(event) => {
                        if (
                          event.target.value &&
                          event.target.value.length > 0
                        ) {
                          setLastNameError("");
                        }
                        setLastName(event.target.value);
                      }}
                      error={lastNameError !== "" ? true : false}
                      helperText={lastNameError !== "" ? lastNameError : ""}
                      fullWidth
                      sx={styles.inputStyle}
                    />
                  </Stack>
                </Stack>
                <Stack direction={"row"} sx={styles.inlineCenter} my={2}>
                  <Box sx={styles.iconBtn}>
                    <MailIcon />
                  </Box>
                  <TextField
                    variant="outlined"
                    type="email"
                    label={"Email"}
                    value={userProfileState?.result?.email}
                    disabled
                    size="small"
                    fullWidth
                    sx={styles.inputStyle}
                  />
                </Stack>
                <Stack
                  direction={"row"}
                  sx={styles.inlineCenter}
                  my={2}
                  gap={4}
                >
                  <Stack direction={"row"} sx={styles.inlineCenter}>
                    <Box sx={styles.iconBtn}>
                      <PhoneIcon />
                    </Box>
                    <TextField
                      variant="outlined"
                      type="tel"
                      label={"Phone No."}
                      value={phoneNo}
                      size="small"
                      onChange={(event) => {
                        if (
                          event.target.value &&
                          event.target.value.length > 0
                        ) {
                          setPhoneNoError("");
                        }
                        setPhoneNo(event.target.value);
                      }}
                      error={phoneNoError !== "" ? true : false}
                      helperText={phoneNoError !== "" ? phoneNoError : ""}
                      fullWidth
                      sx={styles.inputStyle}
                    />
                  </Stack>
                  <Stack direction={"row"} sx={styles.inlineCenter}>
                    <Box sx={styles.iconBtn}>
                      <CakeIcon />
                    </Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={selectedDate}
                        disableFuture
                        format={"DD/MM/YYYY"}
                        onChange={(value) => setSelectedDate(dayjs(value))}
                      />
                    </LocalizationProvider>
                  </Stack>
                </Stack>

                <Stack sx={{ width: "50%", margin: "0 auto" }}>
                  <PrimaryButton
                    title="Update Profile"
                    onClick={handleUpdateProfile}
                  />
                </Stack>
              </Box>
            </Paper>
          ) : (
            <Box mt={2}>
              <Stack direction={"row"} sx={styles.inlineCenter} my={1}>
                <Box sx={styles.iconBtn}>
                  <MailIcon />
                </Box>

                <Typography sx={styles.profileItemText}>
                  {userProfileState.result?.email}
                </Typography>
              </Stack>
              <Divider />
              <Stack direction={"row"} sx={styles.inlineCenter} my={1}>
                <Box sx={styles.iconBtn}>
                  <PhoneIcon />
                </Box>
                <Typography sx={styles.profileItemText}>
                  {userProfileState.result?.phoneNumber
                    ? userProfileState.result?.phoneNumber
                    : "-"}
                </Typography>
              </Stack>
              <Divider flexItem sx={{ display: { sm: "block" } }} />
              <Stack direction={"row"} sx={styles.inlineCenter} mt={1}>
                <Box sx={styles.iconBtn}>
                  <CakeIcon />
                </Box>
                <Typography sx={styles.profileItemText}>
                  {userProfileState?.result?.dob
                    ? getDateOfBirthFormat(userProfileState.result?.dob)
                    : "-"}
                </Typography>
              </Stack>
              <Divider flexItem sx={{ display: { sm: "block" } }} />
              <Stack
                direction={"row"}
                sx={styles.inlineHover}
                mt={1}
                onClick={() => setShowAccountDeletionDialog(true)}
              >
                <Box sx={styles.iconBtn}>
                  <AccountDeletionIcon />
                </Box>
                <Typography sx={styles.deactivateItemText}>
                  Request Account Deletion
                </Typography>
              </Stack>
            </Box>
          )}
        </CardContent>
      </Card>
    );
  };

  const handleImageUpload = async () => {
    try {
      if (croppedImageUrl) {
        const body: ProfileImageUploadRequest = {
          profile_image: croppedImageUrl,
        };
        const response = await uploadProfileImage(body);
        if (response) {
          if (response.status === 200) {
            setSelectedImage("");
            dispatch(userProfileRequest());
          }
        }
      }
    } catch (error) {}
  };

  const bankData = useMemo(() => {
    if (showBankOption !== 0) {
      const bank = bankState.result?.find((element) => {
        if (element.bankId === showBankOption) {
          return element;
        }
        return 0;
      });
      return bank;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showBankOption]);

  const handleMakePrimaryAccount = async (bankId: number | undefined) => {
    if (bankId) {
      const response = await requestSetAsPrimaryBank(bankId);
      if (response) {
        if (response.status === 200) {
          setShowBankOption(0);
          setAlertData({
            title: "Make Primary Account",
            message: response.data.message,
            type: "success",
          });
          dispatch(bankRequest());
        } else {
          setShowBankOption(0);
          setAlertData({
            title: "Make Primary Account",
            message: response.data.message,
            type: "error",
          });
        }
        setShowBankOption(0);
      }
    }
  };

  const handleDeactivatedAccount = async (bankId: number | undefined) => {
    if (bankId) {
      const response = await requestUpdateBankDetailStatus(bankId, {
        status: "inactive",
      });
      if (response) {
        if (response.status === 200) {
          setShowBankOption(0);
          setAlertData({
            title: "Deactivate Account",
            message: response.data.message,
            type: "success",
          });
          dispatch(bankRequest());
        } else {
          setShowBankOption(0);
          setAlertData({
            title: "Deactivate Account",
            message: response.data.message,
            type: "error",
          });
        }
        setShowBankOption(0);
      }
    }
  };

  const handleActiveAccount = async (bankId: number | undefined) => {
    if (bankId) {
      const response = await requestUpdateBankDetailStatus(bankId, {
        status: "active",
      });
      if (response) {
        if (response.status === 200) {
          setShowBankOption(0);
          setAlertData({
            title: "Active Account",
            message: response.data.message,
            type: "success",
          });
          dispatch(bankRequest());
        } else {
          setShowBankOption(0);
          setAlertData({
            title: "Active Account",
            message: response.data.message,
            type: "error",
          });
        }
        setShowBankOption(0);
      }
    }
  };

  const bankCard = () => {
    return (
      <Grid item xs={12} md={6}>
        <Card sx={{ height: "45vh", backgroundColor: "rgba(12, 12, 12, 1)" }}>
          <CardContent>
            <Box display="flex" alignItems="center" mb={2}>
              <img
                src="../../static/svg/bank.svg"
                alt="bankicon"
                style={{ padding: "0 15px 0 0" }}
              />
              <Box flexGrow={1}>
                <Typography variant="h6">Bank Details</Typography>
              </Box>
              <Box sx={{ width: "40%" }}>
                <PrimaryButton
                  title="Link Account"
                  onClick={() => setOpenBank(true)}
                />
              </Box>
            </Box>
            <Divider />
            {bankState.result && bankState.result.length > 0 ? (
              bankState.result.map((bank) => (
                <Grid container sx={styles.inlineSpaceBetween} my={2}>
                  <Grid item xs={7} lg={7}>
                    <Stack
                      direction={"row"}
                      sx={styles.inlineCenterClickable}
                      onClick={() => setShowBankDetails(bank)}
                    >
                      <>
                        <Box sx={styles.bankLogoContainer}>
                          <Box
                            sx={
                              bank.status === "active"
                                ? styles.bankLogoGlass
                                : styles.bankLogoGlassDeactive
                            }
                          >
                            <Typography
                              sx={
                                bank.status === "active"
                                  ? styles.bankLogoText
                                  : styles.bankLogoTextDeactive
                              }
                            >
                              {getBankName(bank?.bankName)}
                            </Typography>
                          </Box>
                        </Box>

                        <Box>
                          <Stack
                            direction={"row"}
                            sx={styles.inlineCenter}
                            gap={1}
                          >
                            <Typography
                              sx={
                                bank?.status === "active"
                                  ? styles.activeBankName
                                  : styles.deactiveBankName
                              }
                            >
                              {bank?.bankName}
                            </Typography>
                            {bank.verificationStatus === "Verified" ? (
                              <VerifiedIcon
                                sx={{
                                  fontSize: "1.1em",
                                  color:
                                    bank?.status === "active"
                                      ? "#14CB1B"
                                      : "#5A5463",
                                }}
                              />
                            ) : (
                              <></>
                            )}
                          </Stack>

                          <Typography sx={styles.maskedBankAccountText}>
                            {maskBankAccount(bank?.accountNumber)}
                            {bank?.isPrimary === 1 ? (
                              <Typography sx={{ color: "#14CB1B" }} ml={0.5}>
                                (Primary)
                              </Typography>
                            ) : (
                              <></>
                            )}
                          </Typography>
                        </Box>
                      </>
                    </Stack>
                  </Grid>
                  <Grid item xs={5} lg={5}>
                    <Grid container spacing={2} sx={{ alignItems: "center" }}>
                      <Grid item xs={6} lg={6}>
                        {bank?.isPrimary === 0 ? (
                          <Tooltip title={"Make Primary Account"}>
                            <Box
                              sx={styles.optionActionBtn}
                              onClick={() =>
                                handleMakePrimaryAccount(bank.bankId)
                              }
                            >
                              <Box sx={styles.iconBox}>
                                <BookmarkIcon />
                              </Box>

                              <Typography sx={styles.optionBtnText}>
                                Set Primary
                              </Typography>
                            </Box>
                          </Tooltip>
                        ) : (
                          <Box sx={styles.optionActionBtn}>
                            <Box sx={styles.iconBox}>
                              <BookmarkIcon color="#5A5463" />
                            </Box>

                            <Typography sx={styles.optionBtnTextDisable}>
                              Set Primary
                            </Typography>
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        {bankState.result &&
                          bankState.result.length > 1 &&
                          bank?.isPrimary === 0 &&
                          (bank?.status === "active" ? (
                            <Tooltip title={"Deactive Account"}>
                              <Box
                                sx={styles.optionActionBtn}
                                onClick={() =>
                                  handleDeactivatedAccount(bank.bankId)
                                }
                              >
                                <Box sx={styles.iconBox}>
                                  <DeactiveAccountIcon />
                                </Box>

                                <Typography sx={styles.optionBtnText}>
                                  Deactivate
                                </Typography>
                              </Box>
                            </Tooltip>
                          ) : (
                            <Tooltip title={"Active Account"}>
                              <Box
                                sx={styles.optionActionBtn}
                                onClick={() => handleActiveAccount(bank.bankId)}
                              >
                                <Box sx={styles.iconBox}>
                                  <ActiveAccountIcon />
                                </Box>

                                <Typography sx={styles.optionBtnText}>
                                  Activate
                                </Typography>
                              </Box>
                            </Tooltip>
                          ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))
            ) : (
              <Stack direction={"column"} pt={2}>
                <img
                  src={DataNotFound}
                  alt="NoDataFound"
                  style={{
                    width: "10em",
                    height: "10em",
                    margin: "0 auto",
                  }}
                />
                <Typography sx={styles.noDataFoundTextHeading} mt={2}>
                  Sorry!
                </Typography>
                <Typography sx={styles.noDataFoundText}>
                  No Bank Account Linked
                </Typography>
              </Stack>
            )}
            {showBankOption !== 0 ? (
              <Dialog
                open={showBankOption !== 0 ? true : false}
                onClose={() => {
                  setShowBankOption(0);
                }}
              >
                <DialogContent sx={{ padding: 0 }}>
                  <Box sx={styles.bankOptionContainer}>
                    <Stack
                      direction={"row"}
                      sx={styles.inlineSpaceBetween}
                      mb={0.5}
                    >
                      <Box sx={styles.bankLogoContainerSM}>
                        <Box sx={styles.bankLogoGlassSM}>
                          <Typography sx={styles.bankLogoTextSM}>
                            {getBankName(bankData?.bankName)}
                          </Typography>
                        </Box>
                      </Box>
                      <Typography style={styles.bankOptionTitle}>
                        {bankData?.bankName}
                      </Typography>
                      <IconButton
                        sx={styles.iconStyle}
                        onClick={() => {
                          setShowBankOption(0);
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Stack>

                    <Divider />
                    {bankData?.isPrimary === 1 ||
                    bankData?.status === "inactive" ? (
                      <Box sx={styles.optionItem}>
                        <Box sx={styles.iconBg}>
                          <BookmarkIcon
                            color={
                              bankData?.isPrimary === 1 ||
                              bankData?.status === "inactive"
                                ? "#5A5463"
                                : "#FFFFFF"
                            }
                          />
                        </Box>
                        <Typography sx={styles.optionItemTextNoActive} ml={1}>
                          Make Primary Account
                        </Typography>
                      </Box>
                    ) : (
                      <Box
                        sx={styles.optionItem}
                        onClick={() =>
                          handleMakePrimaryAccount(bankData?.bankId)
                        }
                      >
                        <Box sx={styles.iconBg}>
                          <BookmarkIcon />
                        </Box>
                        <Typography sx={styles.optionItemText} ml={1}>
                          Make Primary Account
                        </Typography>
                      </Box>
                    )}

                    <Divider />
                    {bankData?.status === "active" ? (
                      <Box
                        sx={styles.optionItem}
                        onClick={() =>
                          handleDeactivatedAccount(bankData?.bankId)
                        }
                      >
                        <Box sx={styles.iconBg}>
                          <DeactiveAccountIcon />
                        </Box>
                        <Typography sx={styles.optionItemTextDeactive} ml={1}>
                          Deactive Account
                        </Typography>
                      </Box>
                    ) : (
                      <Box
                        sx={styles.optionItem}
                        onClick={() => handleActiveAccount(bankData?.bankId)}
                      >
                        <Box sx={styles.iconBg}>
                          <ActiveAccountIcon />
                        </Box>
                        <Typography sx={styles.optionItemTextActive} ml={1}>
                          Active Account
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </DialogContent>
              </Dialog>
            ) : (
              <></>
            )}
          </CardContent>
        </Card>
      </Grid>
    );
  };

  const performLogout = () => {
    localStorage.clear();
    dispatch({ type: "LOGOUT_REQUEST" });
    window.location.replace("/auth/login");
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        {userProfilePreview()}
      </Grid>
      {selectedImage ? (
        <Dialog open={selectedImage ? true : false}>
          <DialogContent>
            <Stack direction={"row"} sx={styles.inlineSpaceBetween} mb={1}>
              <Stack direction={"row"} sx={styles.inlineCenter}>
                {croppedImageUrl && (
                  <div className="cropped-image">
                    <img
                      src={croppedImageUrl}
                      alt="Cropped"
                      style={styles.croppedImageStyle}
                    />
                  </div>
                )}
                <Typography sx={styles.profileItemText} ml={1}>
                  Image Cropper
                </Typography>
              </Stack>

              <IconButton
                sx={styles.iconBackground}
                onClick={() => setSelectedImage("")}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider />

            <ReactCrop
              style={{ width: 300, height: 300 }}
              crop={crop}
              aspect={1}
              onChange={(newCrop) => setCrop(newCrop)}
              onComplete={onCropComplete}
            >
              <img ref={imageRef} src={selectedImage} alt="Crop" />
            </ReactCrop>

            <PrimaryButton title={"Upload"} onClick={handleImageUpload} />
          </DialogContent>
        </Dialog>
      ) : (
        <></>
      )}

      {bankCard()}

      <Grid item xs={12} md={6}>
        <Card sx={{ backgroundColor: "rgba(12, 12, 12, 1)" }}>
          <CardContent>
            <Box display="flex" alignItems="center" mb={1}>
              <img
                src="../../static/svg/two-step-verification.svg"
                alt="two_step_verification"
                style={{ padding: "0 15px 0 0" }}
              />
              <Box flexGrow={1}>
                <Typography variant="body2">Two Step verification</Typography>
              </Box>
              <FormControlLabel
                label=""
                checked={twoFASwitch}
                control={
                  <IOSSwitch sx={{ m: 1 }} onClick={handleEnableTFAClicked} />
                }
              />
            </Box>
            <Divider flexItem sx={{ display: { sm: "block" } }} />
            <Box mt={2}>
              <Box
                display="flex"
                alignItems="center"
                mb={1}
                mt={1}
                onClick={() => setOpenResetPassword(true)}
              >
                <img
                  src="../../static/svg/reset-password.svg"
                  alt="reset password"
                  style={{ padding: "0 15px 0 0" }}
                />
                <Typography variant="body2" style={{ cursor: "pointer" }}>
                  Reset Password{" "}
                </Typography>
              </Box>
            </Box>
            <Divider flexItem sx={{ display: { sm: "block" } }} />
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              mt={2}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                gap={0.5}
                sx={styles.hoverPointer}
                onClick={() => {
                  window.open(
                    "https://aegt.io",
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
              >
                <Typography sx={styles.aboutText}>About us</Typography>
                <ChevronRightIcon />
              </Stack>
              <Stack
                direction={"row"}
                alignItems={"center"}
                gap={0.5}
                sx={styles.hoverPointer}
                onClick={() => {
                  window.open(
                    "https://aegt.io/privacy-policy",
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
              >
                <Typography sx={styles.aboutText}>
                  Terms and Conditions
                </Typography>
                <ChevronRightIcon />
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Grid>

      <AddBankAccountForm
        open={openBank}
        handleClose={() => setOpenBank(false)}
        onError={(error) => {
          setAlertData({
            title: "Add Bank",
            message: error,
            type: "error",
          });
          console.log("Error Bank details");
        }}
        onSuccess={(message: string) => {
          setAlertData({
            title: "Add Bank",
            message: message,
            type: "success",
          });
          setOpenBank(false);
          dispatch(bankRequest());
        }}
      />

      <VerifyTwoFAModel
        open={verifyTwoFA}
        title="Two Step Verification"
        handleClose={() => {
          setVerifyTwoFa(false);
        }}
        onTwoFASuccess={(token: string | undefined) => {
          if (token) {
            setVerifyTwoFa(false);
            disableTwoFA(token);
          }
        }}
      />

      {showForgetPassword ? (
        <ForgetPasswordDialog
          open={showForgetPassword}
          onSuccess={(message) => {
            setAlertData({ message: message, type: "success" });
            setShowForgetPassword(false);
          }}
          onError={(error) => {
            setAlertData({ message: error, type: "error" });
            setShowForgetPassword(false);
          }}
          handleClose={() => {
            setShowForgetPassword(false);
          }}
        />
      ) : (
        <></>
      )}

      {/* Reset Password Dialog */}
      {openResetPassword ? (
        <ResetPasswordDialog
          open={true}
          handleClose={() => setOpenResetPassword(false)}
          onForgotPasswordClick={() => {
            setOpenResetPassword(false);
            setShowForgetPassword(true);
          }}
          onError={(error: string) => {
            setAlertData({
              title: "Password Reset",
              message: error,
              type: "error",
            });
            setOpenResetPassword(false);
          }}
          onSuccess={(message: string) => {
            setAlertData({
              title: "Password Reset",
              message: message,
              type: "success",
            });
            setOpenResetPassword(false);
          }}
        />
      ) : (
        <></>
      )}

      {/* Two Step Verification Dialog */}
      <EnableTwoFA
        open={openTwoFA}
        handleClose={() => {
          setOpenTwoFA(false);
        }}
        onSuccess={() => {
          setOpenTwoFA(false);
          dispatch(userProfileRequest());
        }}
      />

      {showBankDetails !== null ? (
        <BankDetailsDialog
          open={showBankDetails !== null ? true : false}
          handleClose={() => setShowBankDetails(null)}
          bankDetails={showBankDetails}
        />
      ) : (
        <></>
      )}

      {showAccountDeletionDialog === true ? (
        <AccountDeletionDialog
          open={showAccountDeletionDialog}
          handleClose={() => setShowAccountDeletionDialog(false)}
          onError={(error: string) => {
            setAlertData({ message: error, type: "error" });
            setShowAccountDeletionDialog(false);
          }}
          onSuccess={(message: string) => {
            setAlertData({ message: message, type: "success" });
            setShowAccountDeletionDialog(false);
            performLogout();
          }}
        />
      ) : (
        <></>
      )}

      <AlertComponent
        open={alertData.message !== "" ? true : false}
        onClose={() => setAlertData({ message: "", type: "error" })}
        message={alertData.message}
        type={alertData.type ? alertData.type : "error"}
      />
    </Grid>
  );
};
export default SettingsPage;

const IOSSwitch = styled((props: any) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#373738" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
