import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import TransactionComponent from "./TransactionComponent";
import { useState } from "react";
import { CloseIcon } from "../assets";
import Iconify from "./icons/Iconify";
import Loader from "./Loader";
import PrimaryButton from "./PrimaryButton/PrimaryButton";
import { isEmpty, isValidPassword } from "../utils/Validation";
import { requestUpdatePassword } from "../api/api";
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers/RootReducer";
import VerifyTwoFAContent from "./VerifyTwoFAContent";
import DialogLoadingView from "./DialogLoadingView";
import { COLOR } from "../theme";
import { UpdatePasswordRequest } from "../types/UpdatePasswordModel";
import { aesEncrypt } from "../utils/Encryption";

type Props = {
  open: boolean;
  handleClose: () => void;
  onError: (error: string) => void;
  onSuccess: (message: string) => void;
  onForgotPasswordClick: () => void;
};

const enum ViewState {
  RESET_PASSWORD_FORM,
  TWOFA,
  LOADING,
}

const ResetPasswordDialog = (props: Props) => {
  const { open, handleClose, onError, onSuccess, onForgotPasswordClick } =
    props;
  const profile = useSelector((state: RootState) => state.userProfileState);
  const [oldPassword, setOldPassword] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [viewState, setViewState] = useState<ViewState>(
    ViewState.RESET_PASSWORD_FORM
  );

  const handleCloseClicked = () => {
    resetFields();
    handleClose();
  };

  const resetFields = () => {
    setLoading(false);
    setViewState(ViewState.RESET_PASSWORD_FORM);
    setOldPassword("");
    setOldPasswordError("");
    setNewPassword("");
    setNewPasswordError("");
    setConfirmPassword("");
    setConfirmPasswordError("");
  };

  const handleResetPassword = () => {
    if (isEmpty(oldPassword)) {
      setOldPasswordError("Old password is required");
    } else if (isEmpty(newPassword)) {
      setNewPasswordError("New password is required");
    } else if (isEmpty(confirmPassword)) {
      setConfirmPasswordError("Confirm password is required");
    } else if (!isValidPassword(newPassword)) {
      setNewPasswordError(
        "Password should be minimum 8 characters long, must contain at least a number, special symbol and Capital letter"
      );
    } else if (confirmPassword !== newPassword) {
      setConfirmPasswordError(
        "New password and confirm password doesn't match"
      );
    } else {
      if (profile.result?.twoFactorEnabled === 1) {
        setViewState(ViewState.TWOFA);
      } else {
        resetPasswordAPI("000000");
      }
    }
  };

  const resetPasswordAPI = async (token: string) => {
    setLoading(true);
    setViewState(ViewState.LOADING);
    const body: UpdatePasswordRequest = {
      old_password: oldPassword,
      new_password: newPassword,
    };
    const encryptText = aesEncrypt(JSON.stringify(body));
    const encryptBody = {
      dataCipher: encryptText,
    };
    const response = await requestUpdatePassword(encryptBody);
    if (response) {
      if (response.status === 200) {
        resetFields();
        onSuccess(response.data.message);
      } else {
        resetFields();
        onError(response.data.message);
      }
    } else {
      resetFields();
      onError("Failed to reset password");
    }
  };

  const handleClipboardEvent = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={TransactionComponent}
      keepMounted
      PaperProps={{
        style: {
          backgroundColor: "rgba(12, 12, 12, 1)",
          border: "0.5px solid rgba(255, 255, 255, 0.4)",
        },
      }}
      onClose={handleCloseClicked}
      aria-describedby="dialog-profile-description"
    >
      <DialogContent sx={styles.container}>
        <Stack direction={"row"} sx={styles.inlineSpaceBetween} mb={1}>
          <Typography variant="h4" component="h4">
            Reset Password
          </Typography>
          <IconButton sx={styles.iconBackground} onClick={handleCloseClicked}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
        {viewState === ViewState.RESET_PASSWORD_FORM ? (
          <Stack direction={"column"} gap={2} mt={2}>
            <TextField
              variant="outlined"
              type={showOldPassword ? "text" : "password"}
              label="Old Password"
              value={oldPassword}
              onPaste={handleClipboardEvent}
              onCopy={handleClipboardEvent}
              onCut={handleClipboardEvent}
              onChange={(event) => {
                if (event.target.value && event.target.value.length > 0) {
                  setOldPasswordError("");
                }
                setOldPassword(event.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowOldPassword((prev) => !prev)}
                      edge="end"
                      style={{ color: "#FFC800" }}
                    >
                      <Iconify
                        icon={
                          showOldPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={oldPasswordError !== "" ? true : false}
              helperText={oldPasswordError !== "" ? oldPasswordError : ""}
              fullWidth
              sx={styles.inputStyle}
            />
            <Typography
              sx={styles.forgetPasswordText}
              onClick={() => {
                onForgotPasswordClick();
              }}
            >
              Forget Password?
            </Typography>
            <TextField
              variant="outlined"
              type={showNewPassword ? "text" : "password"}
              label="New Password"
              value={newPassword}
              onPaste={handleClipboardEvent}
              onCopy={handleClipboardEvent}
              onCut={handleClipboardEvent}
              onChange={(event) => {
                if (event.target.value && event.target.value.length > 0) {
                  setNewPasswordError("");
                }
                setNewPassword(event.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowNewPassword((prev) => !prev)}
                      edge="end"
                      style={{ color: "#FFC800" }}
                    >
                      <Iconify
                        icon={
                          showNewPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={newPasswordError !== "" ? true : false}
              helperText={newPasswordError !== "" ? newPasswordError : ""}
              fullWidth
              sx={styles.inputStyle}
            />
            <Typography sx={styles.infoText}>
              Required At least 8 characters, Including upper case, Number,
              Special characters
            </Typography>
            <TextField
              variant="outlined"
              type={showConfirmPassword ? "text" : "password"}
              label="Confirm Password"
              onPaste={handleClipboardEvent}
              onCopy={handleClipboardEvent}
              onCut={handleClipboardEvent}
              value={confirmPassword}
              onChange={(event) => {
                if (event.target.value && event.target.value.length > 0) {
                  setConfirmPasswordError("");
                }
                setConfirmPassword(event.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowConfirmPassword((prev) => !prev)}
                      edge="end"
                      style={{ color: "#FFC800" }}
                    >
                      <Iconify
                        icon={
                          showConfirmPassword
                            ? "eva:eye-fill"
                            : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={confirmPasswordError !== "" ? true : false}
              helperText={
                confirmPasswordError !== "" ? confirmPasswordError : ""
              }
              fullWidth
              sx={styles.inputStyle}
            />
            <Box mt={2}>
              {loading ? (
                <Loader />
              ) : (
                <PrimaryButton
                  title={"Reset Password"}
                  onClick={handleResetPassword}
                />
              )}
            </Box>
          </Stack>
        ) : (
          <></>
        )}
        {viewState === ViewState.TWOFA ? (
          <VerifyTwoFAContent onSuccess={(code) => resetPasswordAPI(code)} />
        ) : (
          <></>
        )}
        {viewState === ViewState.LOADING ? <DialogLoadingView /> : <></>}
      </DialogContent>
    </Dialog>
  );
};
export default ResetPasswordDialog;
const styles = {
  container: {
    backgroundColor: "rgba(12, 12, 12, 1)",
    maxWidth: 380,
  },
  infoText: {
    fontSize: "0.8rem",
    fontWeight: 400,
    flexWrap: "wrap",
  },
  iconBackground: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "rgba(0, 0, 0, 1)",
    width: "1em",
    height: "1em",
    borderRadius: "1em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    marginLeft: "1em",
  },
  inlineSpaceBetween: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  inlineCenter: {
    alignItems: "center",
  },
  inputStyle: {
    fontSize: "0.8rem",
    fontWeight: 400,
  },
  forgetPasswordText: {
    alignSelf: "flex-end",
    fontSize: "0.8rem",
    fontWeight: 500,
    color: COLOR.primary,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
};
