import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { CloseIcon } from "../assets";
import TransactionComponent from "./TransactionComponent";
import { requestForgetPassword, requestResetPassword } from "../api/api";
import { ResetPasswordRequest } from "../types/ForgetPasswordModel";
import PrimaryButton from "./PrimaryButton/PrimaryButton";
import { useEffect, useRef, useState } from "react";
import Loader from "./Loader";
import AlertComponent from "./AlertComponent";
import { AlertDataProps } from "../types/Common";
import EyeShowIcon from "../assets/svg/EyeShowIcon";
import EyeSlashIcon from "../assets/svg/EyeSlashIcon";
import {
  isEmpty,
  isValidEmailAddress,
  isValidPassword,
} from "../utils/Validation";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ReCaptchaComponent from "../pages/login/ReCaptchaComponent";
import { aesEncrypt } from "../utils/Encryption";

enum ForgetPasswordStatus {
  FORM,
  OTP_SCREEN,
  RESET_SCREEN,
}

type Props = {
  open: boolean;
  handleClose: () => void;
  onSuccess: (message: string) => void;
  onError: (error: string) => void;
};

const ForgetPasswordDialog = (props: Props) => {
  const { open, handleClose, onSuccess, onError } = props;
  const [loading, setLoading] = useState(false);
  const [alertData, setAlertData] = useState<AlertDataProps>({
    message: "",
    type: "error",
  });
  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const [password, setPassword] = useState<string | null>(null);
  const [confirmPassword, setConfirmPassword] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState<
    string | null
  >(null);
  const [passwordSecureText, setPasswordSecureText] = useState(false);
  const [confirmPasswordSecureText, setConfirmPasswordSecureText] =
    useState(false);

  const [forgetPasswordStatus, setForgetPasswordStatus] = useState(
    ForgetPasswordStatus.FORM
  );
  const [reCaptcha, setReCaptcha] = useState<string | null>(null);
  const [code1, setCode1] = useState("");
  const [code2, setCode2] = useState("");
  const [code3, setCode3] = useState("");
  const [code4, setCode4] = useState("");
  const [otpError, setOTPError] = useState<string | null>(null);
  const input1Refs = useRef<HTMLInputElement | null>(null);
  const input2Refs = useRef<HTMLInputElement | null>(null);
  const input3Refs = useRef<HTMLInputElement | null>(null);
  const input4Refs = useRef<HTMLInputElement | null>(null);

  const resetFormData = () => {
    setEmail(null);
    setEmailError(null);
    setPassword(null);
    setPasswordError(null);
    setConfirmPassword(null);
    setConfirmPasswordError(null);
    setConfirmPasswordSecureText(false);
    setPasswordSecureText(false);

    setCode1("");
    setCode2("");
    setCode3("");
    setCode4("");
    input1Refs?.current?.focus();
  };

  const handleEmailSubmit = async () => {
    if (email === null || isEmpty(email)) {
      setEmailError("Please enter your email address");
    } else if (!isValidEmailAddress(email)) {
      setEmailError("Please enter a valid email address");
    } else {
      setLoading(true);
      const body = {
        email: email,
      };
      try {
        const response = await requestForgetPassword(body);
        setLoading(false);
        if (response) {
          if (response.status === 200) {
            setForgetPasswordStatus(ForgetPasswordStatus.OTP_SCREEN);
            setAlertData({ message: response.data.message, type: "success" });
          } else {
            setAlertData({ message: response.data.message, type: "success" });
          }
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const onHandleClose = () => {
    handleClose();
  };

  const handleOTPSubmit = () => {
    if (code1 && code2 && code3 && code4) {
      setForgetPasswordStatus(ForgetPasswordStatus.RESET_SCREEN);
    } else {
      setOTPError("Please enter OTP");
    }
  };

  const handleResetPassword = async () => {
    let isValid = true;
    if (password === null || isEmpty(password)) {
      setPasswordError("Please enter your password");
      isValid = false;
    }
    if (password && !isValidPassword(password)) {
      setPasswordError(
        "Password should be minimum 8 characters long, must contain at least a number, special symbol and Capital letter"
      );
      isValid = false;
    }
    if (confirmPassword === null || isEmpty(confirmPassword)) {
      setConfirmPasswordError("Please enter your password again");
      isValid = false;
    }
    if (confirmPassword !== password) {
      setConfirmPasswordError("Password & confirm password does'nt match");
      isValid = false;
    }
    if (isValid === false) {
      return;
    }
    if (
      email &&
      password &&
      confirmPassword &&
      code1 &&
      code2 &&
      code3 &&
      code4 &&
      reCaptcha
    ) {
      const body: ResetPasswordRequest = {
        email: email,
        password: password,
        otp: code1 + code2 + code3 + code4,
        recaptchaToken: reCaptcha,
      };
      const encryptText = aesEncrypt(JSON.stringify(body));
      const encryptBody = {
        dataCipher: encryptText,
      };
      const response = await requestResetPassword(encryptBody);
      if (response) {
        if (response.status === 200) {
          resetFormData();
          onSuccess(response.data.message);
        } else {
          onError(response.data.message);
        }
      }
    }
  };

  useEffect(() => {
    if (reCaptcha && reCaptcha !== null) {
      handleResetPassword();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reCaptcha]);

  const handleClipboardEvent = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={TransactionComponent}
      keepMounted
      onClose={onHandleClose}
      aria-describedby="dialog-profile-description"
      PaperProps={{
        style: {
          backgroundColor: "rgba(12, 12, 12, 1)",
          border: "0.5px solid rgba(255, 255, 255, 0.4)",
          minWidth: 380,
          maxWidth: 380,
        },
      }}
    >
      <DialogContent>
        <Stack direction={"row"} sx={styles.inlineSpaceBetween} mb={0.5}>
          <Typography sx={styles.titleText}>Forgot Password</Typography>
          <IconButton sx={styles.iconBackground} onClick={onHandleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
        {forgetPasswordStatus === ForgetPasswordStatus.FORM && (
          <>
            <TextField
              fullWidth
              autoComplete="email"
              type="email"
              label="Email Address"
              sx={{
                input: {
                  color: "white",
                  fontSize: "0.8rem",
                  fontWeight: 400,
                },
                marginTop: "1em",
                marginBottom: "2em",
                minWidth: "250px",
              }}
              onChange={(event) => {
                if (event.target.value && event.target.value.length > 0) {
                  setEmailError(null);
                }
                setEmail(event.target.value);
              }}
              error={emailError !== null ? true : false}
              helperText={emailError === null ? "" : emailError}
            />
            {loading ? (
              <Loader />
            ) : (
              <PrimaryButton
                title="Forgot Password"
                onClick={handleEmailSubmit}
              />
            )}
          </>
        )}
        {forgetPasswordStatus === ForgetPasswordStatus.OTP_SCREEN && (
          <Box sx={styles.boxAlignCenter}>
            <Typography sx={styles.labelText}>
              Enter the OTP send to your email
            </Typography>
            <Stack direction={"column"} mb={4}>
              <Stack sx={{ mb: 1 }} direction={"row"} gap={2}>
                <TextField
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    maxLength: 1,
                  }}
                  inputRef={input1Refs}
                  onChange={(e) => {
                    if (e.target.value && e.target.value.length > 0) {
                      setOTPError(null);
                      input2Refs?.current?.focus();
                      setCode1(e.target.value);
                    }
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Backspace") {
                      if (code1 && code1.length > 0) {
                        setCode1("");
                      }
                    }
                  }}
                  sx={styles.inputStyle}
                  value={code1}
                />
                <TextField
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    maxLength: 1,
                  }}
                  inputRef={input2Refs}
                  onChange={(e) => {
                    if (e.target.value && e.target.value.length > 0) {
                      setOTPError(null);
                      input3Refs?.current?.focus();
                      setCode2(e.target.value);
                    }
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Backspace") {
                      if (code2 && code2.length > 0) {
                        setCode2("");
                      } else {
                        input1Refs?.current?.focus();
                      }
                    }
                  }}
                  sx={styles.inputStyle}
                  value={code2}
                />
                <TextField
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    maxLength: 1,
                  }}
                  inputRef={input3Refs}
                  onChange={(e) => {
                    if (e.target.value && e.target.value.length > 0) {
                      setOTPError(null);
                      input4Refs?.current?.focus();
                      setCode3(e.target.value);
                    }
                  }}
                  onKeyDown={(event) => {
                    console.log(" handleChange event: ", event.key);
                    if (event.key === "Backspace") {
                      if (code3 && code3.length > 0) {
                        setCode3("");
                      } else {
                        input2Refs?.current?.focus();
                      }
                    }
                  }}
                  sx={styles.inputStyle}
                  value={code3}
                />
                <TextField
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    maxLength: 1,
                  }}
                  inputRef={input4Refs}
                  onChange={(e) => {
                    if (e.target.value && e.target.value.length > 0) {
                      setOTPError(null);
                      setCode4(e.target.value);
                    }
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Backspace") {
                      if (code4 && code4.length > 0) {
                        setCode4("");
                      } else {
                        input3Refs?.current?.focus();
                      }
                    }
                  }}
                  sx={styles.inputStyle}
                  value={code4}
                />
              </Stack>
              {otpError ? (
                <Typography sx={styles.otpErrorStyle}>{otpError}</Typography>
              ) : null}
            </Stack>

            {loading ? (
              <Loader />
            ) : (
              <PrimaryButton title="Next" onClick={handleOTPSubmit} />
            )}
          </Box>
        )}
        {forgetPasswordStatus === ForgetPasswordStatus.RESET_SCREEN && (
          <Box sx={styles.boxAlignCenter}>
            <Typography sx={styles.labelText}>
              Set your new password to reset your account credentials
            </Typography>
            <TextField
              fullWidth
              autoComplete="off"
              type={passwordSecureText ? "text" : "password"}
              label="Enter Password"
              onPaste={handleClipboardEvent}
              onCopy={handleClipboardEvent}
              onCut={handleClipboardEvent}
              sx={{
                input: {
                  color: "white",
                  fontWeight: 400,
                },
              }}
              onChange={(event) => {
                if (event.target.value && event.target.value.length > 0) {
                  setPasswordError(null);
                }
                setPassword(event.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setPasswordSecureText(!passwordSecureText)}
                      edge="end"
                      style={{ color: "#FFC800" }}
                    >
                      {passwordSecureText ? <EyeShowIcon /> : <EyeSlashIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={passwordError !== null ? true : false}
              helperText={passwordError === null ? "" : passwordError}
            />
            <Typography
              sx={styles.passwordValidationInfo}
              gutterBottom
              mb={2}
              mt={0.5}
            >
              Required At least 8 characters, Including upper case, Number,
              Special characters
            </Typography>
            <TextField
              fullWidth
              autoComplete="off"
              type={confirmPasswordSecureText ? "text" : "password"}
              onPaste={handleClipboardEvent}
              onCopy={handleClipboardEvent}
              onCut={handleClipboardEvent}
              label="Confirm Password"
              sx={{
                marginBottom: "2em",
                input: {
                  color: "white",
                  fontWeight: 400,
                },
              }}
              onChange={(event) => {
                if (event.target.value && event.target.value.length > 0) {
                  setConfirmPasswordError(null);
                }
                setConfirmPassword(event.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setConfirmPasswordSecureText(!confirmPasswordSecureText)
                      }
                      edge="end"
                      style={{ color: "#FFC800" }}
                    >
                      {confirmPasswordSecureText ? (
                        <EyeShowIcon />
                      ) : (
                        <EyeSlashIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={confirmPasswordError !== null ? true : false}
              helperText={
                confirmPasswordError === null ? "" : confirmPasswordError
              }
            />
            {loading ? (
              <Loader />
            ) : (
              <GoogleReCaptchaProvider
                useRecaptchaNet
                reCaptchaKey={siteKey as string}
                scriptProps={{
                  async: true,
                  defer: true,
                  appendTo: "body",
                }}
              >
                <ReCaptchaComponent
                  title="Reset Password"
                  onSuccess={(token) => {
                    if (token) {
                      setReCaptcha(token);
                    }
                  }}
                  onError={(error) => {
                    if (error) {
                      setAlertData({ message: error, type: "error" });
                    }
                  }}
                />
              </GoogleReCaptchaProvider>
            )}
          </Box>
        )}
      </DialogContent>
      <AlertComponent
        open={alertData.message !== "" ? true : false}
        onClose={() => setAlertData({ message: "", type: "error" })}
        message={alertData.message}
        type={alertData.type ? alertData.type : "error"}
      />
    </Dialog>
  );
};
export default ForgetPasswordDialog;
const styles = {
  iconBackground: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "rgba(0, 0, 0, 1)",
    width: "1em",
    height: "1em",
    borderRadius: "1em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    marginLeft: "1em",
  },
  inlineSpaceBetween: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  inlineCenter: {
    alignItems: "center",
  },
  titleText: {
    fontSize: "1rem",
    fontWeight: 600,
  },
  otpErrorStyle: {
    fontSize: "0.7rem",
    fontWeight: 400,
    color: "red",
  },
  passwordValidationInfo: {
    fontSize: "0.7rem",
    fontWeight: 300,
  },
  labelText: {
    fontSize: "0.9rem",
    fontWeight: 400,
    marginBottom: "1em",
    maxWidth: 360,
    textAlign: "center",
  },
  boxAlignCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "1em",
  },
  inputStyle: {
    "& .MuiOutlinedInput-root": {
      width: "40px", // Set width and height to ensure square aspect ratio
      minHeight: "40px",
      padding: 0, // Remove default padding
      display: "flex",
      alignItems: "center",
      "& .MuiInputBase-input": {
        textAlign: "center", // Center the input text
        height: "40px",
        padding: 0,
      },
    },
  },
};
