import { BankBG } from "../../assets";
import { COLOR } from "../../theme";

export const styles = {
  profileImageStyle: {
    width: 42,
    height: 42,
    borderRadius: 42,
    border: "0.5px solid ",
  },
  inlineCenter: {
    alignItems: "center",
  },
  inlineHover: {
    alignItems: "center",
    ":hover": {
      cursor: "pointer",
    },
  },
  inlineCenterClickable: {
    alignItems: "center",
    cursor: "pointer",
  },

  inlineCenterFullWidth: {
    alignItems: "center",
    width: "100%",
  },
  noDataFoundTextHeading: {
    fontSize: "1.8rem",
    fontWeight: 900,
    color: "#454973",
    textAlign: "center",
  },
  noDataFoundText: {
    fontSize: "0.9rem",
    fontWeight: "500",
    color: "rgba(165, 164, 164, 1)",
    textAlign: "center",
  },
  editButton: {
    color: "#b4aeae",
    border: "1px solid rgba(145, 158, 171, 0.24)",
    borderRadius: "25px",
    fontSize: "14px",
    padding: "10px 15px 10px 15px",
  },
  profileItemText: {
    fontSize: "0.8rem",
    fontWeight: 400,
    color: "#FFFFFF",
  },
  deactivateItemText: {
    fontSize: "0.8rem",
    fontWeight: 500,
    color: COLOR.danger,
  },
  iconBtn: {
    backgroundColor: "#1F1F1F",
    borderRadius: "1em",
    height: "2em",
    minHeight: "2em",
    maxHeight: "2em",
    width: "2em",
    minWidth: "2em",
    maxWidth: "2em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "1em",
  },
  profileCard: {
    height: "45vh",
    backgroundColor: "rgba(12, 12, 12, 1)",
  },
  inputStyle: {
    fontWeight: 300,
    fontSize: "0.9rem",
    color: "#FFFFFF",
  },
  customInputStyle: {
    border: "0.5px solid #383118",
    borderRadius: "8px",
    display: "block",
    width: window.innerWidth * 0.348,
    height: "2.5em",
    fontWeight: 300,
    fontSize: "0.9rem",
    color: "#FFFFFF",
    lineHeight: "2.5em",
    padding: "0px 0.8em",
  },
  placeholderStyle: {
    color: "#f7d96a",
    fontWeight: 300,
    fontSize: "1rem",
  },
  imageChooserText: {
    fontWeight: 300,
    fontSize: "0.6rem",
    cursor: "pointer",
    color: "#FFFFFF",
    marginTop: "5px",
  },
  iconBackground: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "rgba(0, 0, 0, 1)",
    width: "1em",
    height: "1em",
    borderRadius: "1em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    marginLeft: "1em",
  },
  inlineSpaceBetween: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  croppedImageStyle: {
    width: "3.2em",
    height: "3.2em",
    borderRadius: "50px",
    border: "0.5px dashed #FFC018",
    padding: "0.25em",
    backgroundColor: "#000000",
  },
  activeBankName: {
    fontSize: "1em",
    fontWeight: 600,
    color: "#FFFFFF",
  },
  deactiveBankName: {
    fontSize: "1em",
    fontWeight: 600,
    color: "#5A5463",
  },
  maskedBankAccountText: {
    display: "flex",
    alignItems: "center",
    color: "#727272",
    fontSize: "0.9em",
    fontWeight: 400,
  },
  bankOptionContainer: {
    backgroundColor: "#000000",
    padding: "1em 0px",
    borderRadius: "8px",
    width: "100%",
  },
  bankOptionTitle: {
    padding: "0px 3em",
    fontSize: "0.9em",
    fontWeight: 600,
  },
  optionItem: {
    display: "flex",
    alignItems: "center",
    padding: "0.5em 3em",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "rgba(50, 50, 50, 0.1)",
    },
  },
  optionItemText: {
    fontSize: "0.8em",
    fontWeight: 400,
  },
  optionItemTextNoActive: {
    fontSize: "0.8em",
    fontWeight: 400,
    color: "#5A5463",
  },

  optionItemTextDeactive: {
    fontSize: "0.8em",
    fontWeight: 400,
    color: "#FC4747",
  },
  optionItemTextActive: {
    fontSize: "0.8em",
    fontWeight: 400,
    color: "#14CB1B",
  },
  iconBg: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    width: "1.8em",
    height: "1.8em",
    borderRadius: "1.8em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
  },
  bankLogoContainer: {
    display: "flex",
    flexDirection: "row",
    background: `url(${BankBG}) no-repeat`,
    backgroundSize: "cover",
    width: "4em",
    height: "4em",
    borderRadius: "4em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    marginRight: "0.5em",
  },
  bankLogoGlass: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    backdropFilter: "blur(5px)",
    width: "3.5em",
    height: "3.5em",
    borderRadius: "3.5em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
  },
  bankLogoGlassDeactive: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    backdropFilter: "blur(5px)",
    width: "3.5em",
    height: "3.5em",
    borderRadius: "3.5em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
  },
  bankLogoContainerSM: {
    display: "flex",
    flexDirection: "row",
    background: `url(${BankBG}) no-repeat`,
    backgroundSize: "cover",
    width: "3em",
    height: "3em",
    borderRadius: "3em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    marginLeft: "1em",
  },
  bankLogoGlassSM: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    backdropFilter: "blur(5px)",
    width: "2.5em",
    height: "2.5em",
    borderRadius: "2.5em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
  },
  iconStyle: {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    marginRight: "0.5em",
  },
  bankLogoText: {
    fontSize: "1em",
    fontWeight: 700,
    color: "#FFFFFF",
  },
  bankLogoTextDeactive: {
    fontSize: "1em",
    fontWeight: 700,
    color: "#5A5463",
  },
  bankLogoTextSM: {
    fontSize: "0.8em",
    fontWeight: 700,
    color: "#FFFFFF",
  },
  iconBox: {
    padding: "2px 0.5em 0em 0.5em",
  },
  optionActionBtn: {
    width: "7em",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: "5px",
    backgroundColor: "#39393A",
    cursor: "pointer",
  },
  optionBtnText: {
    fontSize: "0.6rem",
    fontWeight: 500,
    height: "100%",
    width: "100%",
    borderRadius: "0px 5px 5px 0px",
    padding: "0.5em",
    backgroundColor: "#474748",
    color: "#FFFFFF",
  },
  optionBtnTextDisable: {
    fontSize: "0.6rem",
    fontWeight: 500,
    height: "100%",
    width: "100%",
    borderRadius: "0px 5px 5px 0px",
    padding: "0.5em",
    backgroundColor: "#474748",
    color: "#5A5463",
  },
  profilePictureHover: {
    cursor: "pointer",
    alignItems: "center",
  },
  aboutText: {
    fontSize: "0.8rem",
    fontWeight: 500,
    color: "#FFFFFF",
  },
  hoverPointer: {
    cursor: "pointer",
  },
};
