import axiosInstance from "./config";
import { RegisterRequest } from "../types/RegisterModel";
import {
  ActivateAccountModelRequest,
  ActivateAccountResponse,
  ActivateCipherResponse,
  ActivateEmailResendRequest,
} from "../types/ActivateAccountModel";
import {
  DisableTwoFARequest,
  EnableTwoFARequest,
  VerifyTwoFARequest,
} from "../types/TwoStepVerificationModel";
import { BankDetailRequest } from "../types/BankDetailsModel";
import {
  ForgetPasswordRequest,
  ResetPasswordRequest,
} from "../types/ForgetPasswordModel";
import { VerifyEmailRequest } from "../types/VerifyEmailModel";
import {
  TransferFeesModel,
  TransferTokenModel,
} from "../types/TransferTokenModel";
import { UpdateProfileRequest } from "../types/UpdateProfile";
import { UpdatePasswordRequest } from "../types/UpdatePasswordModel";
import {
  ContactUsRequest,
  ContactUsUserRequest,
} from "../types/ContactUsModel";
import { RedeemRequestModel } from "../types/RedeemModel";
import { ProfileImageUploadRequest } from "../types/ProfileImageModel";
import { SellTokenRequest } from "../types/SellTokenModel";
import { BuyTokenRequest } from "../types/BuyTokenModel";
import { BankStatusRequest } from "../types/BankDetailsModel";
import { apiDecrypt } from "../utils/Encryption";

export const requestRegisterApi = async (registerRequest: RegisterRequest) => {
  try {
    const response = await axiosInstance.post("/register", registerRequest);
    return response;
  } catch (error) {
    console.log("Error log: ", { error });
    throw new Error("Registration failed. Please check your credentials.");
  }
};

export const requestActivateAccount = async (
  activateAccountModel: ActivateAccountModelRequest
) => {
  try {
    const response = await axiosInstance.post(
      "/activate-account",
      activateAccountModel
    );
    if (response?.data && response?.data?.dataCipher) {
      const data: ActivateCipherResponse = response.data;
      const plainText = JSON.parse(apiDecrypt(data.dataCipher));
      return plainText;
    } else {
      if (response?.data && response?.data?.dataCipher) {
        const plainText = JSON.parse(apiDecrypt(response.data.dataCipher));
        if (plainText) {
          return plainText.message;
        }
      } else {
        return response.data;
      }
    }
  } catch (error) {
    throw new Error("Verify email failed. Please check your credentials.");
  }
};

export const requestActivationEmail = async (
  request: ActivateEmailResendRequest
) => {
  try {
    const response = await axiosInstance.post(
      "/request-activation-email",
      request
    );
    return response;
  } catch (error) {
    throw new Error("Profile update failed. Please check your credentials.");
  }
};

export const requestVerifyTwoFa = async (
  verifyTwoFaRequest: VerifyTwoFARequest
) => {
  try {
    const response = await axiosInstance.post(
      "/verify-2fa",
      verifyTwoFaRequest
    );
    return response;
  } catch (error) {
    throw new Error("Registration failed. Please check your credentials.");
  }
};

export const setupTwoFA = async () => {
  try {
    const response = await axiosInstance.get("/setup-2fa");
    return response;
  } catch (error) {
    throw new Error("setupTwoFA failed. Please check your credentials.");
  }
};

export const verifyTwoFa = async (verifyTwoFaRequest: VerifyTwoFARequest) => {
  try {
    const response = await axiosInstance.post(
      "/verify-2fa",
      verifyTwoFaRequest
    );
    return response;
  } catch (error) {
    throw new Error("Registration failed. Please check your credentials.");
  }
};

export const enableTwoFa = async (enableTwoFARequest: EnableTwoFARequest) => {
  try {
    const response = await axiosInstance.patch(
      "/enable-2fa",
      enableTwoFARequest
    );
    return response;
  } catch (error) {
    throw new Error("Registration failed. Please check your credentials.");
  }
};

export const disableTwoFa = async (
  disableTwoFARequest: DisableTwoFARequest
) => {
  try {
    const response = await axiosInstance.patch(
      "/disable-2fa",
      disableTwoFARequest
    );
    return response;
  } catch (error) {
    throw new Error("Registration failed. Please check your credentials.");
  }
};

export const verifyEmail = async (verifyEmailRequest: VerifyEmailRequest) => {
  try {
    const response = await axiosInstance.post(
      "/verify-email",
      verifyEmailRequest
    );
    return response;
  } catch (error) {
    throw new Error("Verify email failed. Please check your credentials.");
  }
};

export const requestTransferToken = async (
  transferTokenRequest: TransferTokenModel
) => {
  try {
    const response = await axiosInstance.post(
      "/transfer-token",
      transferTokenRequest
    );
    return response;
  } catch (error) {}
};

export const updateProfile = async (profileRequest: UpdateProfileRequest) => {
  try {
    const response = await axiosInstance.patch("/users", profileRequest);
    return response;
  } catch (error) {
    throw new Error("Profile update failed. Please check your credentials.");
  }
};

export const contactUs = async (contactUsRequest: ContactUsRequest) => {
  try {
    const response = await axiosInstance.post("/support", contactUsRequest);
    return response;
  } catch (error) {
    throw new Error("Profile update failed. Please check your credentials.");
  }
};

export const addBankAccountRequest = async (bankDetails: BankDetailRequest) => {
  try {
    const response = await axiosInstance.post("/bank-details", bankDetails);
    return response;
  } catch (error) {
    throw new Error("Profile update failed. Please check your credentials.");
  }
};

export const requestForgetPassword = async (request: ForgetPasswordRequest) => {
  try {
    const response = await axiosInstance.post(
      "/request-reset-password",
      request
    );
    return response;
  } catch (error) {
    throw new Error("Profile update failed. Please check your credentials.");
  }
};

export const requestResetPassword = async (request: ResetPasswordRequest) => {
  try {
    const response = await axiosInstance.post("/reset-password", request);
    return response;
  } catch (error) {
    throw new Error("Profile update failed. Please check your credentials.");
  }
};

export const requestDisableBiometric = async () => {
  try {
    const response = await axiosInstance.delete("/biometric");
    return response;
  } catch (error) {
    throw new Error("Profile update failed. Please check your credentials.");
  }
};

export const requestRedeemToken = async (request: RedeemRequestModel) => {
  try {
    const response = await axiosInstance.post("/redeem-token", request);
    return response;
  } catch (error) {
    throw new Error("Redeem token failed. Please check your credentials.");
  }
};

export const requestSetAsPrimaryBank = async (bankId: number) => {
  try {
    const response = await axiosInstance.patch(
      `/set-primary-account/${bankId}`
    );
    return response;
  } catch (error) {
    throw new Error(
      "Setting bank as primary bank failed. Please check your credentials."
    );
  }
};

export const uploadProfileImage = async (
  request: ProfileImageUploadRequest
) => {
  try {
    const response = await axiosInstance.post("/profile-image", request);
    return response;
  } catch (error) {
    throw new Error(
      "Setting bank as primary bank failed. Please check your credentials."
    );
  }
};

export const requestTokenSell = async (request: SellTokenRequest) => {
  try {
    const response = await axiosInstance.post("/sell-token", request);
    return response;
  } catch (error) {
    throw new Error(
      "Request token sell failed. Please check your credentials."
    );
  }
};

export const requestBuyToken = async (request: BuyTokenRequest) => {
  try {
    const response = await axiosInstance.post("/buy-token", request);
    return response;
  } catch (error) {
    throw new Error(
      "Request token sell failed. Please check your credentials."
    );
  }
};

export const requestGetTransactionDetails = async (id: number) => {
  try {
    const response = await axiosInstance.get(`/transactions/${id}`);
    return response;
  } catch (error) {
    throw new Error(
      "Request token sell failed. Please check your credentials."
    );
  }
};

export const requestStripeSession = async () => {
  try {
    const response = await axiosInstance.post("/stripe-session");
    return response;
  } catch (error) {
    throw new Error(
      "Request token sell failed. Please check your credentials."
    );
  }
};

export const requestUpdateBankDetailStatus = async (
  bankId: number,
  request: BankStatusRequest
) => {
  try {
    const response = await axiosInstance.patch(
      `/bank-details-status/${bankId}`,
      request
    );
    return response;
  } catch (error) {
    throw new Error(
      "Request token sell failed. Please check your credentials."
    );
  }
};

export const requestUpdatePassword = async (
  updatePasswordRequest: UpdatePasswordRequest
) => {
  try {
    const response = await axiosInstance.post(
      "/update-password",
      updatePasswordRequest
    );
    return response;
  } catch (error) {
    throw new Error("Profile update failed. Please check your credentials.");
  }
};

export const requestContactUsUser = async (request: ContactUsUserRequest) => {
  try {
    const response = await axiosInstance.post(`/contact-webform`, request);
    return response;
  } catch (error) {
    throw new Error(
      "Request token sell failed. Please check your credentials."
    );
  }
};

export const requestTransferFees = async (request: TransferFeesModel) => {
  try {
    const response = await axiosInstance.post(`/transfer-fees`, request);
    return response;
  } catch (error) {
    throw new Error(
      "Request token sell failed. Please check your credentials."
    );
  }
};

export const requestAccountDeletion = async () => {
  try {
    const response = await axiosInstance.post(`/account-deletion`);
    return response;
  } catch (error) {
    throw new Error(
      "Request account deletion failed. Please check your credentials."
    );
  }
};
