import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import { styled, useTheme } from "@mui/material/styles";

type Props = {
  title: string;
  onClick?: () => void;
  type?: "button" | "submit" | "reset" | undefined;
};

const DashboardBuyButton = (props: Props) => {
  const { title, onClick, type = "submit" } = props;
  const theme = useTheme(); // Access the theme object

  return (
    <GradientButton
      type={type}
      startIcon={
        <AddIcon
          fontSize="large"
          sx={{ color: "black", fontWeight: "bold" }}
        />
      }
      onClick={onClick}
      theme={theme} // Pass theme to styled component
    >
      {title}
    </GradientButton>
  );
};
export default DashboardBuyButton;

const GradientButton = styled(Button)(({ theme }) => ({
  background:
    "linear-gradient(to right, rgba(255, 246, 1, 1), rgba(241, 178, 15, 1))",
  color: "#000000",
  border: "none",
  borderRadius: "30px",
  padding: "0.25em 1em",
  textTransform: "none",
  fontSize: "0.8rem",
  fontWeight: "800",
  boxShadow: "none",
  transition: "background 0.3s ease",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "auto",
  height: "40px",
  textAlign: "center",
  [theme.breakpoints.down('sm')]: { // Adjustments for small screens
    padding: "0.2em 0.6em",
    height: "35px",
    fontSize: "0.7rem",
  },
  [theme.breakpoints.up('md')]: { // Adjustments for medium and up screens
    padding: "0.25em 1em",
    height: "40px",
    fontSize: "0.8rem",
  },
  "&:hover": {
    background:
      "linear-gradient(to right, rgba(241, 178, 15, 1), rgba(255, 246, 1, 1))",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
  "&:active": {
    boxShadow: "inset 0px 2px 4px rgba(0, 0, 0, 0.2)",
  },
  "&:disabled": {
    background:
      "linear-gradient(to right, rgba(255, 246, 1, 0.5), rgba(241, 178, 15, 0.5))",
    color: "#000000",
  },
}));
