import { BankBG } from "../../assets";
import { COLOR } from "../../theme";

export const styles = {
  holdingCardBg: {
    background:
      "radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(217, 154, 38, 0.2) 0%, rgba(249, 250, 250, 0.0447917) 77.08%, rgba(181, 181, 181, 0) 100%)",
    color: "#fff",
    border: "1px solid rgba(135,123,61,1)",
    borderImage:
      "radial-gradient(80.38% 222.5% at -13.75% -12.36%, #877B3D 0%, rgba(255, 255, 255, 0) 100%),radial-gradient(80.69% 208.78% at 108.28% 112.58%, #FFF1BF 0%, rgba(183, 142, 38, 0) 100%)",
    height: "100%",
  },
  flexCenterSpaceBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  aegBalanceHoldingValue: {
    fontSize: "1.8rem",
    fontWeight: 700,
  },
  aegHoldingText: {
    fontSize: "1rem",
    fontWeight: 600,
  },
  inlineCenter: {
    alignItems: "center",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  activeBankName: {
    fontSize: "1em",
    fontWeight: 600,
    color: "#FFFFFF",
  },
  primaryBankAccountText: {
    fontSize: "0.9em",
    fontWeight: 400,
    color: "#FFFFFF",
  },
  maskedBankAccountText: {
    display: "flex",
    alignItems: "center",
    color: "#727272",
    fontSize: "0.9em",
    fontWeight: 400,
  },
  actionContainer: {
    backgroundColor: COLOR.background,
    color: "#fff",
    borderRadius: 2,
    height: "100%",
  },
  actionContent: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    height: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "1em 0.5em",
  },
  actionItem: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    padding: "10px 0.5em",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.3)",
      transition: "all 0.3s ease-in-out",
    },
  },
  noDataFoundTextHeading: {
    fontSize: "1.8rem",
    fontWeight: 900,
    color: "#454973",
    textAlign: "center",
  },
  actionItemIconContainer: {
    width: "36px",
    height: "36px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    border: "1px solid #5c5858",
    backgroundColor: "#94918c2e",
  },
  trendingText: {
    fontSize: "0.8rem",
    display: "flex",
    alignItems: "center",
    borderRadius: "15px",
    backgroundColor: "#0A0A0A",
    padding: "5px 15px 5px 15px",
    marginLeft: "10px",
  },
  aegHoldingAEDText: {
    fontSize: "0.9rem",
    fontWeight: 400,
  },
  aegBalanceText: {
    fontSize: "0.9rem",
    fontWeight: 500,
    color: COLOR.primary,
  },
  holdingAEDStrip: {
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    marginTop: "1em",
    padding: "10px",
  },
  noDataFoundText: {
    fontSize: "0.9rem",
    fontWeight: "500",
    color: "rgba(165, 164, 164, 1)",
    textAlign: "center",
  },
  bankLogoContainer: {
    display: "flex",
    flexDirection: "row",
    background: `url(${BankBG}) no-repeat`,
    backgroundSize: "cover",
    width: "4em",
    height: "4em",
    borderRadius: "4em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    marginRight: "0.5em",
  },
  bankLogoGlass: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    backdropFilter: "blur(5px)",
    width: "3.5em",
    height: "3.5em",
    borderRadius: "3.5em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
  },
  bankLogoText: {
    fontSize: "1em",
    fontWeight: 700,
    color: "#FFFFFF",
  },
};
