import {
  Box,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { LogoIcon } from "../../assets";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

interface QueryParams {
  buyOrderId?: string;
  transactionId?: string;
  session_id?: string;
}

const GatewaySuccess = () => {
  const location = useLocation();
  const [status, setStatus] = useState("");
  const [params, setParams] = useState<QueryParams>();
  useEffect(() => {
    handleRedirection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRedirection = () => {
    const query = new URLSearchParams(location.search);
    const path = location.pathname;
    console.log("handle redirection: ", path);
    const statusText = path.split("/");
    console.log("status: ", statusText.length);

    if (statusText[statusText.length - 1].includes("success")) {
      setStatus("Success");
    } else {
      setStatus("Failed");
    }

    const paramsObj: QueryParams = {};
    query.forEach((value, key) => {
      paramsObj[key as keyof QueryParams] = value;
    });
    setParams(paramsObj);

    setTimeout(() => {
      window.location.replace(
        `/dashboard/transactions?transactionId=${paramsObj.transactionId}`
      );
    }, 2000);
  };

  return (
    <Stack
      direction={"row"}
      sx={{
        alignItems: "center",
        flex: 1,
        width: "100%",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <Stack
        direction={"column"}
        gap={3}
        p={3}
        sx={{ width: "40%", height: "100vh", alignItems: "center" }}
      >
        <Stack direction={"row"} sx={{ alignItems: "center" }} gap={1}>
          <LogoIcon width={60} height={40} />
          <Typography sx={styles.titleText}>AEG Wallet</Typography>
        </Stack>
        <Typography sx={styles.heading1}>Payment Gatway</Typography>
        {status === "Success" ? (
          <Stack direction={"column"} gap={2}>
            <Typography sx={styles.successText}>
              Payment Confirmation
            </Typography>
            <Typography sx={styles.heading2}>
              Thank You for Your Purchase!
            </Typography>
            <Typography sx={styles.desc}>
              We are pleased to inform you that your payment has been
              successfully processed. Below are the details of your transaction:
            </Typography>
          </Stack>
        ) : (
          <Stack direction={"column"}>
            <Typography sx={styles.failedText}>Payment Failed</Typography>
            <Typography sx={styles.heading2} mt={2}>
              Oops! Something Went Wrong
            </Typography>
            <Typography sx={styles.desc}>
              Unfortunately, we were unable to process your payment. Please
              review the details below:
            </Typography>
          </Stack>
        )}
        <Box sx={styles.detailsCard}>
          <Typography sx={styles.heading2} mb={0.5}>
            Payment Tranaction Details
          </Typography>
          <Divider />
          <TableContainer>
            <Table
              sx={{
                "td,th": {
                  borderBottom: "0px",
                  padding: "3px 5px",
                },
                marginTop: "1em",
              }}
            >
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography sx={styles.heading3}>Tranaction Id </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography sx={styles.value}>
                      {params?.transactionId ? params.transactionId : ""}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography sx={styles.heading3}>Order Id </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography sx={styles.value}>
                      {params?.transactionId ? params.buyOrderId : ""}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography sx={styles.heading3}>Status </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography sx={styles.value}>{status}</Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Typography sx={styles.pleasewait}>
          Please wait! The page doesn't redirect on its own, please click the
          button below to continue.
        </Typography>
        <Box sx={{ width: "40%" }}>
          <PrimaryButton title="Redirect" onClick={handleRedirection} />
        </Box>
      </Stack>
    </Stack>
  );
};
export default GatewaySuccess;
const styles = {
  titleText: {
    fontSize: "1.5rem",
    fontWeight: 600,
    color: "#FFC800",
  },
  heading1: {
    fontSize: "1rem",
    fontWeight: 600,
    color: "#FFC800",
  },
  failedText: {
    fontSize: "1rem",
    fontWeight: 600,
    color: "#EE2E31",
  },
  successText: {
    fontSize: "1rem",
    fontWeight: 600,
    color: "#30C535",
  },
  heading2: {
    fontSize: "0.9rem",
    fontWeight: 400,
    color: "#FFFFFF",
  },
  heading3: {
    fontSize: "0.9rem",
    fontWeight: 400,
    color: "#FFFFFF",
  },
  value: {
    fontSize: "0.9rem",
    fontWeight: 300,
    color: "#FFFFFF",
    textAligh: "right",
  },
  desc: {
    fontSize: "0.8rem",
    fontWeight: 400,
    color: "#FFFFFF",
  },
  pleasewait: {
    fontSize: "0.8rem",
    fontWeight: 400,
    color: "#FFFFFF",
    textAlign: "center",
    width: "80%",
  },
  detailsCard: {
    backgroundColor: "#191B24",
    borderRadius: "0.2em",
    padding: "1em",
    width: "100%",
    margin: "1em 20em",
  },
};
