import React from "react";
type Props = {
  width?: string;
  height?: string;
  color?: string;
};
const ActiveAccountIcon = (props: Props) => {
  const { width = "0.9em", height = "0.9em", color = "#14CB1B" } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none">
      <path
        d="M22.4851 5.51472C27.1714 10.201 27.1714 17.799 22.4851 22.4853C17.7988 27.1716 10.2009 27.1716 5.51458 22.4853C0.828287 17.799 0.828287 10.201 5.51458 5.51472C10.2009 0.828427 17.7988 0.828427 22.4851 5.51472Z"
        stroke={color}
        stroke-width="4"
      />
    </svg>
  );
};
export default ActiveAccountIcon;
