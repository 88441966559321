import React, { useState } from "react";
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Typography,
} from "@mui/material";
import Iconify from "./icons/Iconify";
import HomeIcon from "../assets/svg/HomeIcon";
import WalletIcon from "../assets/svg/WalletIcon";
import SettingsIcon from "../assets/svg/SettingsIcon";
import SupportIcon from "../assets/svg/SupportIcon";
import TransactionsIcon from "../assets/svg/TransactionsIcon";
import LogoutIcon from "../assets/svg/LogoutIcon";
import LogoutDialog from "./LogoutDialog";
import { useDispatch } from "react-redux";

const ListItemStyle = styled((props: any) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  height: 40,
  fontWeight: 400,
  fontSize: "0.95rem",
  position: "relative",
  color: "#FFFFFF",
  padding: "0.5em 5px",
  margin: "1.2em 0.5em",
  transition: "all .35s ease-in-out",
  "&:hover": {
    fontWeight: 500,
    color: "rgba(255, 200, 0, 1)",
    backgroundColor: "transparent",
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: "inherit",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const styles = {
  sideBarContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  activeRootStyle: {
    color: "rgba(255, 200, 0, 1)",
    fontWeight: 400,
  },
  activeSubStyle: {
    color: "#FFC800",
    fontWeight: 400,
  },
  verticalIndicator: {
    display: "block",
    backgroundColor: "rgba(255, 200, 0, 1)",
    width: "4px",
    height: "100%",
    borderRadius: "5px",
  },
  hideIndicator: {
    backgroundColor: "rgba(0, 0, 0, 1)",
    width: "4px",
    height: "100%",
    borderRadius: "5px",
  },
  logoutText: {
    fontWeight: 400,
    fontSize: "0.95rem",
    "&:hover": {
      fontWeight: 500,
      color: "rgba(255, 200, 0, 1)",
    },
  },
  logoutContainerStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 40,
    padding: "0.5em 5px",
    cursor: "pointer",
    margin: "1.2em 0.5em 0.2em 0.5em",
    transition: "all .35s ease-in-out",
    "&:hover": {
      color: "rgba(255, 200, 0, 1)",
      backgroundColor: "transparent",
    },
  },
  versionText: {
    margin: "0em 2em",
    fontSize: "0.7rem",
    fontWeight: 400,
    color: "#c0c1c0",
    textAlign: "right",
  },
};

const NavItem = ({ item, active }: { item: any; active: any }) => {
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState<boolean>(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && styles.activeRootStyle),
            transition: (theme: any) => theme.transitions.create("transform"),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText sx={{ fontWeight: 400 }} primary={title} />
          {info && info}
          <Iconify
            icon={
              open
                ? "eva:arrow-ios-downward-fill"
                : "eva:arrow-ios-forward-fill"
            }
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item: any) => {
              const { title, path } = item;
              const isActiveSub = active(path);
              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && styles.activeSubStyle),
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: "flex",
                        borderRadius: "50%",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "text.disabled",
                        transition: (theme) =>
                          theme.transitions.create("transform"),
                        ...(isActiveSub && {
                          transform: "scale(2)",
                          backgroundColor: "primary.main_active",
                        }),
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText
                    disableTypography
                    primary={title}
                    sx={{
                      transition: (theme) =>
                        theme.transitions.create("transform"),
                      ...(isActiveSub && {
                        color: "primary.text.primary",
                      }),
                    }}
                  />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && styles.activeRootStyle),
      }}
    >
      {isActiveRoot ? <Box sx={styles.verticalIndicator} /> : <></>}
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  );
};

const NavSection = ({ ...other }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);
  const navConfigArray = [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: <HomeIcon />,
    },
    {
      title: "Wallet",
      path: "/dashboard/wallet",
      icon: <WalletIcon />,
    },
    {
      title: "Transactions",
      path: "/dashboard/transactions",
      icon: <TransactionsIcon />,
    },
    {
      title: "Support",
      path: "/dashboard/support",
      icon: <SupportIcon />,
    },
    {
      title: "Settings",
      path: "/dashboard/settings",
      icon: <SettingsIcon />,
    },
  ];

  const match = (path: any) =>
    path ? !!matchPath({ path, end: true }, pathname) : false;

  const performLogout = () => {
    //show confirmation dialog before logging out of application
    localStorage.clear();
    dispatch({ type: "LOGOUT_REQUEST" });
    window.location.replace("/auth/login");
  };

  return (
    <Box {...other}>
      <List disablePadding sx={styles.sideBarContainer}>
        <Box>
          {navConfigArray.map((item) => (
            <NavItem key={item.title} item={item} active={match} />
          ))}
        </Box>
        <Box
          sx={styles.logoutContainerStyle}
          onClick={() => setShowLogoutDialog(true)}
        >
          <Box sx={styles.hideIndicator} />
          <LogoutIcon />
          <Typography sx={styles.logoutText}>Logout</Typography>
        </Box>
      </List>
      {showLogoutDialog ? (
        <LogoutDialog
          open={showLogoutDialog}
          handleClose={() => setShowLogoutDialog(false)}
          handleLogout={performLogout}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};
export default NavSection;
