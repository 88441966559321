import { useEffect, useRef, useState } from "react";
import Navbar from "../../components/Navbar";
import AlertComponent from "../../components/AlertComponent";
import {
  Box,
  Button,
  Typography,
  Grid,
  Paper,
  Container,
  TextField,
} from "@mui/material";
import { items } from "./CardData";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import ChartWidget from "../Chart/ChartWidget";
import { Parallax } from "react-parallax";
import { Workspace } from "../../assets";
import { AlertDataProps } from "../../types/Common";
import { Form, FormikProvider, useFormik } from "formik";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ReCaptchaComponent from "../login/ReCaptchaComponent";
import { ContactUsFormSchema } from "../../validations/Validations";
import { ContactUsUserRequest } from "../../types/ContactUsModel";
import { requestContactUsUser } from "../../api/api";
import Loader from "../../components/Loader";

const HomePage = () => {
  const navigate = useNavigate();
  const [alertData, setAlertData] = useState<AlertDataProps>({
    message: "",
    type: "error",
  });
  const [loading, setLoading] = useState(false);
  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const [token, setToken] = useState<string | null>(null);
  const containerRef = useRef<any>(null);

  // Function to scroll to the container
  const scrollToAbout = () => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (token !== null) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const submitContactUs = async (body: ContactUsUserRequest) => {
    try {
      const response = await requestContactUsUser(body);
      setLoading(false);
      if (response) {
        if (response.status === 200) {
          setAlertData({
            message: response.data.message,
            type: "success",
          });
          resetForm();
          setToken(null);
        } else {
          setAlertData({
            message: response.data.message,
            type: "error",
          });
          resetForm();
          setToken(null);
        }
        console.log("Response: ", response.status);
      }
    } catch (error) {
      resetForm();
      setToken(null);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      description: "",
    },
    validationSchema: ContactUsFormSchema,
    onSubmit: (value) => {
      if (token !== null) {
        const body: ContactUsUserRequest = {
          userEmail: value.email,
          description: value.description,
          recaptchaToken: token,
        };
        submitContactUs(body);
        setLoading(true);
        //dispatch(loginRequest(body));
      }
    },
  });
  const { errors, touched, handleSubmit, getFieldProps, resetForm } = formik;
  const handleLoginClick = () => {
    navigate("/auth/login");
  };

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage] = useState("");

  return (
    <div style={{ backgroundColor: "black" }}>
      <Navbar scrollToAbout={scrollToAbout} />
      {/* Hero section */}
      <AlertComponent
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        message={alertMessage}
      />
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          padding: { xs: "20px", md: "100px" },
          gap: "20px",
          backgroundColor: "black",
          color: "white",
          textAlign: "center",
        }}
      >
        <Typography
          variant="h5"
          component="div"
          sx={{
            mb: 2,
            mt: 5,
            fontSize: { xs: "16px", md: "20px" },
            color: "#F1B20F",
          }}
        >
          TRANSPARENT, AND VALUE-BACKED
        </Typography>
        <Typography
          variant="h2"
          component="div"
          sx={{
            mb: 4,
            fontSize: { xs: "24px", md: "52px" },
            padding: { xs: "0px 20px", md: "0px 100px" },
          }}
        >
          Experience the Future of Digital Gold Ownership with AEG
        </Typography>
        <Typography
          variant="body1"
          component="div"
          sx={{
            mb: 4,
            fontWeight: "bold",
            padding: { xs: "0px 20px", md: "0px 100px" },
          }}
        >
          Introducing AEG (Arab Emirates Gold), a gold-pegged digital asset that
          revolutionizes the way you own and invest in gold. AEG is exclusively
          acquired through Dirham (Arab Emirates Dirham) fiat currency, ensuring a
          secure and transparent transaction process.
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 2,
            mb: 5,
          }}
        >
          <Button
            onClick={handleLoginClick}
            variant="contained"
            style={{
              backgroundColor: "#F1B20F",
              color: "black",
              borderRadius: "25px",
              padding: "10px 30px",
            }}
          >
            Buy AEG
          </Button>
          <Button
            variant="outlined"
            onClick={scrollToAbout}
            style={{
              borderColor: "white",
              color: "white",
              borderRadius: "25px",
              padding: "10px 30px",
            }}
          >
            Learn More
          </Button>
        </Box>
      </Container>
      {/* featured Section */}

      {/* PriceSection */}
      <Box
        sx={{
          backgroundColor: "black",
          color: "white",
          padding: { xs: "20px", md: "40px" },
          borderRadius: "10px",
          margin: "20px",
          textAlign: "center",
        }}
      >
        <Typography variant="h3" component="div" sx={{ mb: 2 }}>
          GOLD GLOBAL INDEX
        </Typography>
      </Box>

      <Container sx={{ position: "relative" }}>
        <ChartWidget height={500} page="home" />
      </Container>

      {/* AEG DIFFERENT Section */}
      <Container
        sx={{
          backgroundColor: "black",
          color: "white",
          padding: { xs: "20px", md: "40px" },
        }}
      >
        <Typography
          variant="h3"
          component="div"
          sx={{
            textAlign: "center",
            mb: 4,
            padding: { xs: "20px", md: "40px" },
            fontSize: { xs: "24px", md: "32px" },
          }}
        >
          WHAT MAKES AEG DIFFERENT
        </Typography>
        <Grid container spacing={4}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Paper
                sx={{
                  backgroundColor: "#1c1c1c",
                  padding: "20px",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "40px",
                    alignItems: "center",
                    justifyContent: "left",
                    p: "10px 25px",
                    mb: 2,
                    borderRadius: "10px",
                    bgcolor: "#f1b20f2e",
                  }}
                >
                  {item.icon}
                </Box>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    marginLeft: "10px",
                    color: "white",
                    fontWeight: "bold",
                    mb: 2,
                  }}
                >
                  {item.title}
                </Typography>
                <Typography
                  variant="body1"
                  component="div"
                  sx={{
                    marginLeft: "10px",
                    color: "white",
                    fontFamily: "system-ui",
                    fontWeight: "100",
                    fontSize: "16px",
                  }}
                >
                  {item.description}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Container sx={{}}>
        <Parallax
          bgImage={Workspace}
          strength={200}
          style={{ borderRadius: "8px", display: "block" }}
          renderLayer={(percentage) => (
            <div
              style={{
                position: "absolute",
                background: `rgba(0, 0, 0, 0.7)`,
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
              }}
            />
          )}
        >
          <Grid container>
            <Grid item md={5} sm={6} xs={0}>
              <Box sx={styles.contactUsInfo}>
                <Typography sx={styles.contactUsTitle}>Contact Us</Typography>
                <Typography sx={styles.contactUsSubtitle}>
                  If you have any queries or suggestions, please feel free to
                  contact us.
                </Typography>
                <Typography sx={styles.contactUsSubtitle}>
                  Send us a message with your email address, and we will contact
                  you soon regarding your queries or suggestions.
                </Typography>
              </Box>
            </Grid>
            <Grid item md={7} sm={6} xs={12}>
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <Box sx={styles.contactUsFormInput}>
                    <label style={styles.contactUsLabel}>Email Address</label>
                    <TextField
                      size="small"
                      variant="outlined"
                      inputProps={{ style: styles.inputStyle }}
                      sx={styles.inputSx}
                      {...getFieldProps("email")}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />

                    <label style={styles.contactUsLabel}>Description</label>
                    <TextField
                      multiline
                      minRows={3}
                      size="small"
                      inputProps={{ style: styles.inputStyle, maxLength: 255 }}
                      sx={styles.inputSx}
                      {...getFieldProps("description")}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                    />
                    {loading ? (
                      <Loader />
                    ) : (
                      <GoogleReCaptchaProvider
                        useRecaptchaNet
                        reCaptchaKey={siteKey as string}
                        scriptProps={{
                          async: true,
                          defer: true,
                          appendTo: "body",
                        }}
                      >
                        <ReCaptchaComponent
                          title="Send"
                          onSuccess={(token) => {
                            if (token) {
                              setToken(token);
                            }
                          }}
                          onError={(error) => {
                            if (error) {
                              setAlertData({ message: error, type: "error" });
                            }
                          }}
                        />
                      </GoogleReCaptchaProvider>
                    )}
                  </Box>
                </Form>
              </FormikProvider>
            </Grid>
          </Grid>
        </Parallax>
      </Container>
      {/* UNLOCK THE POWER Section */}
      <Container
        ref={containerRef}
        sx={{
          backgroundColor: "black",
          color: "white",
          padding: { xs: "20px", md: "100px" },
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="subtitle1"
              sx={{
                color: "#F1B20F",
                marginBottom: "10px",
                fontWeight: "normal",
              }}
            >
              UNLOCK THE POWER OF GOLD WITH AEG
            </Typography>
            <Typography
              variant="h2"
              sx={{
                marginBottom: "20px",
                fontSize: { xs: "24px", md: "48px" },
              }}
            >
              The Future of Digital Asset Stability
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontFamily: "system-ui",
                fontWeight: "100",
                fontSize: "17px",
              }}
            >
              Experience a groundbreaking revolution in digital asset ownership
              with AEG, the first-ever gold pegged digital
              gold. By combining the timeless value of gold with the convenience
              and accessibility of digital currency, AEG offers a secure and
              stable investment opportunity like no other.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="./static/images/gold-bar.png"
              alt="Gold Bars"
              style={{ maxWidth: "60%" }}
            />
          </Grid>
        </Grid>
      </Container>

      {/*  */}
      <Container
        sx={{
          backgroundColor: "black",
          color: "white",
          padding: { xs: "20px", md: "50px" },
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="./static/images/coin.png"
              alt="Gold coin"
              style={{ maxWidth: "80%", height: "60%" }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="subtitle1"
              sx={{
                color: "#F1B20F",
                marginBottom: "10px",
                fontWeight: "normal",
              }}
            >
              EMPOWERING DIGITAL GOLD INVESTORS
            </Typography>
            <Typography
              variant="h2"
              sx={{
                marginBottom: "20px",
                fontSize: { xs: "24px", md: "3rem" },
              }}
            >
              AEG Redefines Precious Metal Ownership
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontFamily: "system-ui",
                fontWeight: "100",
                fontSize: "17px",
              }}
            >
              Discover a new era of digital gold ownership with AEG, a pioneering gold pegged digital gold that
              revolutionizes the way you invest in precious metals. Designed to
              provide security, accessibility, and transparency, AEG empowers
              investors to tap into the potential of gold in the digital age.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: { xs: "flex", md: "none" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="./static/images/coin.png"
              alt="Gold coin"
              style={{ maxWidth: "80%", height: "60%" }}
            />
          </Grid>
        </Grid>
      </Container>

      {/* CTA Section */}
      <Container
        sx={{
          backgroundColor: "#F1B20F",
          padding: { xs: "20px", md: "40px" },
          borderRadius: "10px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={9}>
            <Typography
              component="h3"
              sx={{
                fontSize: { xs: "20px", md: "30px" },
                color: "black",
                fontWeight: "900",
                pl: { xs: "20px", md: "40px" },
              }}
            >
              THE FUTURE OF DIGITAL GOLD OWNERSHIP
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "flex-end" },
              alignItems: "center",
            }}
          >
            <Button
              variant="outlined"
              onClick={handleLoginClick}
              style={{
                color: "white",
                backgroundColor: "black",
                borderRadius: "25px",
                padding: "10px 30px",
              }}
            >
              Buy Now
            </Button>
          </Grid>
        </Grid>
      </Container>

      {/* footer section */}
      <Footer scrollToAbout={scrollToAbout} />
      <AlertComponent
        open={alertData.message !== "" ? true : false}
        onClose={() => setAlertData({ message: "", type: "error" })}
        message={alertData.message}
        type={alertData.type ? alertData.type : "error"}
      />
    </div>
  );
};

export default HomePage;

const styles = {
  contactUsFormContainer: {
    flex: 3,
    height: "100%",
    width: window.innerWidth * 0.4,
    clipPath: "circle(100% at 100% 50%);",
    backgroundColor: "rgba(0,0,0,0.15)",
    boxShadow: "inset 48px 0px 100px -90px rgba(0,0,0,1)",
    padding: { xs: "10px", md: "20px" },
  },
  contactUsFormInput: {
    display: "flex",
    flexDirection: "column",
    padding: "3em",
    backgroundColor: "rgba(10,10,0,0.75)",
  },
  contactUsLabel: {
    fontSize: "0.9rem",
    fontWeight: 500,
    marginBottom: "8px",
    color: "#FFFFFF",
  },
  inputStyle: {
    fontSize: "0.9rem",
    fontWeight: 500,
    color: "#FFFFFF",
    "& .MuiOutlinedInput-root": {
      width: "60px", // Set width and height to ensure square aspect ratio
      minHeight: "60px",
      padding: 0, // Remove default padding
      display: "flex",
      alignItems: "center",
      outline: "#FFFFFF",
      border: `0.5px solid #FFFFFF`,
      "& fieldset": {
        border: `0.5px solid #FFFFFF`,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: `0.5px solid #FFFFFF`,
      },
      "& .MuiInputBase-input": {
        textAlign: "center", // Center the input text
        height: "60px",
        padding: 0,
      },
    },
  },
  inputSx: {
    marginBottom: "1em",
  },
  contactUsInfo: {
    padding: "3em",
  },
  contactUsTitle: {
    fontSize: "1.5rem",
    fontWeight: 600,
    color: "#FFFFFF",
    marginBottom: "1em",
  },
  contactUsSubtitle: {
    fontSize: "0.9rem",
    fontWeight: 500,
    color: "#FFFFFF",
    marginBottom: "1em",
  },
  contactUsButton: {
    backgroundColor: "rgba(240, 178, 14,1)",
    color: "black",
    borderRadius: "3em",
    width: "120px",
    "&:hover": {
      backgroundColor: "rgba(240, 178, 14,0.7)",
    },
  },
};
