import React, { useEffect } from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
const RegistrationConfirmation = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location.replace("/dashboard");
    }, 1500);
  }, []);

  return (
    <Card
      sx={{
        backgroundColor: "rgba(10, 10, 10, 0.9)",
        border: "1px solid rgba(98, 77, 0, 1)",
      }}
    >
      <CardContent>
        <Box mb={2} pt={8} display="flex" justifyContent="center">
          <Typography gutterBottom textAlign={"center"} sx={{ mt: 2, mb: 2 }}>
            <img
              src={process.env.PUBLIC_URL + "/static/images/verified.png"}
              alt="Logo"
              style={{ width: 100 }}
            />
          </Typography>
        </Box>
        <Typography
          gutterBottom
          textAlign={"center"}
          variant="h3"
          sx={{ color: "#FFC800", mt: 2, mb: 2 }}
        >
          Verified
        </Typography>
        <Typography gutterBottom textAlign={"center"} mb={2} fontSize={18}>
          Thank you for registering!
        </Typography>
        <Typography gutterBottom textAlign={"center"} mb={8} fontSize={15}>
          Please Wait to Continue
        </Typography>
      </CardContent>
    </Card>
  );
};
export default RegistrationConfirmation;
