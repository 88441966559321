import { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
} from "@mui/material";
import { CoinGoldBar, ReceiveIcon, TransferIcon } from "../../assets";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/reducers/RootReducer";
import { useLocation } from "react-router-dom";
import { walletRequest } from "../../store/reducers/WalletReducer";
import ReceiveDialog from "../../components/ReceiveDialog";
import TransferDialog from "../../components/TransferDialog";
import { COLOR } from "../../theme";
import { toFixedDecimal } from "../../utils/Utils";
import AlertComponent from "../../components/AlertComponent";
import { AlertDataProps } from "../../types/Common";
import TransactionDetails from "../../components/TransactionDetails";

const WalletPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const walletData = useSelector((state: RootState) => state.walletState);
  const [openReceivePopup, setOpenReceivePopup] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState(0);
  const [openTransferPopup, setOpenTransferPopup] = useState(false);
  const [alertData, setAlertData] = useState<AlertDataProps>({
    message: "",
    type: "error",
  });
  const [showTransactionDetails, setShowTransactionDetails] = useState(-1);
  useEffect(() => {
    dispatch(walletRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Card
          sx={{
            backgroundImage: `url(${CoinGoldBar})`,
            height: "10em",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            color: "#fff",
            border: "0.5px solid rgba(135,123,61,1)",
            borderImage:
              "radial-gradient(80.38% 222.5% at -13.75% -12.36%, #877B3D 0%, rgba(255, 255, 255, 0) 100%),radial-gradient(80.69% 208.78% at 108.28% 112.58%, #FFF1BF 0%, rgba(183, 142, 38, 0) 100%)",
            borderRadius: 2,
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            p: 2,
          }}
        >
          <CardContent
            sx={{
              flexGrow: 2,
              alignItems: "center",
              justifyContent: "center",

              textAlign: { xs: "center", sm: "left" },
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: { xs: "center", sm: "flex-start" },
              }}
            >
              Crypto Balance
              <img
                src="../../static/svg/stroke-up.svg"
                alt="stroke"
                style={{ marginLeft: "10px" }}
              />
            </Typography>
            <Typography
              variant="h2"
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: { xs: "center", sm: "flex-start" },
                fontWeight: "normal",
              }}
            >
              <Box
                component="span"
                sx={{ display: "ruby", alignItems: "center", mr: 1 }}
              >
                <Typography variant="h3" fontWeight="normal" component="span">
                  AED
                </Typography>
              </Box>
              {walletData.result?.totalBalanceAed}
            </Typography>
          </CardContent>
          <CardContent
            sx={{ flexGrow: 1, textAlign: { xs: "center", sm: "left" } }}
          >
            <Box sx={{ display: "flex", gap: 2 }}></Box>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={12}>
        <TableContainer sx={styles.tableContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow sx={styles.tableTitleRowBorder}>
                <TableCell width={"50%"}>Currency</TableCell>
                {/* <TableCell align="center" width={"12%"}>Market Price</TableCell> */}
                <TableCell align="center" width={"30%"}>
                  Balance
                </TableCell>
                <TableCell align="center" width={"20%"} colSpan={2}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {walletData?.result?.wallets?.map((element) => (
                <TableRow sx={styles.rowBorder}>
                  <TableCell>
                    <Stack direction={"row"} gap={1} sx={styles.inlineCenter}>
                      <img
                        src={element.cryptoIconUrl}
                        alt={element.cryptoName}
                        style={styles.iconStyle}
                      />
                      <Stack direction={"column"}>
                        <Typography sx={styles.coinName}>
                          {element.cryptoName}
                        </Typography>
                        <Typography sx={styles.subText}>
                          {element.cryptoTicker}
                        </Typography>
                      </Stack>
                    </Stack>
                  </TableCell>

                  {/* <TableCell align="right">
                    <Typography sx={{color:"#fff", fontWeight:350}}>{`${toFixedDecimal(element.unitPrice)} AED`}</Typography>
                  </TableCell> */}

                  <TableCell align="center">
                    <Stack direction={"column"}>
                      <Typography
                        sx={{ ...styles.balanceText, color: "#FFC800" }}
                      >{`${element.balance} ${element.cryptoTicker}`}</Typography>
                      <Typography sx={{ ...styles.subText }}>
                        {`≈ ${toFixedDecimal(element.balanceAed)} AED`}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={styles.inlinePointerCenter}
                      onClick={() => {
                        console.log("Selected scrypt: ", element.cryptoId);

                        setSelectedCoin(element.cryptoId);
                        setOpenTransferPopup(true);
                      }}
                    >
                      <Box sx={styles.iconBox}>
                        <TransferIcon />{" "}
                        <Typography sx={styles.iconText}>Transfer</Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={styles.inlinePointerCenter}
                      onClick={() => {
                        setSelectedCoin(element.cryptoId);
                        setOpenReceivePopup(true);
                      }}
                    >
                      <Box sx={styles.iconBox}>
                        <ReceiveIcon />
                        <Typography sx={styles.iconText}>Receive</Typography>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <ReceiveDialog
        open={selectedCoin && openReceivePopup ? true : false}
        selectedCryptoCoin={selectedCoin}
        handleClose={() => setOpenReceivePopup(false)}
      />
      <TransferDialog
        open={selectedCoin && openTransferPopup ? true : false}
        handleClose={() => setOpenTransferPopup(false)}
        onError={(error) => {
          setAlertData({
            title: "Sell Error",
            message: error,
            type: "error",
          });
          setOpenTransferPopup(false);
        }}
        onSuccess={(trnxId: number) => {
          setOpenTransferPopup(false);
          setSelectedCoin(0);
          setShowTransactionDetails(trnxId);
          dispatch(walletRequest());
        }}
        selectedCryptoCoin={selectedCoin}
      />
      <AlertComponent
        open={alertData.message !== "" ? true : false}
        onClose={() => setAlertData({ message: "", type: "error" })}
        message={alertData.message}
        type={alertData.type ? alertData.type : "error"}
      />
      {showTransactionDetails !== -1 ? (
        <TransactionDetails
          open={true}
          txnID={showTransactionDetails}
          onError={() => {
            setShowTransactionDetails(-1);
          }}
          handleClose={() => {
            setShowTransactionDetails(-1);
          }}
        />
      ) : (
        <></>
      )}
    </Grid>
  );
};
export default WalletPage;
const styles = {
  tableContainer: {
    backgroundColor: COLOR.background,
    borderRadius: "1em",
    maxHeight: "55vh",
  },
  tableTitleRowBorder: {
    "td,th": {
      borderBottom: "0.5px solid rgba(255, 255, 255, 0.2)",
      padding: "1em 1em",
      backgroundColor: COLOR.background,
    },
  },
  rowBorder: {
    "td,th": {
      borderBottom: "0.5px solid rgba(255, 255, 255, 0.2)",
      padding: "1em 0.8em",
    },
    "&:last-child td, &:last-child th": { border: 0 },
  },
  iconStyle: {
    width: "2.5em",
    height: "2.5em",
  },
  inlineCenter: {
    alignItems: "center",
  },
  inlinePointerCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
  },
  iconBox: {
    width: "108px",
    height: "36px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    border: "1px solid #5c5858",
    backgroundColor: "#94918c2e",
  },
  iconText: {
    fontSize: "0.8rem",
    fontWeight: 400,
    marginTop: "1em",
    marginRight: "1em",
    marginBottom: "1em",
  },
  balanceText: {
    fontSize: "1rem",
    fontWeight: 400,
  },
  subText: {
    fontSize: "0.8rem",
    fontWeight: 400,
  },
  coinName: {
    fontSize: "1rem",
    fontWeight: 500,
  },
};
