import { Form, FormikProvider, useFormik } from "formik";
import { LoginSchema } from "../../validations/Validations";
import TextField from "@mui/material/TextField";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginRequest } from "../../store/reducers/AuthReducer";
import React from "react";
import { RootState } from "../../store/reducers/RootReducer";
import { setAuthToken } from "../../api/config";
import { STORE_KEY } from "../../utils/AppConstants";
import VerifyTwoFAModel from "../../components/VerifyTwoFAModel";
import { Link } from "react-router-dom";
import { COLOR } from "../../theme";
import EyeShowIcon from "../../assets/svg/EyeShowIcon";
import EyeSlashIcon from "../../assets/svg/EyeSlashIcon";
import ForgetPasswordDialog from "../../components/ForgetPasswordDialog";
import { AlertDataProps } from "../../types/Common";
import AlertComponent from "../../components/AlertComponent";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ReCaptchaComponent from "./ReCaptchaComponent";
import Loader from "../../components/Loader";
import { aesEncrypt } from "../../utils/Encryption";

const Login = () => {
  const dispatch = useDispatch();
  const authData = useSelector((state: RootState) => state.authState);
  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const [showPassword, setShowPassword] = useState(false);
  const [show2FA, setShow2FA] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const [showForgetPassword, setShowForgetPassword] = useState(false);
  const [alertData, setAlertData] = useState<AlertDataProps>({
    message: "",
    type: "error",
  });

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  useEffect(() => {
    if (token !== null) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (authData?.token) {
      setAuthToken(authData.token);
      if (authData.result?.twoFactorEnabled === 1) {
        setShow2FA(true);
      } else {
        localStorage.setItem(
          STORE_KEY.SESSION_DATA,
          JSON.stringify(authData.result)
        );
        window.location.replace("/dashboard");
      }
    } else {
      if (authData?.error) {
        setAlertData({ message: authData?.error, type: "error" });
      }
    }
  }, [authData]);

  const onTwoFASuccess = () => {
    if (authData?.token) {
      localStorage.setItem(
        STORE_KEY.SESSION_DATA,
        JSON.stringify(authData.result)
      );
      setShow2FA(false);
      window.location.replace("/dashboard");
    }
  };

  const onTwoFAClose = () => {
    setShow2FA(false);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (value) => {
      if (token !== null) {
        const body = {
          email: value.email,
          password: value.password,
          recaptchaToken: token,
        };
        const encryptText = aesEncrypt(JSON.stringify(body));
        const encryptBody = {
          dataCipher: encryptText,
        };
        dispatch(loginRequest(encryptBody));
        //dispatch(loginRequest(body));
      }
    },
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const handleClipboardEvent = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <>
      <Card
        sx={{
          backgroundColor: "rgba(10, 10, 10, 0.9)",
          border: "1px solid rgba(98, 77, 0, 1)",
        }}
      >
        <CardContent>
          <Box mb={2}>
            <Typography
              mt={5}
              mb={5}
              variant="h4"
              gutterBottom
              textAlign={"center"}
            >
              Login To Continue
            </Typography>

            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3} mt={5}>
                  <TextField
                    fullWidth
                    autoComplete="email"
                    type="email"
                    label="Email Address"
                    sx={{ input: { color: "white", fontWeight: 400 } }}
                    {...getFieldProps("email")}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />

                  <TextField
                    fullWidth
                    autoComplete="current-password"
                    type={showPassword ? "text" : "password"}
                    label="Password"
                    onPaste={handleClipboardEvent}
                    onCopy={handleClipboardEvent}
                    onCut={handleClipboardEvent}
                    sx={{
                      input: {
                        color: "white",
                        fontWeight: 400,
                      },
                    }}
                    {...getFieldProps("password")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleShowPassword}
                            edge="end"
                            style={{ color: "#FFC800" }}
                          >
                            {showPassword ? <EyeShowIcon /> : <EyeSlashIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />
                  <Typography
                    sx={styles.linkStyle}
                    onClick={() => {
                      setShowForgetPassword(true);
                    }}
                  >
                    Forgot password?
                  </Typography>
                  {authData?.loading ? (
                    <Loader />
                  ) : (
                    <GoogleReCaptchaProvider
                      useRecaptchaNet
                      reCaptchaKey={siteKey as string}
                      scriptProps={{
                        async: true,
                        defer: true,
                        appendTo: "body",
                      }}
                    >
                      <ReCaptchaComponent
                        onSuccess={(token) => {
                          if (token) {
                            setToken(token);
                          }
                        }}
                        onError={(error) => {
                          if (error) {
                            setAlertData({ message: error, type: "error" });
                          }
                        }}
                      />
                    </GoogleReCaptchaProvider>
                  )}
                </Stack>
              </Form>
            </FormikProvider>

            <Typography variant="body2" mt={2} textAlign={"center"}>
              Don't have an Account?{" "}
              <Link
                style={styles.signInLink}
                to="/auth/registration"
                relative="route"
              >
                Sign Up
              </Link>
            </Typography>
          </Box>
        </CardContent>
      </Card>
      {showForgetPassword ? (
        <ForgetPasswordDialog
          open={showForgetPassword}
          onSuccess={(message) => {
            setAlertData({ message: message, type: "success" });
            setShowForgetPassword(false);
          }}
          onError={(error) => {
            setAlertData({ message: error, type: "error" });
            setShowForgetPassword(false);
          }}
          handleClose={() => {
            setShowForgetPassword(false);
          }}
        />
      ) : (
        <></>
      )}
      <AlertComponent
        open={alertData.message !== "" ? true : false}
        onClose={() => setAlertData({ message: "", type: "error" })}
        message={alertData.message}
        type={alertData.type ? alertData.type : "error"}
      />
      <VerifyTwoFAModel
        open={show2FA}
        title={"Sign In Two Step Verification"}
        handleClose={() => {
          onTwoFAClose();
        }}
        onTwoFASuccess={() => {
          onTwoFASuccess();
        }}
      />
    </>
  );
};
export default Login;

const styles = {
  linkStyle: {
    color: COLOR.primary,
    fontSize: "0.8rem",
    fontWeight: 600,
    alignSelf: "end",
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  signInLink: {
    color: COLOR.primary,
    fontSize: "1em",
    fontWeight: 600,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
};
