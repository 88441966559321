import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import TransactionComponent from "./TransactionComponent";
import { CloseIcon } from "../assets";

type Props = {
  open: boolean;
  handleClose: () => void;
  handleLogout: () => void;
};

const LogoutDialog = (props: Props) => {
  const { open, handleClose, handleLogout } = props;
  return (
    <Dialog
      open={open}
      TransitionComponent={TransactionComponent}
      keepMounted
      PaperProps={{
        style: {
          backgroundColor: "rgba(12, 12, 12, 1)",
          border: "0.5px solid rgba(255, 255, 255, 0.4)",
        },
      }}
      onClose={handleClose}
    >
      <DialogContent sx={styles.container}>
        <Box sx={styles.headerStyle} mb={0.5}>
          <Typography sx={styles.dialogTitle}>Logout</Typography>
          <IconButton sx={styles.iconBackground} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box sx={styles.flexCenter}>
          <Box sx={styles.logoutIcon}>
            <Typography sx={styles.iconText}>!</Typography>
          </Box>
          <Typography mt={1}>Are you sure ?</Typography>
          <Stack direction={"row"} gap={2} sx={styles.inlineSpaceBetween}>
            <Button
              variant="contained"
              size="small"
              sx={{ width: "8em" }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              color="error"
              sx={{ width: "8em" }}
              onClick={handleLogout}
            >
              Log out
            </Button>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default LogoutDialog;
const styles = {
  container: {
    backgroundColor: "rgba(12, 12, 12, 1)",
  },
  headerStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: "1.2rem",
    color: "#FFFFFF",
  },
  iconBackground: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "rgba(0, 0, 0, 1)",
    width: "1em",
    height: "1em",
    borderRadius: "1em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    marginLeft: "1em",
  },
  flexCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0em 1em",
  },
  logoutIcon: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#CB1F14",
    width: "3em",
    height: "3em",
    borderRadius: "3em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    marginTop: "2em",
  },
  iconText: {
    fontSize: "2rem",
    fontWeight: 500,
  },
  inlineSpaceBetween: {
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "2em",
  },
};
