import BigNumber from 'bignumber.js';

export const isValidPhoneNumber = (text: string) => {
    const re = /^(?:\+?\d{1,3}[ -]?)?\d{10,12}$/;
    return re.test(text);
  };
  export const isValidTerminalCode = (text: string) => {
    const re = /^(?=.*\d)(?=.*[A-Za-z])[A-Za-z0-9]{1,10}$/;
    return re.test(text);
  };
  export const isValidEmailAddress = (text: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(text);
  };
  export const isValidPassword = (text: string) => {
    const re =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return re.test(text);
  };
  
  export const isValidTransactionAddress = (text: string) => {
    const re = /^0x[0-9a-fA-F]{40}$/;
    return re.test(text);
  };
  
  export const extractOTP = (text: string) => {
    const re = /(\d{4})/g;
    return re.exec(text);
  };
  
  export const isValidFullName = (text: string) => {
    const re = /^[A-Za-z\s]+$/;
    return re.test(text);
  };
  
  export const isValidDOBNumber = (text: string) => {
    const re = /^\d{2}\/\d{2}\/\d{4}$/;
    return re.test(text);
  };
  
  export const isValidBankName = (text: string) => {
    const re = /^[A-Za-z0-9 ]{3,50}$/;
    return re.test(text);
  };
  
  export const isValidBankAccountNumber = (text: string) => {
    const re = /^[a-zA-Z0-9]{5,30}$/;
    return re.test(text);
  };
  
  export const isValidBankSWIFTCode = (text: string) => {
    const re = /^[a-zA-Z]{4}[a-zA-Z]{2}[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?$/;
    return re.test(text);
  };
  
  export const isValidBankIBANCode = (text: string) => {
    const re = /^[A-Z]{2}\d{2}[A-Z\d]{1,30}$/;
    return re.test(text);
  };
  
  export const isValidBeneficiaryName = (text: string): boolean => {
    const re = /^[\w\s\-.,#()\/]{5,100}$/;
    return re.test(text);
  };
  
  export const isValidSubject = (text: string): boolean => {
    const re = /^[\w\s\-.,#()\/]{3,100}$/;
    return re.test(text);
  };
  
  export const isValidDescription = (text: string): boolean => {
    const re = /^[\w\s\-.,#()\/]{10,250}$/;
    return re.test(text);
  };
  
  export const isValidAmount = (text: string): boolean => {
    const re = /^\d*(\.\d{0,8})?$/;
    return re.test(text);
  };
  

export const isValidWalletAddress = (text:string)=>{
    const regex = /^(0x[a-fA-F0-9]{40}|[TS][a-zA-Z0-9]{33}|0x[a-zA-Z0-9]{40})$/;
    const res = regex.test(text);
    return res;
}

export const isEmpty = (text: string | undefined) => {
    if (text === null || text === undefined || text === '') {
      return true;
    } else {
      return false;
    }
  };
  
  export const isNumber = (value: string) => {
    const regex = /^-?\d+(\.\d+)?$/;
    return regex.test(value);
  };
  
  export const isLessThenOrEqual = (
    value: string | undefined,
    maxNumber: string | undefined,
  ) => {
    if (value && maxNumber) {
      const num1 = new BigNumber(value);
      const num2 = new BigNumber(maxNumber);
      return num1.isLessThanOrEqualTo(num2);
    } else {
      return false;
    }
  };