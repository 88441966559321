import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const ChevronRightIcon = (props: Props) => {
  const { width = 8, height = 12 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 5.25C5.58579 5.25 5.25 5.58579 5.25 6C5.25 6.41421 5.58579 6.75 6 6.75V5.25ZM7.03033 6.53033C7.32322 6.23744 7.32322 5.76256 7.03033 5.46967L2.25736 0.696699C1.96447 0.403806 1.48959 0.403806 1.1967 0.696699C0.903806 0.989593 0.903806 1.46447 1.1967 1.75736L5.43934 6L1.1967 10.2426C0.903806 10.5355 0.903806 11.0104 1.1967 11.3033C1.48959 11.5962 1.96447 11.5962 2.25736 11.3033L7.03033 6.53033ZM6 6.75H6.5V5.25H6V6.75Z"
        fill="white"
      />
    </svg>
  );
};
export default ChevronRightIcon;
