import React from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import RedeemIcon from "@mui/icons-material/Redeem";

const StatCard = ({ icon, title, value }) => (
  <Card sx={{ bgcolor: "background.paper", color: "text.primary" }}>
    <CardContent>
      <Box display="flex" alignItems="center" mb={1}>
        {icon}
        <Typography variant="subtitle2" ml={1}>
          {title}
        </Typography>
      </Box>
      <Typography variant="h5" component="div">
        {value}
      </Typography>
    </CardContent>
  </Card>
);

const AdminDashboardPage = () => {
  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        {/* Crypto Holding Card */}
        <Grid item xs={12} md={12}>
          <Card
            sx={{
              background:
                "radial-gradient(114.16% 143.01% at 15.32% 63.04%, rgba(217, 154, 38, 0.2) 27%, rgba(249, 250, 250, 0.0447917) 77.08%, rgba(181, 181, 181, 0) 100%)",
              color: "#fff",
              borderRadius: 2,
              border: "1px solid #343232",
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              p: 2,
            }}
          >
            <Box sx={{ flexGrow: 1, p: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <StatCard
                    icon={<PeopleIcon />}
                    title="Active Users"
                    value="6,09,098"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <StatCard
                    icon={<ShoppingCartIcon />}
                    title="Total Purchase"
                    value="AED 9,12,008"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <StatCard
                    icon={<AccountBalanceWalletIcon />}
                    title="Total AEG Hold"
                    value="AED 30,09,098"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <StatCard
                    icon={<RedeemIcon />}
                    title="Redeem Completed"
                    value="AED 30,09,098"
                  />
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};
export default AdminDashboardPage;
