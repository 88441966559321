import * as React from "react";
import { Grid, Typography, Box } from "@mui/material";
import "../custom-styles.css";
import { Outlet, useNavigate } from "react-router-dom";
import LogoIcon from "../assets/svg/Logo";
import {
  EasyRegistration,
  EasySale,
  FormBackground,
  SafeSecure,
} from "../assets";
import { APP_COPYRIGHT } from "../utils/AppConstants";
const AuthLayout = () => {
  const navigate = useNavigate();
  return (
    <div style={styles.wrapper}>
      <Box sx={{ p: { xs: 2, sm: 2, md: 6, lg: 6 } }} height={"100vh"}>
        <Box sx={styles.container}>
          <Grid container spacing={0} sx={{ justifyContent: "space-between" }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={7}
              lg={6}
              sx={{ paddingLeft: 0, md: { paddingLeft: 15 } }}
            >
              <Box
                sx={styles.logoContainer}
                onClick={() => {
                  navigate("/");
                }}
              >
                <LogoIcon width={63} height={37} />
                <Typography sx={styles.welcomeText}>Welcome to AEG</Typography>
              </Box>
              <Box sx={styles.onboardingItems}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "left",
                    mb: 3,
                  }}
                >
                  <img src={EasyRegistration} alt="secure Icon" width="60" />
                  <Typography
                    variant="h5"
                    ml={2}
                    fontWeight={500}
                    sx={{ color: "white" }}
                  >
                    Easy Registration
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "left",
                    mb: 3,
                  }}
                >
                  <img src={SafeSecure} alt="secure Icon" width="60" />
                  <Typography
                    variant="h5"
                    ml={2}
                    fontWeight={500}
                    sx={{ color: "white" }}
                  >
                    Safe And Secure
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "left",
                    mb: 3,
                  }}
                >
                  <img src={EasySale} alt="secure Icon" width="60" />
                  <Typography
                    variant="h5"
                    ml={2}
                    fontWeight={500}
                    sx={{ color: "white" }}
                  >
                    Easy Sell and Purchase
                  </Typography>
                </Box>
                <Typography
                  variant="body2"
                  sx={{
                    color: "white",
                    textAlign: "justify",
                    width: "70%",
                    mt: 3,
                  }}
                >
                  Discover a new era of digital gold ownership with AEG (Arab
                  Emirates Gold), a pioneering gold pegged digital gold that
                  revolutionizes the way you invest in precious metals. Designed
                  to provide security, accessibility, and transparency, AEG
                  empowers investors to tap into the potential of gold in the
                  digital age.
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              lg={5}
              sx={{
                backgroundImage: `url(${FormBackground})`,
                backgroundSize: "cover",
                p: { xs: 1, sm: 1, md: 6 },
              }}
              borderRadius={2}
            >
              <Outlet />
            </Grid>
          </Grid>
        </Box>
        <Typography
          variant="body2"
          mt={2}
          textAlign={"right"}
          sx={{ color: "#999999", position: "fixed", bottom: 20, right: 40 }}
          bottom={-2}
        >
          {APP_COPYRIGHT}
        </Typography>
      </Box>
    </div>
  );
};

export default AuthLayout;

const styles = {
  wrapper: {
    backgroundColor: "rgba(10, 10, 10, 1)",
  },
  container: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(10, 10, 10, 1)",
  },
  welcomeText: {
    color: "#FFC800",
    mt: 1,
    mb: 2,
    fontWeight: 700,
    fontSize: {
      xs: "1.5rem",
      sm: "1.5rem",
      md: "2rem",
      lg: "2rem",
    },
  },
  logoContainer: {
    display: "flex",
    flexDirection: "row",
    mb: 2,
    justifyContent: "flex-start",
    md: {
      justifyContent: "flex-start",
    },
    alignItems: "center",
    gap: 1,
    ":hover": {
      cursor: "pointer",
    },
  },
  onboardingItems: {
    display: { xs: "none", sm: "none", md: "flex" },
    flexDirection: "column",
    justifyContent: "center",
    mt: 8,
  },
};
