import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import TransactionComponent from "./TransactionComponent";
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers/RootReducer";
import { useEffect, useMemo, useState } from "react";
import {
  AEGCoinIcon,
  BankBG,
  CloseIcon,
  PlatformTagIcon,
  VerifyIcon,
} from "../assets";
import PrimaryButton from "./PrimaryButton/PrimaryButton";
import {
  addBigNumber,
  calcualtePercentage,
  getBankName,
  maskBankAccount,
  multiplyBigNumber,
  toFixedDecimal,
  zeroNumber,
} from "../utils/Utils";
import VerifiedIcon from "@mui/icons-material/Verified";
import VerifyTwoFAContent from "./VerifyTwoFAContent";
import { requestTokenSell } from "../api/api";
import { SellTokenRequest } from "../types/SellTokenModel";
import DialogLoadingView from "./DialogLoadingView";
import {
  isEmpty,
  isLessThenOrEqual,
  isNumber,
  isValidAmount,
} from "../utils/Validation";
import { COLOR } from "../theme";

type Props = {
  open: boolean;
  handleClose: () => void;
  onError: (error: string) => void;
  onSuccess: (trnxId: number) => void;
};

const enum ViewState {
  SELL_FORM,
  CHOOSE_BANK,
  TWOFA,
  LOADING,
  CONFIRMATION,
}

const SellDialog = (props: Props) => {
  const { open, handleClose, onError, onSuccess } = props;
  const { aegDetailState, userProfileState, bankState } = useSelector(
    (state: RootState) => state
  );
  const [sellQty, setSellQty] = useState("");
  const [sellQtyError, setSellQtyError] = useState("");
  const [viewState, setViewState] = useState<ViewState>(ViewState.SELL_FORM);
  const [transactionId, setTransactionId] = useState(0);

  const primaryBank = useMemo(() => {
    if (bankState.result && bankState.result.length > 0) {
      const primaryBank = bankState?.result?.find(
        (element) => element.isPrimary === 1
      );
      return primaryBank;
    }
  }, [bankState.result]);

  useEffect(() => {
    if (viewState === ViewState.CONFIRMATION) {
      setTimeout(() => {
        onSuccess(transactionId);
      }, 1500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewState]);

  const handleSell = () => {
    if (!isNumber(sellQty)) {
      setSellQtyError("Please enter valid sell quantity");
    } else if (
      isLessThenOrEqual(
        sellQty,
        aegDetailState?.result?.sellMinimumQty.toString()
      )
    ) {
      setSellQtyError(
        `Sell quantity should be more than ${aegDetailState?.result?.sellMinimumQty}`
      );
    } else if (!isLessThenOrEqual(sellQty, aegDetailState.result?.balance)) {
      setSellQtyError("Quantity should be more than available quantity");
    } else {
      if (viewState === ViewState.SELL_FORM) {
        if (bankState?.result && bankState.result.length > 0) {
          setViewState(ViewState.CHOOSE_BANK);
        } else {
          onError("No bank is added, Please add bank first");
        }
      } else if (viewState === ViewState.CHOOSE_BANK) {
        if (userProfileState.result?.twoFactorEnabled === 1) {
          setViewState(ViewState.TWOFA);
        } else {
          performSellAction("000000");
        }
      }
    }
  };

  const performSellAction = async (token: string) => {
    try {
      setViewState(ViewState.LOADING);
      const body: SellTokenRequest = {
        amount: sellQty,
        token: token,
        bank_id: primaryBank?.bankId?.toString() || "",
      };
      const response = await requestTokenSell(body);
      if (response) {
        if (response.status === 200) {
          console.log(
            "Sell Success: ",
            response.data.data.responseData.transactionId
          );

          setTransactionId(response.data.data.responseData.transactionId);
          setViewState(ViewState.CONFIRMATION);
        } else {
          setViewState(ViewState.SELL_FORM);
          setSellQty("");
          onError(response.data.message);
        }
      }
    } catch (error) {
      setViewState(ViewState.SELL_FORM);
      setSellQty("");
    }
  };

  const totalPriceValue = useMemo(() => {
    if (sellQty && aegDetailState.result?.aegPriceAed) {
      return multiplyBigNumber(aegDetailState.result?.aegPriceAed, sellQty);
    }
    return zeroNumber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellQty]);

  const percentagePrice = useMemo(() => {
    if (
      aegDetailState?.result?.sellMarginFeesPercent &&
      !isEmpty(sellQty) &&
      aegDetailState.result?.aegPriceAed
    ) {
      const aegPrice = calcualtePercentage(
        aegDetailState?.result?.sellMarginFeesPercent,
        sellQty
      );
      return multiplyBigNumber(aegPrice, aegDetailState.result?.aegPriceAed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aegDetailState?.result?.sellMarginFeesPercent, sellQty]);

  const grandTotalValue = useMemo(() => {
    if (!isEmpty(totalPriceValue) && !isEmpty(percentagePrice)) {
      return addBigNumber(totalPriceValue, percentagePrice);
    }
    return zeroNumber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPriceValue]);

  const handleCloseRequest = () => {
    setViewState(ViewState.SELL_FORM);
    setSellQty("");
    handleClose();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={TransactionComponent}
      keepMounted
      onClose={handleCloseRequest}
      PaperProps={{
        style: {
          backgroundColor: "rgba(12, 12, 12, 1)",
          border: "0.5px solid rgba(255, 255, 255, 0.4)",
          minWidth: 360,
        },
      }}
      aria-describedby="dialog-profile-description"
    >
      <DialogTitle>
        <Stack direction={"row"} sx={styles.inlineSpaceBetween}>
          <Typography sx={styles.titleText}>Sell AEG</Typography>
          <IconButton sx={styles.iconBackground} onClick={handleCloseRequest}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {ViewState.SELL_FORM === viewState ? (
          <Box>
            <Stack direction={"row"} sx={styles.inlineCenter} mb={2}>
              <AEGCoinIcon />
              <Typography sx={styles.aegPrice} ml={1}>{` x 1 = ${toFixedDecimal(
                aegDetailState.result?.aegPriceAed
              )} AED`}</Typography>
            </Stack>
            <TextField
              variant="outlined"
              type="text"
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{
                      backgroundColor: COLOR.background,
                      minHeight: "2.5em",
                      borderRadius: "8px 0px 0px 8px",
                    }}
                  >
                    <Typography sx={styles.inputPrefix}>AEG Qty</Typography>
                  </InputAdornment>
                ),
                inputProps: {
                  style: {
                    textAlign: "right",
                    fontWeight: 700,
                    letterSpacing: "0.05em",
                    boxSizing: "border-box",
                    paddingRight: "10px",
                  },
                },
              }}
              value={sellQty}
              onChange={(event) => {
                if (isValidAmount(event.target.value)) {
                  if (event.target.value && event.target.value.length > 0) {
                    setSellQtyError("");
                  }
                  if (event.target.value && event.target.value.length > 0) {
                    const pattern = /^\d{0,6}(\.\d{0,8})?$/;
                    if (pattern.test(event.target.value)) {
                      setSellQty(event.target.value);
                    }
                  } else {
                    setSellQty("");
                  }
                }
              }}
              fullWidth
              sx={styles.inputStyle}
              error={sellQtyError !== "" ? true : false}
              helperText={sellQtyError !== "" ? sellQtyError : ""}
            />
            <Typography sx={styles.availableQtyTxt} mt={0.5}>
              {`Available Qty: ${aegDetailState.result?.balance} AEG`}
            </Typography>
            <Stack direction={"row"} sx={styles.inlineSpaceBetween} mt={2}>
              <Stack direction={"row"} sx={styles.inlineCenter} mb={1.5}>
                <AEGCoinIcon />
                <Typography
                  sx={styles.totalGold}
                  ml={1}
                >{`Total Receivable`}</Typography>
              </Stack>
              <Typography
                sx={styles.totalGoldPrice}
              >{`AED ${totalPriceValue}`}</Typography>
            </Stack>
            <Stack direction={"row"} sx={styles.inlineSpaceBetween} mb={1}>
              <Stack direction={"row"} sx={styles.inlineCenter} pl={1}>
                <PlatformTagIcon width={16} height={26} />
                <Typography
                  sx={styles.totalGold}
                  ml={1.5}
                >{`Platform Fees ( ${toFixedDecimal(
                  aegDetailState?.result?.sellMarginFeesPercent
                )}% )`}</Typography>
              </Stack>
              <Typography sx={styles.totalGoldPrice}>{`AED ${
                percentagePrice ? percentagePrice : zeroNumber()
              }`}</Typography>
            </Stack>
            <Divider />
            <Stack
              direction={"row"}
              sx={styles.inlineSpaceBetween}
              mt={1}
              mb={4}
            >
              <Typography sx={styles.totalGoldPrice}>
                {"Grand Total"}
              </Typography>
              <Typography sx={styles.totalGoldPrice}>{`AED ${
                grandTotalValue ? grandTotalValue : zeroNumber()
              }`}</Typography>
            </Stack>
            <PrimaryButton title="Sell Now" onClick={handleSell} />
          </Box>
        ) : (
          <></>
        )}
        {ViewState.CHOOSE_BANK === viewState ? (
          <Box
            m={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography sx={styles.bankDesc} mb={3}>
              Amount will be deposited{"\n\r"}In your Default bank account
            </Typography>
            <Stack direction={"row"} sx={styles.inlineCenter} mb={5}>
              <Box sx={styles.bankLogoContainer}>
                <Box sx={styles.bankLogoGlass}>
                  <Typography sx={styles.bankLogoText}>
                    {getBankName(primaryBank?.bankName)}
                  </Typography>
                </Box>
              </Box>
              <Stack direction={"column"}>
                <Stack direction={"row"} sx={styles.inlineCenter}>
                  <Typography sx={styles.bankName}>
                    {primaryBank?.bankName}
                  </Typography>
                  <VerifiedIcon sx={{ color: "#14CB1B", ml: 3 }} />
                </Stack>

                <Typography sx={styles.accountNumber}>
                  {maskBankAccount(primaryBank?.accountNumber)}
                  <Typography sx={styles.primaryText}> (Primary)</Typography>
                </Typography>
              </Stack>
            </Stack>
            <PrimaryButton title="Continue" onClick={handleSell} />
          </Box>
        ) : (
          <></>
        )}
        {viewState === ViewState.TWOFA ? (
          <VerifyTwoFAContent onSuccess={(code) => performSellAction(code)} />
        ) : (
          <></>
        )}
        {viewState === ViewState.LOADING ? <DialogLoadingView /> : <></>}
        {viewState === ViewState.CONFIRMATION ? (
          <Stack direction={"column"} sx={styles.inlineCenter} p={3}>
            <img
              src={VerifyIcon}
              alt={"BuyCompelete"}
              style={styles.verifyIconStyle}
            />
            <Typography mt={0.5} sx={styles.doneText}>
              Done
            </Typography>
            <Typography sx={styles.sucessText} mt={2}>
              Transaction Successful
            </Typography>
            <Typography style={styles.transactionId} mt={3}>
              Order Id
            </Typography>
            <Typography style={styles.transactionId}>
              {transactionId}
            </Typography>
          </Stack>
        ) : (
          <></>
        )}
      </DialogContent>
    </Dialog>
  );
};
export default SellDialog;

const styles = {
  iconBackground: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "rgba(0, 0, 0, 1)",
    width: "1em",
    height: "1em",
    borderRadius: "1em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    marginLeft: "1em",
  },
  titleText: {
    fontSize: "1.2rem",
    fontWeight: 500,
  },
  inlineSpaceBetween: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  inlineCenter: {
    alignItems: "center",
  },
  aegPrice: {
    fontSize: "1rem",
    fontWeight: 500,
  },
  goldPriceText: {
    fontSize: ".8rem",
    fontWeight: 500,
  },
  inputPrefix: {
    fontSize: "1rem",
    fontWeight: 300,
    padding: "5px 1em",
  },
  inputStyle: {
    fontSize: "1rem",
    "& .MuiInputBase-root": {
      padding: 0,
    },
    "& .MuiInputBase-input": {
      padding: "0 0 0 0",
    },
  },
  availableQtyTxt: {
    textAlign: "right",
    fontSize: "0.8rem",
    fontWeight: 300,
  },
  totalGold: {
    fontSize: "0.8rem",
    fontWeight: 300,
  },
  totalGoldPrice: {
    fontSize: "0.8rem",
    fontWeight: 500,
  },
  bankDesc: {
    fontSize: "1rem",
    fontWeight: 500,
    maxWidth: "300px",
    textAlign: "center",
  },
  bankName: {
    fontSize: "1rem",
    fontWeight: 400,
    color: "#FFFFFF",
  },
  accountNumber: {
    fontSize: "0.7rem",
    fontWeight: 300,
    color: "#727272",
    display: "flex",
    flexDirction: "row",
    alignItems: "center",
  },
  primaryText: {
    fontSize: "0.7rem",
    fontWeight: 300,
    color: "#14CB1B",
    marginLeft: "0.5em",
  },
  verifyIconStyle: {
    width: "5em",
    height: "5em",
  },
  doneText: {
    fontSize: "1.5rem",
    fontWeight: 700,
    color: "#FFC800",
  },
  sucessText: {
    fontSize: "1rem",
    fontWeight: 600,
    color: "#FFFFFF",
  },
  transactionId: {
    fontSize: "0.7rem",
    fontWeight: 400,
  },
  bankLogoContainer: {
    display: "flex",
    flexDirection: "row",
    background: `url(${BankBG}) no-repeat`,
    backgroundSize: "cover",
    width: "3em",
    height: "3em",
    borderRadius: "3em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    marginRight: "0.5em",
  },
  bankLogoGlass: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    backdropFilter: "blur(5px)",
    width: "2.5em",
    height: "2.5em",
    borderRadius: "2.5em",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
  },
  bankLogoText: {
    fontSize: "1em",
    fontWeight: 700,
    color: "#FFFFFF",
  },
};
