import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
import DashboardLayout from "./layouts/dashboard/DashboardLayout";
import DashboardPage from "./pages/dashboard/DashboardPage";
import TransactionsPage from "./pages/transactions/TransactionsPage";
import HomePage from "./pages/home/HomePage";
import PrivacyPolicyPage from "./pages/privacy-policy/PrivacyPolicyPage";
import WalletPage from "./pages/wallet/WalletPage";
import SupportPage from "./pages/support/SupportPage";
import SettingsPage from "./pages/settings/SettingsPage";
import AdminDashboardPage from "./pages/admin-dashboard/AdminDashboardPage";
import AuthLayout from "./layouts/AuthLayout";
import Registration from "./pages/registration/Registration";
import Login from "./pages/login/Login";
import RegistrationCodeVerification from "./pages/confirmation/TwoStepVerification";
import RegistrationConfirmation from "./pages/confirmation/RegistrationConfirmation";
import { useDispatch } from "react-redux";
import { ActivateAccountResponseData } from "./types/ActivateAccountModel";
import { loginSuccess } from "./store/reducers/AuthReducer";
import { setAuthToken } from "./api/config";
import { STORE_KEY } from "./utils/AppConstants";
import GatewaySuccess from "./pages/gateway/GatewaySuccess";
import ChartWidget from "./pages/Chart/ChartWidget";
import PageNotFound from "./pages/pageNotFound/PageNotFound";
import TermsAndCondition from "./pages/termsCondition/TermsAndCondition";
import Products from "./pages/products/Products";

const AuthRoute = ({ element }: any) => {
  const sessionData = localStorage.getItem(STORE_KEY.SESSION_DATA);
  return sessionData && sessionData !== "null" ? (
    <Navigate to="/dashboard" />
  ) : (
    element
  );
};

const ProtectedRoute = ({ element }: any) => {
  const sessionData = localStorage.getItem(STORE_KEY.SESSION_DATA);
  return sessionData && sessionData !== "null" ? (
    element
  ) : (
    <Navigate to="/auth/login" />
  );
};

const AppRouter = () => {
  const dispatch = useDispatch();

  const saveToAuthState = (loginData: ActivateAccountResponseData) => {
    if (loginData) {
      dispatch({
        type: loginSuccess.type,
        payload: {
          token: loginData.token,
          result: loginData,
        },
      });
      setAuthToken(loginData.token);
    }
  };
  useEffect(() => {
    const sessionData = localStorage.getItem(STORE_KEY.SESSION_DATA);
    if (sessionData && sessionData === "null") {
      return;
    } else {
      if (sessionData && sessionData !== null) {
        const userData = JSON.parse(sessionData);
        saveToAuthState(userData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<LogoOnlyLayout />}>
          <Route index element={<HomePage />} />
          <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="terms-and-conditions" element={<TermsAndCondition />} />
          <Route path="widget/chart" element={<ChartWidget />} />
          <Route path="products" element={<Products />} />
        </Route>

        <Route path="/auth" element={<AuthLayout />}>
          <Route path="login" element={<AuthRoute element={<Login />} />} />
          <Route
            path="registration"
            element={<AuthRoute element={<Registration />} />}
          />
          <Route
            path="verify-email"
            element={<AuthRoute element={<RegistrationCodeVerification />} />}
          />
          <Route
            path="registration-confirmation"
            element={<AuthRoute element={<RegistrationConfirmation />} />}
          />
        </Route>

        <Route path="gatewayRedirect">
          <Route path="stripe/success" element={<GatewaySuccess />} />
          <Route path="stripe/failed" element={<GatewaySuccess />} />
        </Route>

        <Route
          path="/dashboard"
          element={<ProtectedRoute element={<DashboardLayout />} />}
        >
          <Route index element={<DashboardPage />} />
          <Route path="transactions" element={<TransactionsPage />} />
          <Route path="wallet" element={<WalletPage />} />
          <Route path="support" element={<SupportPage />} />
          <Route path="settings" element={<SettingsPage />} />
          <Route path="admin" element={<AdminDashboardPage />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
};
export default AppRouter;
