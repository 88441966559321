import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const RedeemIcon = (props: Props) => {
  const { width = 43, height = 19 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7234 16.3092L13.4231 2.14349L17.4689 0.376651C17.9534 0.165039 18.519 0.36722 18.7596 0.838066L25.5695 14.1657L20.7234 16.3092Z"
        fill="#D9D9D9"
      />
      <path
        d="M19.9467 16.9615L12.3669 2.45414L10.034 3.26003C9.46073 3.45808 9.19433 4.11677 9.46876 4.65767L17.5237 20.534L19.9467 16.9615Z"
        fill="#D9D9D9"
      />
      <path
        d="M18.5488 21L20.7234 17.4275L25.8491 15.1287L26.0615 16.7002C26.12 17.133 25.8908 17.5538 25.4954 17.7394L18.5488 21Z"
        fill="#D9D9D9"
      />
      <path
        d="M26.7189 6.11982L23.4882 7.67308L25.8491 12.3328L28.0584 11.6242C28.6329 11.4399 28.9146 10.7927 28.6581 10.2467L26.7189 6.11982Z"
        fill="#D9D9D9"
      />
      <path
        d="M22.8669 6.67899L21.1583 3.63462L26.1598 5.3432L22.8669 6.67899Z"
        fill="#D9D9D9"
      />
      <path
        d="M14.7278 17.179L9.94379 8.04586L4.22781 10.034L10.1302 12.5814V19.2604L14.7278 17.179Z"
        fill="#D9D9D9"
      />
      <path
        d="M9.13609 13.1405L3.54438 10.7796L1.12685 14.5786C0.797441 15.0962 1.00757 15.7857 1.56969 16.0316L8.9497 19.2604L9.13609 13.1405Z"
        fill="#D9D9D9"
      />
    </svg>
  );
};
export default RedeemIcon;
