import React from "react";

type Props = {
  width?: number;
  height?: number;
};

const ChevronDownIcon = (props: Props) => {
  const { width = 12, height = 8 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1.5L4.29289 4.79289C4.68342 5.18342 5.31658 5.18342 5.70711 4.79289L9 1.5"
        stroke="#FCE705"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default ChevronDownIcon;
