import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  Pagination,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/reducers/RootReducer";
import { useLocation, useNavigate } from "react-router-dom";
import { transactionRequest } from "../../store/reducers/TransactionReducer";
import { getTransactionListDateTimeFormat } from "../../utils/DateUtils";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  QuestionMarkCircleIcon,
} from "../../assets";
import { COLOR } from "../../theme";
import TransactionDetails from "../../components/TransactionDetails";
import TableNoDataFound from "../../components/TableNoDataFound";
import Loader from "../../components/Loader";
import { TransactionList } from "../../types/TransactionsModel";
import { formatNumber } from "../../utils/Utils";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";

const TransactionsPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const transactions = useSelector(
    (state: RootState) => state.transactionState
  );
  const [dateRange, setDateRange] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [transactionType, setTransactionType] = useState("");
  const [transactionStatus, setTransactionStatus] = useState("");
  const [showTransactionDetails, setShowTransactionDetails] = useState(-1);
  const [transactionList, setTransactionList] = useState<TransactionList[]>([]);
  const [clearAllDisable, setClearAllDisable] = useState(true);
  const [fromDate, setFromDate] = useState<Dayjs | null>(null);
  const [toDate, setToDate] = useState<Dayjs | null>(null);

  const requestTransaction = (currentPage?: number) => {
    const query = `${encodeURIComponent("page")}=${encodeURIComponent(
      currentPage ? currentPage : 1
    )}`;
    dispatch(transactionRequest(query));
  };

  useEffect(() => {
    if (
      transactions?.result?.transactions &&
      transactions?.result?.transactions.length > 0
    ) {
      setTransactionList(transactions?.result?.transactions);
      setTotalPages(transactions?.result?.totalPages);
      setTotalRecords(transactions?.result?.totalCount);
    } else {
      setTotalPages(0);
      setTotalRecords(0);
      setTransactionList([]);
    }
  }, [transactions?.result]);

  useEffect(() => {
    requestTransaction();
    if (location.search) {
      const query = new URLSearchParams(location.search);
      query.forEach((value, key) => {
        if (key === "transactionId") {
          setShowTransactionDetails(parseInt(value));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  const getTransactionTypeTextStyle = (type: string) => {
    switch (type) {
      case "Transfer":
        return {
          fontSize: "0.8rem",
          fontWeight: "500",
          color: "rgba(203, 31, 20, 1)",
        };
      case "Redeem":
        return {
          fontSize: "0.8rem",
          fontWeight: "500",
          color: "rgba(231, 188, 68, 1)",
        };
      case "Sell":
        return {
          fontSize: "0.8rem",
          fontWeight: "500",
          color: "rgba(203, 31, 20, 1)",
        };
      case "Received":
        return {
          fontSize: "0.8rem",
          fontWeight: "500",
          color: "rgba(20, 203, 27, 1)",
        };
      case "Buy":
        return {
          fontSize: "0.8rem",
          fontWeight: "500",
          color: "rgba(20, 203, 27, 1)",
        };
    }
  };

  const getTransactionTypeValue = (type: string, value: string) => {
    switch (type) {
      case "Transfer":
      case "Redeem":
      case "Sell":
        return `- AED ${value}`;
      case "Received":
      case "Buy":
        return `+ AED ${value}`;
    }
  };

  const clearAllFilters = () => {
    setDateRange("");
    setTransactionType("");
    setTransactionStatus("");
    setFromDate(null);
    setToDate(null);
    dispatch(transactionRequest(""));
  };

  useEffect(() => {
    const queryParts: string[] = [];
    if (
      dateRange ||
      transactionType ||
      transactionStatus ||
      fromDate ||
      toDate
    ) {
      if (dateRange) {
        queryParts.push(
          `${encodeURIComponent("time_range")}=${encodeURIComponent(dateRange)}`
        );
      }
      if (fromDate && toDate) {
        const from_date = dayjs(fromDate, "DD/MM/YYYY");
        const to_date = dayjs(toDate, "DD/MM/YYYY");

        queryParts.push(
          `${encodeURIComponent("from_date")}=${encodeURIComponent(
            from_date.format("YYYY-MM-DD")
          )}`
        );
        queryParts.push(
          `${encodeURIComponent("to_date")}=${encodeURIComponent(
            to_date.format("YYYY-MM-DD")
          )}`
        );
      }

      if (transactionType) {
        queryParts.push(
          `${encodeURIComponent("type")}=${encodeURIComponent(transactionType)}`
        );
      }
      if (transactionStatus) {
        queryParts.push(
          `${encodeURIComponent("status")}=${encodeURIComponent(
            transactionStatus
          )}`
        );
      }
      const queryString = queryParts.join("&");
      setClearAllDisable(false);
      dispatch(transactionRequest(queryString));
    } else {
      setClearAllDisable(true);
      dispatch(transactionRequest(""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, fromDate, toDate, transactionType, transactionStatus]);

  const handleNeedHelpClick = (event: any) => {
    event.stopPropagation();
    navigate("/dashboard/support");
    console.log("Cell clicked:");
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    const queryParts: string[] = [];
    if (
      dateRange ||
      transactionType ||
      transactionStatus ||
      fromDate ||
      toDate
    ) {
      if (dateRange) {
        queryParts.push(
          `${encodeURIComponent("time_range")}=${encodeURIComponent(dateRange)}`
        );
      }
      if (fromDate && toDate) {
        const from_date = dayjs(fromDate, "DD/MM/YYYY");
        const to_date = dayjs(toDate, "DD/MM/YYYY");

        queryParts.push(
          `${encodeURIComponent("from_date")}=${encodeURIComponent(
            from_date.format("YYYY-MM-DD")
          )}`
        );
        queryParts.push(
          `${encodeURIComponent("to_date")}=${encodeURIComponent(
            to_date.format("YYYY-MM-DD")
          )}`
        );
      }

      if (transactionType) {
        queryParts.push(
          `${encodeURIComponent("type")}=${encodeURIComponent(transactionType)}`
        );
      }
      if (transactionStatus) {
        queryParts.push(
          `${encodeURIComponent("status")}=${encodeURIComponent(
            transactionStatus
          )}`
        );
      }
      queryParts.push(
        `${encodeURIComponent("page")}=${encodeURIComponent(value)}`
      );
      const queryString = queryParts.join("&");
      setClearAllDisable(false);
      dispatch(transactionRequest(queryString));
    } else {
      requestTransaction(value);
    }
    setPage(value);
  };

  return (
    <Box sx={styles.container}>
      <Stack direction={"row"} sx={styles.filterDD} gap={4}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={fromDate}
            label="From Date &nbsp;&nbsp;&nbsp;&nbsp;"
            disableFuture
            format={"DD/MM/YYYY"}
            onChange={(value) => setFromDate(dayjs(value))}
            slotProps={{
              textField: {
                variant: "outlined",
                size: "small",
                InputProps: { style: styles.slotPropsStyle },
              },
            }}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={toDate}
            disableFuture
            label="To Date &nbsp;&nbsp;&nbsp;"
            minDate={dayjs(fromDate)}
            format={"DD/MM/YYYY"}
            onChange={(value) => setToDate(dayjs(value))}
            slotProps={{
              textField: {
                variant: "outlined",
                size: "small",
                InputProps: { style: styles.slotPropsStyle },
              },
            }}
          />
        </LocalizationProvider>
        <FormControl size="small" variant="outlined">
          <InputLabel>
            <Typography sx={styles.dropdownItemText}>Date Range</Typography>
          </InputLabel>
          <Select
            value={dateRange}
            label="Date Range"
            IconComponent={() => <ChevronDownIcon />}
            sx={styles.dropdownStyle}
            onChange={(event: { target: { value: string } }) => {
              setDateRange(event.target.value);
            }}
          >
            <MenuItem value={""}>
              <Typography sx={styles.dropdownItemText}>
                <em>Date Range</em>
              </Typography>
            </MenuItem>
            <MenuItem value={"1_week"}>
              <Typography sx={styles.dropdownItemText}>1 Week</Typography>
            </MenuItem>
            <MenuItem value={"1_month"}>
              <Typography sx={styles.dropdownItemText}>1 Month</Typography>
            </MenuItem>
            <MenuItem value={"3_month"}>
              <Typography sx={styles.dropdownItemText}>3 Month</Typography>
            </MenuItem>
            <MenuItem value={"6_month"}>
              <Typography sx={styles.dropdownItemText}>6 Month</Typography>
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl size="small" variant="outlined">
          <InputLabel>
            <Typography sx={styles.dropdownItemText}>
              Transaction Type
            </Typography>
          </InputLabel>
          <Select
            value={transactionType}
            label="Transaction Type"
            IconComponent={() => <ChevronDownIcon />}
            sx={styles.dropdownStyle}
            onChange={(event: { target: { value: string } }) => {
              setTransactionType(event.target.value);
            }}
          >
            <MenuItem value={""}>
              <Typography sx={styles.dropdownItemText}>
                <em>Transaction Type</em>
              </Typography>
            </MenuItem>
            <MenuItem value={"Redeem"}>
              <Typography sx={styles.dropdownItemText}>Redeem</Typography>
            </MenuItem>
            <MenuItem value={"Transfer"}>
              <Typography sx={styles.dropdownItemText}>Transfer</Typography>
            </MenuItem>
            <MenuItem value={"Receive"}>
              <Typography sx={styles.dropdownItemText}>Receive</Typography>
            </MenuItem>
            <MenuItem value={"Sell"}>
              <Typography sx={styles.dropdownItemText}>Sell</Typography>
            </MenuItem>
            <MenuItem value={"Buy"}>
              <Typography sx={styles.dropdownItemText}>Buy</Typography>
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl size="small" variant="outlined">
          <InputLabel>
            <Typography sx={styles.dropdownItemText}>Status </Typography>
          </InputLabel>
          <Select
            value={transactionStatus}
            label="Status"
            IconComponent={() => <ChevronDownIcon />}
            sx={styles.dropdownStyle}
            onChange={(event: { target: { value: string } }) => {
              setTransactionStatus(event.target.value);
            }}
          >
            <MenuItem value={""}>
              <Typography sx={styles.dropdownItemText}>
                <em>Status</em>
              </Typography>
            </MenuItem>
            <MenuItem value={"Approved"}>
              <Typography sx={styles.dropdownItemText}>Approved</Typography>
            </MenuItem>
            <MenuItem value={"Pending"}>
              <Typography sx={styles.dropdownItemText}>Pending</Typography>
            </MenuItem>
            <MenuItem value={"Rejected"}>
              <Typography sx={styles.dropdownItemText}>Rejected</Typography>
            </MenuItem>
            <MenuItem value={"Failed"}>
              <Typography sx={styles.dropdownItemText}>Failed</Typography>
            </MenuItem>
          </Select>
        </FormControl>
        <Button
          variant={"contained"}
          onClick={clearAllFilters}
          disabled={clearAllDisable}
        >
          Clear All
        </Button>
      </Stack>
      <TableContainer sx={styles.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow sx={styles.tableTitleRowBorder}>
              <TableCell>#</TableCell>
              <TableCell>Coin</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.loading ? (
              <TableRow sx={styles.noBorder}>
                <TableCell colSpan={5} align="center" sx={{ padding: "3em" }}>
                  <Loader />
                </TableCell>
              </TableRow>
            ) : transactionList && transactionList.length > 0 ? (
              transactionList?.map((element, index) => (
                <TableRow
                  sx={styles.rowBorder}
                  onClick={() =>
                    setShowTransactionDetails(element.transactionId)
                  }
                >
                  <TableCell align="center">
                    {(page - 1) * 10 + index + 1}
                  </TableCell>

                  <TableCell>
                    <Stack
                      direction={"row"}
                      sx={styles.inlineCenter}
                      justifyContent={"center"}
                    >
                      <img
                        src={element.cryptoIconUrl}
                        alt="icon"
                        style={styles.iconSize}
                      />
                      <Typography ml={1}>{element.cryptoTicker}</Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align="center">
                    {getTransactionListDateTimeFormat(element.createdAt)}
                  </TableCell>
                  <TableCell align="center">
                    {formatNumber(element.txValue)}
                  </TableCell>
                  <TableCell
                    sx={getTransactionTypeTextStyle(element.transactionType)}
                    align="center"
                  >
                    {element.transactionType}
                  </TableCell>
                  <TableCell
                    sx={getTransactionTypeTextStyle(element.transactionType)}
                    align="center"
                  >
                    {getTransactionTypeValue(
                      element.transactionType,
                      element.transactionValueAed
                    )}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={(event) => handleNeedHelpClick(event)}
                  >
                    <Stack
                      direction={"row"}
                      sx={styles.inlineCenter}
                      justifyContent={"center"}
                    >
                      <QuestionMarkCircleIcon />
                      <Typography sx={styles.needHelp}>Need Help</Typography>
                      <ChevronRightIcon />
                    </Stack>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableNoDataFound />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        display="flex"
        alignItems={"center"}
        justifyContent="space-between"
        px={3}
        mt={2}
        pb={1}
      >
        <Typography
          sx={styles.totalRecordsStyle}
        >{`Total Records: ${totalRecords}`}</Typography>
        <Pagination
          count={totalPages ? totalPages : 1}
          page={page}
          color="primary"
          onChange={handleChangePage}
          sx={{
            button: { color: "white" },
            ul: { justifyContent: "center" },
          }}
        />
      </Box>
      {showTransactionDetails !== -1 ? (
        <TransactionDetails
          open={true}
          txnID={showTransactionDetails}
          onError={() => {
            setShowTransactionDetails(-1);
          }}
          handleClose={() => {
            setShowTransactionDetails(-1);
          }}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default TransactionsPage;

const styles = {
  container: {
    backgroundColor: COLOR.background,
    borderRadius: "1em",
  },
  rowBorder: {
    "td,th": {
      borderBottom: "0.5px solid rgba(255, 255, 255, 0.2)",
    },
    "&:last-child td, &:last-child th": { border: 0 },
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.3)",
      transition: "all 0.3s ease-in-out",
    },
    cursor: "pointer",
  },
  totalRecordsStyle: {
    color: "white",
    fontSize: "0.8rem",
    fontWeight: 650,
  },
  noBorder: {
    "td,th": {
      border: 0,
    },
  },
  iconSize: {
    width: "2em",
    height: "2em",
  },
  inlineCenter: {
    alignItems: "center",
  },
  needHelp: {
    fontSize: "0.8rem",
    color: "#83807F",
    fontWeight: 600,
    margin: "0 0.5em",
  },
  dropDownSelect: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "1em",
  },
  dropdownSelectTxt: {
    fontSize: "0.8rem",
    color: "#FFFFFF",
    fontWeight: 600,
    userSelect: "none",
    cursor: "pointer",
  },
  dropDownItems: {
    position: "absolute",
    backgroundColor: "#000000",
    borderRadius: "3px",
    padding: "3px",
    elevation: 3,
    zIndex: 999,
    top: "1.4em",
    width: "8em",
    left: "-1.5em",
  },
  filterDD: {
    width: "100%",
    padding: "1em",
    alignItems: "center",
    justifyContent: "end",
  },
  filterItemText: {
    fontSize: "0.8rem",
    color: "#FFFFFF",
    fontWeight: 400,
    padding: "1em 1.3em",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.1)",
    },
  },
  tableContainer: {
    paddingLeft: "1em",
    paddingRight: "1em",
    marginTop: "0.5em",
    maxHeight: "60vh",
    height: "60vh",
  },
  dropdownStyle: {
    width: "12em",
    backgroundColor: "transparent",
    boxShadow: "none",
    ".MuiOutlinedInput-notchedOutline": { border: "0.5px solid #FFC800" },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: "0.5px solid #FFC800",
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "0.5px solid #FFC800",
    },
    paddingTop: "0.33em",
    paddingBottom: "0.33em",
    paddingRight: "1em",
    fontSize: "0.8em",
    color: "#FFFFFF",
  },
  slotPropsStyle: {
    ".MuiOutlinedInput-notchedOutline": {
      fontSize: "0.8em",
      color: "#FFFFFF",
    },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      fontSize: "0.8em",
      color: "#FFFFFF",
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      fontSize: "0.8em",
      color: "#FFFFFF",
      border: "0.5px solid #FFC800",
    },
    "&.MuiFormLabel-root .MuiInputLabel-root": {
      fontSize: "0.8em",
      color: "#FFFFFF",
    },
    "&.MuiFormLabel-root .MuiInputLabel-root.Mui-focused": {
      color: "#FFFFFF",
    },
    "&.MuiFormLabel-root .MuiInputLabel-root.MuiInputLabel-shrink": {
      color: "#FFFFFF",
      fontSize: "1rem",
    },
    fontSize: "0.8em",
    color: "#FFFFFF",
    padding: "4px 6px",
  },
  dropdownItemText: {
    fontSize: "0.8rem",
    color: "#FFFFFF",
    fontWeight: 400,
    paddingTop: "0.33em",
    paddingBottom: "0.33em",
  },
  tableTitleRowBorder: {
    "td,th": {
      borderBottom: "0.5px solid rgba(255, 255, 255, 0.2)",
      fontSize: "0.8rem",
      fontWeight: 600,
      textAlign: "center",
      color: "#FFFFFF",
      backgroundColor: COLOR.background,
    },
  },
  dateRangeText: {
    fontSize: "0.8rem",
  },
};
