import BigNumber from "bignumber.js";

export const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

export const maskBankAccount = (accountNumber: string | undefined) => {
  if (accountNumber) {
    const accountStr = String(accountNumber);
    const lastFourDigits = accountStr.slice(-4);
    const maskedSection = "X".repeat(accountStr.length - 4);
    return maskedSection + lastFourDigits;
  } else {
    return "";
  }
};

export const trimAddress = (address: string | undefined, count?: number) => {
  if (address) {
    return `${address.substring(0, count ? count : 5)}...${address.substring(
      address.length - (count ? count : 5),
      address.length
    )}`;
  } else {
    return "";
  }
};

export const getBankName = (bankName: string | undefined) => {
  if (bankName) {
    const bankSplit = bankName.split(" ");
    return bankSplit[0].slice(0, 1);
  } else {
    return "BB";
  }
};

export const generatePayload = (id: number) => {
  return "WPJG" + id + "QfzB2k+88KhWX9W8bw";
};

export const multiplyBigNumber = (a: any, b: any): string => {
  BigNumber.config({ DECIMAL_PLACES: 2 });
  let x: BigNumber;
  let y: BigNumber;
  if (a) {
    x = new BigNumber(a);
    if (x.isNaN()) {
      x = new BigNumber(0);
    }
  } else {
    x = new BigNumber(0);
  }
  if (b) {
    y = new BigNumber(b);
    if (y.isNaN()) {
      y = new BigNumber(0);
    }
  } else {
    y = new BigNumber(0);
  }
  return x.multipliedBy(y).toFixed(2).toString();
};

export const formatNumber = (number: string | number | undefined) => {
  if (number) {
    let x = new BigNumber(number);
    const formattedNumber = x.toFixed().toString();
    return formattedNumber;
  }
};

export const addBigNumber = (a: any, b: any, decimal?: number): string => {
  BigNumber.config({ DECIMAL_PLACES: decimal ? decimal : 2 });
  let x: BigNumber;
  let y: BigNumber;
  if (a) {
    x = new BigNumber(a);
    if (x.isNaN()) {
      x = new BigNumber(0);
    }
  } else {
    x = new BigNumber(0);
  }
  if (b) {
    y = new BigNumber(b);
    if (y.isNaN()) {
      y = new BigNumber(0);
    }
  } else {
    y = new BigNumber(0);
  }
  return x
    .plus(y)
    .toFixed(decimal ? decimal : 2)
    .toString();
};

export const calcualtePercentage = (percentage: any, value: any): string => {
  BigNumber.config({ DECIMAL_PLACES: 2 });
  let x: BigNumber;
  let y: BigNumber;
  if (percentage) {
    x = new BigNumber(percentage);
    if (x.isNaN()) {
      x = new BigNumber(0);
    }
  } else {
    x = new BigNumber(0);
  }

  if (value) {
    y = new BigNumber(value);
    if (y.isNaN()) {
      y = new BigNumber(0);
    }
  } else {
    y = new BigNumber(0);
  }
  return x.dividedBy(100).multipliedBy(y).toString();
};

export const toPascalCase = (text: string) => {
  const firstLetter = text.substring(0, 1).toUpperCase();
  const otherLetter = text.substring(1, text.length).toLowerCase();
  return firstLetter + otherLetter;
};

export const zeroNumber = () => {
  return new BigNumber(0).toFixed(2).toString();
};

export const toFixedDecimal = (value: any, decimal?: number) => {
  if (value) {
    let x = new BigNumber(value);
    if (decimal === 0) {
      return x.toString();
    }
    return x.toFixed(decimal ? decimal : 2).toString();
  } else {
    return "0.00";
  }
};
