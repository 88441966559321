import React from "react";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
} from "@mui/material";
import Table from "@mui/material/Table";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers/RootReducer";
import { getTransactionListDateTimeFormat } from "../utils/DateUtils";
import { COLOR } from "../theme";
import { useNavigate } from "react-router-dom";
import TableNoDataFound from "./TableNoDataFound";
import Loader from "./Loader";
import { formatNumber } from "../utils/Utils";

type Props = {
  onClick: (transactionId: number) => void;
};

const TransactionsLists = (props: Props) => {
  const dashboardData = useSelector((state: RootState) => state.dashboardState);
  const navigate = useNavigate();

  const getTransactionTypeTextStyle = (type: string) => {
    switch (type) {
      case "Transfer":
        return {
          fontSize: "0.8rem",
          fontWeight: "600",
          color: "rgba(203, 31, 20, 1)",
        };
      case "Redeem":
        return {
          fontSize: "0.8rem",
          fontWeight: "500",
          color: "rgba(231, 188, 68, 1)",
        };
      case "Sell":
        return {
          fontSize: "0.8rem",
          fontWeight: "600",
          color: "rgba(203, 31, 20, 1)",
        };
      case "Received":
        return {
          fontSize: "0.8rem",
          fontWeight: "500",
          color: "rgba(20, 203, 27, 1)",
        };
      case "Buy":
        return {
          fontSize: "0.8rem",
          fontWeight: "500",
          color: "rgba(20, 203, 27, 1)",
        };
    }
  };

  const getTransactionTypeValue = (type: string, value: string) => {
    switch (type) {
      case "Transfer":
      case "Redeem":
      case "Sell":
        return `- AED ${value}`;
      case "Received":
      case "Buy":
        return `+ AED ${value}`;
    }
  };

  const handleViewAllClick = () => {
    navigate("/dashboard/transactions");
  };

  return (
    <Card
      sx={{ backgroundColor: COLOR.background, color: "#fff", borderRadius: 2 }}
    >
      <CardContent sx={{ padding: "1em" }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  "td,th": {
                    borderBottom: "0.5px solid rgba(255, 255, 255, 0.2)",
                  },
                  padding: "1em",
                }}
              >
                <TableCell sx={{ padding: "0em 0.5em 0em 0.5em" }} colSpan={3}>
                  Transactions
                </TableCell>
                <TableCell
                  sx={{ padding: "0em 0.5em 0em 0.5em" }}
                  colSpan={2}
                  align="right"
                >
                  <IconButton
                    color="inherit"
                    sx={{ color: "#fff" }}
                    onClick={handleViewAllClick}
                  >
                    <Typography sx={styles.itemListTitle}>View All </Typography>
                    <ArrowForwardIcon sx={{ fontSize: 16 }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dashboardData?.loading ? (
                <TableRow sx={styles.noBorder}>
                  <TableCell colSpan={5} align="center" sx={{ padding: "3em" }}>
                    <Loader />
                  </TableCell>
                </TableRow>
              ) : dashboardData?.result?.transactions &&
                dashboardData?.result?.transactions.length > 0 ? (
                dashboardData?.result?.transactions
                  ?.slice(0, 5)
                  ?.map((transaction, index) => (
                    <TableRow
                      onClick={() => props.onClick(transaction.transactionId)}
                      key={transaction.transactionId}
                      sx={styles.tableRow}
                    >
                      <TableCell sx={{ padding: "0.5em" }}>
                        <Stack
                          direction={"row"}
                          sx={{ alignItems: "center" }}
                          gap={1}
                        >
                          <img
                            src={transaction.cryptoIconUrl}
                            style={styles.iconStyle}
                            alt="AEG"
                          />
                          <Typography sx={styles.boldText}>
                            {transaction.cryptoTicker}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        {transaction.createdAt
                          ? getTransactionListDateTimeFormat(
                              transaction.createdAt
                            )
                          : ""}
                      </TableCell>
                      <TableCell>
                        {`${
                          transaction.txValue
                            ? formatNumber(transaction.txValue)
                            : "0.00"
                        } AEG`}
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={getTransactionTypeTextStyle(
                            transaction.transactionType
                          )}
                        >
                          {transaction.transactionType}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography
                          sx={getTransactionTypeTextStyle(
                            transaction.transactionType
                          )}
                        >
                          {getTransactionTypeValue(
                            transaction.transactionType,
                            transaction.txValueAed
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableNoDataFound />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default TransactionsLists;

const styles = {
  itemContainerStyle: {
    display: "flex",
    alignItems: "center",
    padding: "0.5em 0",
    borderBottom: "1px solid #363535",
  },
  itemListTitle: {
    fontSize: "0.9rem",
    fontWeight: "500",
  },
  noBorder: {
    "td,th": {
      border: 0,
    },
  },
  iconStyle: {
    minWidth: 30,
    minHeight: 30,
    width: 30,
    height: 30,
  },
  itemAegPriceText: {
    fontSize: "0.8rem",
    fontWeight: "500",
    color: "rgba(165, 164, 164, 1)",
  },
  boldText: {
    fontWeight: 500,
  },
  noDataFoundText: {
    fontSize: "0.9rem",
    fontWeight: "500",
    color: "rgba(165, 164, 164, 1)",
    textAlign: "center",
  },
  tableRow: {
    "td,th": {
      borderBottom: "0.5px solid rgba(255, 255, 255, 0.2)",
    },
    "&:last-child td, &:last-child th": { border: 0 },
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.3)",
      transition: "all 0.3s ease-in-out",
    },
    cursor: "pointer",
  },
};
